import React, { useContext, Fragment } from "react";
import { withStyles, Divider, Button } from "@material-ui/core";
import Carousel from "../../carousel/Carousel";
import DataContext from "../../data/DataContext";
import { openLink } from "../../util/utilNative";
import reserve from "../../../asset/img/btn_reserve.png";

const styles = (theme) => ({
  root: {
    position: "relative",
    letterSpacing: "-0.5px",
    margin: "6px 0px",
    zIndex: 1,
  },
  carousel: {
    width: "100%",
    height: "144px",
  },
  item: {
    height: "37px",
    textAlign: "center",
    color: theme.color.select.color,
    fontSize: theme.font.size14,
    fontWeight: "bold",
    padding: "0px",
    flex: 1,
  },
  vertical: {
    height: "auto",
  },
  list: {
    display: "flex",
    margin: "0px 20px 0px 20px",
  },
  listitem: {
    width: "288px",
    padding: "0px 8px 0px 0px",
  },
  card: {
    width: "280px",
    height: "384px",
    flex: "auto",
    background: "#fff",
    border: "1px solid #d7d7d7",
    borderRadius: "6px",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  thumbnail: {
    height: "147px",
    padding: "15px 20px 8px",
  },
  thumbnailImage: {
    width: "auto",
    height: "132px",
  },
  date: {
    fontSize: theme.font.size14,
    color: "#333",
    lineHeight: "22px",
    padding: "0px 19px",
    textAlign: "start",
  },
  info: {
    display: "flex",
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'space-between'
  },
  productTitle: {
    color: '#333',
    fontSize: theme.font.size15, // "15px",
    lineHeight: '26px'
  },
  productModel: {
    color: '#555',
    fontSize: theme.font.size13,
    marginBottom: '8px'
  },
  rental: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '20px'
  },
  monthlyPayment: {
    fontSize: theme.font.size11,
    color: '#707070'
  },
  payment: {
    fontSize: theme.font.size13,
    color: '#333',
    textAlign: 'end'
  },
  price: {
    fontWeight: 'bold',
    fontSize: theme.font.size18,
    color: '#F41977'
  },
  external: {
    display: "flex",
    height: "45px",
    background: "#f7f0f0",
    borderRadius: "0 0 6px 6px",
    borderTop: '1px solid #d7d7d7',
    marginTop: '10px'
  },
  externalButton: {
    color: "#555",
    fontWeight: "normal",
    fontWeight: "bold",
    fontSize: theme.font.size14,
    flex: 1,
    border: "none",
    padding: 0,
    background: "transparent",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  buttonIcon: {
    width: '20px',
    height: '20px',
    marginRight: '4px'
  }
});

const Card = React.forwardRef((props, ref) => {
  const { classes, data } = props;
  const context = useContext(DataContext);

  const handleClick = (url, action, event) => {
    if (!ref.clickAllowed()) {
      return;
    }
    openLink(context, action, url, event);
  };

  return data.items.map((item, key) => (
    <div key={key} className={classes.listitem}>
      <div className={classes.card}>
        <div className={classes.content} onClick={() => handleClick(item.link.product_link_url, item.link.type)}>
          <div className={classes.thumbnail}>
            <img
              className={classes.thumbnailImage}
              src={context.userAgent.deviceType === 'mobile' ? item.link.product_image_mobile_url : item.link.product_image_url}
              alt={item.link.product_title}
            />
          </div>
          <div className={classes.info}>
            <div className={classes.productTitle}>{item.link.product_title}</div>
            <div className={classes.productModel}>{item.link.product_name}</div>
            <div className={classes.rental}>
              <div className={classes.monthlyPayment}>
                {context.string.chat.monthlyPayment}
              </div>
              <div className={classes.payment}>
                <span className={classes.price}>{item.product_price}</span>
                <span>{context.string.chat.currency}</span>
                <div>
                  {item.product_price_desc}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.external}>
          {item.buttons.map((button, index) => (
            <Fragment key={index}>
              <Divider
                className={classes.externalDivider}
                orientation="vertical"
                hidden={index === 0}
                component={"div"}
                disabled
              />
              <Button
                type="button"
                className={classes.externalButton}
                disabled={context.conversationEnd}
                onClick={() =>
                  handleClick(
                    context.userAgent.deviceType === "mobile"
                      ? button.parameters.mobile_url
                      : button.parameters.url,
                    button.parameters.action,
                    button.event_action
                  )
                }
              >
                <div className={classes.buttonContainer}>
                  <img className={classes.buttonIcon} src={reserve} alt={button.label}/>
                  <div>{button.label}</div>
                </div>
              </Button>
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  ));
});

function CareCard(props) {
  const { classes, data } = props;

  return (
    <>
      <div className={classes.root}>
        <Carousel
          className={classes.carousel}
          component={withStyles(styles)(Card)}
          data={data}
        />
      </div>
    </>
  );
}
export default withStyles(styles)(CareCard);
