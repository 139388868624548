import React from 'react';
import { withStyles } from '@material-ui/core';
import loading from "../../asset/img/loading.svg";
import loading_safari from "../../asset/img/loading_safari.svg";

const styles = () => ({
  body: {
    /*
    position: "absolute",
    margin: "auto",
    top: "0",
    left: "0",
    bottom: "0",
    right: "0",
    width: "50%",
    height: "50%"
    */
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
  loadingImg: {
   width: "3em"
  }
});

function Loading(props) {
  const { classes, safari } = props;
  return (
    <>
      <div className={classes.body}>
        <img className={classes.loadingImg} src={safari ? loading : loading} alt={"loading"} />
      </div> 
    </>
  );
}
export default withStyles(styles)(Loading);