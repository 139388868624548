import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  withStyles,
} from "@material-ui/core";
import { useLiveChat } from "../data/LiveChat";
import { Rating } from "react-simple-star-rating";

const styles = (theme) => ({
  paper: {
    borderRadius: "20px",
    margin: "20px",
  },
  title: {
    fontSize: theme.font.size18,
    fontFamliy: "LGSmHaTSB",
    color: theme.color.accent,
    margin: "18px 24px",
  },
  description: {
    width: "100%"
  },
  rating: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "20px",
  },
  button: {
    color: theme.color.accent,
    fontSize: theme.font.size15, // "15px",
    fontFamily: "LGSmHaTSB",
  },
  textarea: {
    width: "100%",
    margin: "10px",
    border: "1px #e8e8e8 solid",
    borderRadius: "2px",
  }
});

const LiveChatRating = (props) => {
  const { classes } = props;
  const { livechat, livechatApi } = useLiveChat();
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");

  const handleRating = (rate) => {
    console.log(rate);
    setRating(rate);
  };

  const handleCancel = () => {
    livechatApi.closeRating();
  };

  const handleOk = () => {
    livechatApi.closeRating(rating, comment);
  };

  const handleCommentChange = e => {
    e.preventDefault();
    setComment(e.target.value)
  }

  return (
    <Dialog open={livechat.openStarRating} classes={{ paper: classes.paper }}>
      <div className={classes.title}>상담 완료</div>
      <DialogContent>
        <div className={classes.description}>고객님, 만족스러운 상담이셨나요? 상담 만족도에 대한 별점과 평가를 부탁드립니다.</div>
        <div className={classes.rating}>
          <Rating onClick={handleRating} ratingValue={rating} allowHalfIcon />
        </div>
        <div>
          <textarea
            className={classes.textarea}
            value={comment}
            onChange={handleCommentChange}
          />
        </div>
        <DialogActions>
          <Button className={classes.button} onClick={handleCancel}>
            취소
          </Button>
          <Button className={classes.button} onClick={handleOk}>
            확인
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(LiveChatRating);
