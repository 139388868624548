import React, { useContext, Fragment } from "react";
import {
  withStyles,
  Divider,
  Button,
} from "@material-ui/core";
import Carousel from "../../carousel/Carousel";
import DataContext from "../../data/DataContext";
import IconMore from "../../../asset/img/btn_more_nor.png";
import IconMorePressed from "../../../asset/img/btn_more_sel.png";

const styles = (theme) => ({
  root: {
    position: "relative",
    letterSpacing: "-0.5px",
    margin: "6px 0px",
    zIndex: 10,
  },
  carousel: {
    width: "100%",
    height: "144px",
  },
  listitem: {
    width: "218px",
    padding: "0px 8px 0px 0px",
  },
  card: {
    width: "210px",
    height: "100%",
    flex: "auto",
    background: "#fff",
    border: "1px solid #d7d7d7",
    borderRadius: "12px",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
  },
  thumbnail: {
    height: "147px",
    padding: "15px 15px 12px 15px",
  },
  thumbnailImage: {
    width: "auto",
    height: "120px",
  },
  moreItemThumbnailImage: {
    width: "60px",
    height: "60px",
    backgroundImage: `url(${IconMore})`,
    backgroundSize: "60px",
    "&:active": {
      backgroundImage: `url(${IconMorePressed})`,
    },
  },
  info: {
    flexGrow: 1,
    minHeight: "9.688rem",
  },
  infoTitle: {
    fontSize: theme.font.size15, // "15px",
    fontWeight: "bold",
    color: theme.color.text,
    textAlign: "center",
    padding: "0px 20px",
  },
  infoDescription: {
    fontSize: theme.font.size12,
    fontWeight: "300",
    color: theme.color.message.date,
    textAlign: "center",
  },
  table: {
    width: "-webkit-fill-available",
    margin: "12px 20px",
    padding: "0px",
    // lineHeight: "18px"
  },
  tableRow: {
    borderBottom: `1px solid ${theme.color.list_divider}`,
    height: "100%",
    display: "flex",
    alignItems: "baseline",
  },
  label: {
    height: "100%",
    minHeight: "2rem",
    fontSize: theme.font.size13,
    fontWeight: "normal",
    color: theme.color.text,
    textAlign: "start",
    padding: "0px 8px",
    display: "flex",
    alignItems: "center",
  },
  value: {
    fontSize: theme.font.size13,
    fontWeight: "normal",
    color: theme.color.text,
    textAlign: "start",
    padding: "0px 8px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    // whiteSpace: "nowrap",
    // maxWidth: '0',
    display: "flex",
    alignItems: "center",
  },
  horizontalDivider: {
    width: "100%",
    height: "1px",
    backgroundColor: theme.color.list_divider,
  },
  external: {
    display: "flex",
    minHeight: "2.813rem",
    borderRadius: "0px 0px 12px 12px",
    background: "#fafafa",
  },
  externalHorizontal: {
    display: "flex",
    height: "45px",
    background: "#f7f0f0",
    flexDirection: "column",
  },
  endBorderRadius: {
    borderRadius: "0 0 12px 12px",
  },
  externalButton: {
    color: theme.color.accent,
    fontWeight: "bold",
    fontSize: theme.font.size14,
    flex: 1,
    border: "none",
    borderRadius: "0px 0px 12px 12px",
    padding: 5,
    background: "#fafafa",
    "&:active": {
      background: "rgba(38, 38, 38, 0.1)",
    },
    whiteSpace: "pre-wrap",
    lineHeight: 1.5,
  },
  externalButtonLeft: {
    borderRadius: "0px 0px 0px 12px",
  },
  externalButtonRight: {
    borderRadius: "0px 0px 12px 0px",
  },
  horizontalButton: {
    width: "100%",
    height: "45px",
    textAlign: "center",
    color: "#707070",
    fontSize: theme.font.size14,
    fontWeight: "bold",
    padding: "0px",
    backgroundColor: "#f7f7f7",
  },
  disabledButton: {
    color: "rgba(38, 38, 38, 0.4)",
  },
  externalDivider: {
    height: "20px",
    alignSelf: "center",
    backgroundColor: theme.color.list_divider,
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  buttonIcon: {
    width: "20px",
    height: "20px",
    marginRight: "4px",
  },
  horizontalButtonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    padding: "8px 18px",
  },
  horizontalButtonLabel: {
    display: "flex",
    flexDirection: "row",
  },
  priceRow: {
    height: "100%",
    display: "flex",
    alignItems: "baseline",
  },
  priceObs: {
    fontWeight: "bold",
    fontSize: theme.font.size16,
    color: theme.color.message.text,
    marginStart: "8px",
    wordBreak: "breakWord",
  },
  priceMaster: {
    textDecoration: "line-through",
    fontSize: theme.font.size14,
    color: theme.color.message.date,
    marginStart: "8px",
  },
});

const Card = React.forwardRef((props, ref) => {
  const { classes, data } = props;
  const context = useContext(DataContext);

  const handleClick = (item) => {
    if (!ref.clickAllowed()) {
      return;
    }

    if (item && item.type && item.type.toLowerCase() === "speech") {
      context.request(context, item.speech, "button");
    }
  };

  let cardList = data.items.map((item, key) => (
    <div key={`SelectCardList${key}`} className={classes.listitem}>
      <div className={classes.card}>
        <div className={classes.thumbnail}>
          <img
            className={classes.thumbnailImage}
            src={item.image}
            alt={item.speech}
          />
        </div>
        <Divider
          orientation={"horizontal"}
          className={classes.horizontalDivider}
          component={"div"}
          disabled
        />
        <div className={[classes.external].join(" ")}>
          <Button
            type="button"
            className={[classes.externalButton].join(" ")}
            disabled={context.conversationEnd || !data.isLast}
            disableRipple
            onClick={() => handleClick(item)}
            aria-label={item.speech}
          >
            {item.speech}
          </Button>
        </div>
      </div>
    </div>
  ));
  return cardList;
});

function SCL(props) {
  const { classes, data } = props;
  const context = useContext(DataContext);

  return (
    <>
      <div className={classes.root}>
        <Carousel
          className={classes.carousel}
          component={withStyles(styles)(Card)}
          data={data}
          ariaLabel={context.string.aria.product}
        />
      </div>
    </>
  );
}
export default withStyles(styles)(SCL);
