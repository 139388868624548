import React, { useContext, Fragment } from "react";
import { withStyles, Divider, Grid } from "@material-ui/core";
import DataContext from "../../data/DataContext";
import LeftTail from "../../../asset/img/bubble_point1.png";
import TypingData from "../../../asset/img/img_text_loading.json";
import Lottie from "react-lottie";

const styles = (theme) => ({
  root: {
    display: "flex",
    letterSpacing: "-0.5px",
  },
  leftContainer: {
    justifyContent: "flex-start",
  },
  rightContainer: {
    justifyContent: "flex-end",
  },
  message: {
    fontSize: theme.font.size15, // "15px",
    borderRadius: "12px",
    flexWrap: "wrap",
    color: theme.color.message.color,
    maxWidth: "75%",
    zIndex: 10,
    wordWrap: "break-word",
  },
  leftMessage: {
    backgroundColor: "white",
    margin: "6px 6px 6px 9px",
    boxShadow: "0px 12px 12px rgba(194, 194, 194, 0.58)",
    fontWeight: "300",
  },
  rightMessage: {
    backgroundColor: theme.color.message.background,
    margin: "8px 10px",
    boxShadow: "none",
  },
  text: {
    padding: "9px 20px",
  },
  date: {
    color: theme.color.message.date,
    fontSize: theme.font.size11,
    fontWeight: "300",
    alignSelf: "flex-end",
    margin: "5px 0 6px 0",
    textAlign: "center"
  },
  typing: {
    width: "30px",
    height: "6px",
  },
  list: {
    display: "flex",
    width: "100%",
    borderRadius: "0px 0px 12px 12px",
    border: "0px",
    alignItems: "center",
    background: "#fafafa"
  },
  item: {
    height: "40px",
    textAlign: "center",
    color: theme.color.accent,
    fontSize: theme.font.size14,
    fontWeight: "bold",
    padding: "0px",
    margin: "0px",
    flex: 1,
    backgroundColor: "#FAFAFA",
    textTransform: "none",
    outline: "0 none",
    border: "0px",
    borderRadius: "0px 0px 12px 12px",
    "&:active": {
      backgroundColor: "rgba(38, 38, 38, 0.1)",
    },
  },
  itemLeft: {
    borderRadius: "0px 0px 0px 12px",
  },
  itemRight: {
    borderRadius: "0px 0px 12px 0px",
  },
  itemDisabled: {
    color: "rgba(38, 38, 38, 0.4)"
  },
  vertical: {
    height: "20px",
    backgroundColor: theme.color.list_divider
  },
  leftTail: {
    // borderLeft: '14px solid transparent',
    // borderRight: '14px solid transparent',
    // borderTop: '18px solid #fff',
    // borderRadius: '5px',
    zIndex: 11,
    margin: "6px -21px 0px 11px",
    backgroundImage: `url(${LeftTail})`,
    backgroundSize: "21px 12px",
    width: "21px",
    height: "12px",
    minWidth: "21px"
  },
  ti: {
    width: "100%",
    padding: "12px 20px",
    margin: "0px",
  },
  title: {
    fontSize: theme.font.size15, // "15px",
    fontWeight: "bold",
    color: theme.color.text
  },
  tiList: {
    marginTop: "12px",
    color: theme.color.text
  },
  listRow: {
    display: "flex",
    alignItems: "center"
  },
  key: {
    minWidth: "3.75rem",
    height: "2rem",
    fontSize: theme.font.size12,
    fontWeight: "bold",
    display: "flex",
    alignItems: "center"
  },
  value: {
    height: "2rem",
    fontSize: theme.font.size15, // "15px",
    fontWeight: "300",
    display: "flex",
    alignItems: "center"
  }
});

const lottieTypingOptions = {
  loop: true,
  autoplay: true,
  animationData: TypingData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}


function CB(props) {
  const { classes, data } = props;
  const context = useContext(DataContext);

  const handleClick = (button) => {
    context.request(context, button.speech, "button");
  };

  return (
    <>
      <div
        className={[
          classes.root,
          data.mine ? classes.rightContainer : classes.leftContainer,
        ].join(" ")}
      >
        {!data.loading && data.mine && (
          <p className={classes.date}>{data.date}</p>
        )}
        {!data.mine && <div className={classes.leftTail} />}
        <div
          className={[
            classes.message,
            data.mine ? classes.rightMessage : classes.leftMessage,
          ].join(" ")}
        >
          {data.loading && (
            <Lottie
              options={lottieTypingOptions}
              className={classes.messageLoading}
              width={50}
              height={22}
            />
          )}

          {data.text &&
            <div className={classes.text}>
              {!data.loading &&
                data.text.split("\n").map((content, index) => (
                  <Fragment key={index}>
                    {content}
                    <br />
                  </Fragment>
                ))}
            </div>
          }

          {data.subType && data.subType.toUpperCase() === "TI" &&
            <div className={classes.ti}>
              <div className={classes.title}>
                {data.title}
              </div>
              <div className={classes.tiList}>
                {data.listItems.map((item) => (
                  <Grid container className={classes.listRow} role={"text"}>
                    <Grid item className={classes.key}>{item.key}</Grid>
                    <Grid item className={classes.value}>{item.value}</Grid>
                  </Grid>
                ))}
              </div>
            </div>
          }

          <Divider component={"div"} disabled/>
          {!data.loading &&
            <div className={classes.list}>
              {data.buttons.map((button, index) => (
                <>
                  <Divider
                    className={classes.vertical}
                    orientation={"vertical"}
                    hidden={index === 0}
                    component={"div"}
                    disabled
                  />
                  <button
                    type="button"
                    className={[
                      classes.item,
                      index === 0 &&
                        data.buttons.length > 1 ?
                        classes.itemLeft : "",
                      index === data.buttons.length - 1 &&
                        data.buttons.length > 1 ?
                        classes.itemRight : "",
                      !data.isLast || context.conversationEnd ?
                        classes.itemDisabled: ""
                    ].join(" ")}
                    disabled={!data.isLast || context.conversationEnd}
                    onClick={() => handleClick(button)}
                  >
                    {button.label}
                  </button>
                </>
              ))}
            </div>
          }
        </div>
        {!data.loading && !data.mine && (
          <p className={classes.date}>{data.date}</p>
        )}
      </div>
    </>
  );
}
export default withStyles(styles)(CB);
