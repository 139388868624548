import React, { createContext, useContext, useEffect, useState } from "react";
import aws_export from "../../asset/aws/livechat-aws-exports";
import Amplify, { API } from "aws-amplify";
import gql from "graphql-tag";
import DataContext from "./DataContext";
import InputContext from "./InputContext";

import { makeTXMessage, makeTypingMessage } from "./DataConvert";

const createTicket = gql`
  mutation Mutation(
    $branch: String
    $categoryId: String
    $customerId: String
    $description: String
  ) {
    createTicket(
      branch: $branch
      categoryId: $categoryId
      customerId: $customerId
      description: $description
    ) {
      branch
      convId
      categoryId
      counselorId
      created
      description
      statusId
      customerId
      ticketId
    }
  }
`;

const customerRequestTicket = gql`
  mutation Mutation($branch: String, $client: String, $customerId: String) {
    customerRequestTicket(branch: $branch, client: $client, customerId: $customerId) {
      assignedAt
      branch
      categoryId
      chatInfo
      chatUrl
      client
      convId
      counselorId
      created
      customerEmail
      customerId
      customerMobile
      customerName
      description
      statusId
      ticketId
    }
  }
`;

const getCategoryList = gql`
  query Query($branch: String!) {
    getCategoryList(branch: $branch) {
      branch
      categoryId
      name
    }
  }
`;

const getBranchPhrase = gql`
  query Query($branch: String!, $phraseType: String!) {
    getBranchPhrase(branch: $branch, phraseType: $phraseType) 
  }
`;

const updateTicket = gql`
  mutation Mutation($categoryId: String!, $ticketId: String!) {
    ticketUpdateCategory(categoryId: $categoryId, ticketId: $ticketId) {
      assignedAt
      categoryId
      branch
      chatInfo
      chatUrl
      client
      convId
      counselorId
      created
      customerEmail
      customerMobile
      customerId
      description
      customerName
      deviceInfo
      statusId
      ticketId
      userId
    }
  }
`;

const updateTicketRating = gql`
  mutation Mutation($ticketId: String!, $customerRating: String!, $customerComment: String!) {
    updateTicketRating(ticketId: $ticketId, customerRating: $customerRating, customerComment: $customerComment) {
      ticketId
      customerRating,
      customerComment
    }
  }
`;

const endConversation = gql`
  mutation Mutation($convId: String!) {
    endConversation(convId: $convId) {
      ticketId,
      customerId,
      created,
      assignedAt,
      branch,
      categoryId,
      description,
      statusId,
      counselorId,
      convId,
      chatUrl,
      chatInfo,
      customerName,
      customerEmail,
      customerMobile,
      client
    }
  }
`;

const sendMessage = gql`
  mutation Mutation($contentJson: String, $convId: String, $senderId: String) {
    sendMessage(
      contentJson: $contentJson
      convId: $convId
      senderId: $senderId
    ) {
      contentJson
      convId
      created
      isSent
      msgId
      senderId
    }
  }
`;

const subscribeMessage = gql`
  subscription Subscription($convId: String!) {
    newMessage(convId: $convId) {
      contentJson
      convId
      created
      isSent
      msgId
      senderId
    }
  }
`;

const subscribeTicket = gql`
  subscription Subscription($ticketId: String) {
    ticketUpdated(ticketId: $ticketId) {
      assignedAt
      branch
      chatInfo
      categoryId
      chatUrl
      client
      convId
      counselorId
      created
      customerEmail
      customerId
      customerMobile
      customerName
      deviceInfo
      description
      ticketId
      statusId
      userId
    }
  }
`;

const subscribeNewConversation = gql`
  subscription Subscription($counselorId: String, $ticketId: String) {
    newConversation(counselorId: $counselorId, ticketId: $ticketId) {
      convId
      counselorId
      created
      customerId
      ended
      ticketId
    }
  }
`;

const close = gql`
  mutation Mutation($ticketId: String) {
    closeTicket(ticketId: $ticketId) {
      branch
      categoryId
      chatInfo
      chatUrl
      convId
      counselorId
      created
      description
      ticketId
      statusId
      customerId
    }
  }
`;

const getTicketsNotAssigned = gql`
  query Query($branch: String, $categoryId: [String], $nextCursor: String, $resultLimit: Int, $orderAsc: Boolean) {
    getTicketsNotAssigned(branch: $branch, categoryId: $categoryId, nextCursor: $nextCursor, resultLimit: $resultLimit, orderAsc: $orderAsc) {
      info {
        ... on Ticket {
          __typename
          branch
          categoryId
          chatInfo
          chatUrl
          convId
          counselorId
          created
          customerId
          description
          statusId
          ticketId
        }
      }
      pageinfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

const  AIMLClassifySentimentShort = gql`
  query Query($text: String!) {
    AIMLClassifySentimentShort(text: $text)
  }
`

const initialLiveChatContext = {
  enable: false,
  openStarRating: false
};

const LiveChatContext = createContext();

const LiveChatProvider = (props) => {
  const { children } = props;
  const [livechatContext, setLiveChatContext] = useState(LiveChatContext);
  const [typing, setTyping] = useState(false);

  const data = useContext(DataContext);
  const input = useContext(InputContext);

  const [ticket, setTicket] = useState({});
  var categoryList = null;
  var subscriptionTicket = null;
  var subscriptionMessage = null;


  /**
   * 1 번 과정
   * - 티켓이 생성되거나 업데이트 될 때마다 해당 브랜치의 카테고리 정보를 다시 불러온다.
   */
   const requestCategory = (branch = null, callback) => {

    API.graphql({
      query: getCategoryList,
      authMode: "AWS_IAM",
      variables: { branch: branch || ticket.branch },
    })
      .then((result) => {
        console.log("[LiveChat] requestCategory Result:", result);
        categoryList = result.data.getCategoryList;

        if (callback) callback(result.data.getCategoryList);
      })
      .catch((error) => {
        console.log("[LiveChat] requestCategory Error:", error);
      });
  };

  /**
   * 2 번 과정
   * - 고객에게 카테고리를 확인한다.
   * @param {Object} ticket 
   * @param {Array} list 
   */
   const askCategory = (ticket, sessionId, list) => {
    list.map((item) => {
      item.frontActionFunc = () => {
        data.addTXMessage(true, item.speech);
        updateTicketCategory(ticket, item.categoryId);
      };
      return item;
    });

    console.log("[LiveChat] Question SL ListItem:", list);

    data.addLiveChatMessage(
      "no category",
      "궁금하신 제품을 선택해주시면 상담원이 도와드리겠습니다.",
      list
    );
  };

  /**
   * 3 번 과정
   * - 고객 세션 아이디를 통해 티켓 생성을 요청한다.
   * @param {String} customerId 세션 아이디
   */
  const requestTicket = (customerId, branch) => {
    console.log("[requestTicket] customerId", customerId);

    setLiveChatContext((prev) => {
      prev.enable = true;
      return prev;
    });

    return new Promise((resolve, reject) => {
      API.graphql({
        query: customerRequestTicket,
        authMode: "AWS_IAM",
        variables: { branch , client: "thinq", customerId: customerId },
      })
        .then((result) => {
          console.log("[requestTicket] Result", result);
          const ticket = result.data.customerRequestTicket;
          setTicket(ticket);
          subsTicket(ticket);

          resolve(ticket);
        })
        .catch((error) => {
          console.log("[requestTicket] Error", error);
          reject(error);
        });

    })

    
  };

  /**
   * 4 번 과정
   * - 티켓의 업데이트 정보를 구독한다.
   * @param {Object} ticket 티켓 정보
   */
  const subsTicket = (ticket) => {
    if (!ticket || !livechatContext.enable) return;

    subscriptionTicket = API.graphql({
      query: subscribeTicket,
      authMode: "AWS_IAM",
      variables: { convId: ticket.convId, ticketId: ticket.ticketId },
    }).subscribe({
      next: (result) => {
        console.log("[LiveChat] Subscribe Ticket Result", result);
        setTicket(result.value.data.ticketUpdated);
        const ticketInfo = result.value.data.ticketUpdated;
      },
      error: (error) => {
        console.log("[LiveChat] Subscribe Ticket Error", error);
      },
    });
  };

  /**
   * 5 번 과정
   * - 티켓이 생성되거나 업데이트 될 때마다 새로운 메시지를 구독한다.
   */
  const subsMessage = () => {
    console.log(`subsMessage ${ticket}`)
    console.log(`ticketStatus: ${ticket.statusId}`)
    return API.graphql({
      query: subscribeMessage,
      authMode: "AWS_IAM",
      variables: { convId: ticket.convId, ticketId: ticket.ticketId },
    }).subscribe({
      next: (result) => {
        const content = JSON.parse(result.value.data.newMessage.contentJson);
        console.log("[LiveChat] Subscribe Message Result", content);
        if (content.template_type.toLowerCase() === "typing") {
          if (result.value.data.newMessage.senderId !== ticket.customerId) {
            data.setTyping(true);
          }
        } else {
          let contentType = content.template_type?content.template_type.toLowerCase():null;
          if (!contentType || contentType === 'tx'){
            data.addLiveChatMessage(
              content.mine ? "me" : "message",
              content.text
            );
          }
          else {
            data.addLiveChatMessage(content.template_type, content);
          }
          
          data.setTyping(false);
        }
      },
      error: (error) => {
        console.log("[LiveChat] Subscribe New Message Error", error);
      },
    });
  };

  

  useEffect(() => {
    console.log('useEffect', ticket, livechatContext);
    if (!ticket || !livechatContext.enable) return;

    if (ticket && (ticket.statusId === "status_followup" || ticket.statusId === "status_closed")) {
      setLiveChatContext(prev => {
        prev.enable = false;
        return { ...prev };
      });
    }

    subscriptionMessage = subsMessage();
    // requestCategory();

    return () => {
      if (subscriptionMessage) {
        subscriptionMessage.unsubscribe();
      }
    };
  }, [ticket]);

  useEffect(() => {
    // requestCategory();
    return () => {
      if (subscriptionTicket) subscriptionTicket.unsubscribe();
    };
  }, []);

  

  /**
   * - 카테고리로 티켓을 업데이트 한다.
   * @param {Object} ticket 
   * @param {String} categoryId 
   */
  const updateTicketCategory = (ticket, categoryId) => {

    console.log('[LiveChat] updateTicketCategory ticket', ticket);

    API.graphql({
      query: updateTicket,
      authMode: "AWS_IAM",
      variables: { categoryId: categoryId, ticketId: ticket.ticketId },
    })
      .then((result) => {
        console.log("[LiveChat] updateTicketCategory Result", result);
        const category = categoryList && categoryList.find(item => item.categoryId === result.data.ticketUpdateCategory.categoryId);
        data.addLiveChatMessage(
          "you",
          `${category.name} 카테고리로 상담이 접수 되었습니다.`
        );
        requestNotAssigned();
      })
      .catch((error) => {
        console.log("[LiveChat] updateTicketCategory Error", error);
      });
  };

  /**
   * 5 번 과정
   * - 할당 되지 않은 티켓 수를 통해 대기열을 보여준다.
   */
  const requestNotAssigned = () => {

    console.log("[LiveChat] requestNotAssigned ticket:", ticket);

    API.graphql({
      query: getTicketsNotAssigned,
      authMode: "AWS_IAM",
      variables: { branch: ticket.branch, categoryId: [ticket.categoryId] },
    })
      .then((result) => {
        console.log("[LiveChat] requestNotAssigned Result", result);
        const tickets = result.data.getTicketsNotAssigned.info;
        console.log(tickets)
        const size = tickets.length;//tickets.size() - 1;
        if (size > 0) {
          data.addLiveChatMessage(
            "you",
            `현재 대기중인 고객님 수는 ${size} 명 입니다. 빠른 상담을 도움 드리지 못해 죄송합니다. 대기시간 중 문의 내용을 입력해 주시면 보다 빠른 상담이 가능합니다`
          );
        } else {
          data.addLiveChatMessage(
            "you",
            `상담사와 연결중이오니 잠시 기다려주세요. 대기시간 중 문의 내용을 입력해 주시면 보다 빠른 상담이 가능합니다.`
          );
        }
      })
      .catch((error) => {
        console.log("[LiveChat] requestNotAssigned Error", error);
      });
  };

  /**
   * 로그인과 함께 다음의 Flow를 따른다.
   *
   * 1. requestCategory         : 카테고리를 불러온다.
   * 2. requestTicket           : 고객 세션 아이디를 통해 티켓 생성을 요청한다.
   * 2-1. askCategory           : 카테고리가 기타이면 사용자에게 카테고리를 선택 요청한다.
   * 2-2. updateTicketCategory  : 티켓에 해당 카테고리정보를 넣는다.
   * 3. requestNotAssigned      : 현재 대기열(할당되지 않은 티켓 수)을 가져온다.
   * 
   */
  const login = async (sessionId, branch) => {
    console.log(aws_export);
    Amplify.configure(aws_export);

    branch = (branch)?branch:'cs';

    // 카테고리 요청
    requestCategory(branch, (categoryList) => {
      console.log("[requestCategory] callback start");
      // if (ticket.categoryId === null) {
      //   const list = categoryList.map((item) => {
      //     return { ...item, speech: item.name, label: item.name };
      //   });
      //   console.log('test ticket', ticket);
      //   askCategory(sessionId, list);
      // } else {
      //   requestNotAssigned();
      // }

      requestTicket(sessionId, branch).then((ticket)=> {
        // 카테고리가 기타로 되어 있어 고객이 선택하는 경우 
        if (ticket.categoryId === "category#cs#25") {
          const list = categoryList.map((item) => {
            return { ...item, speech: item.name, label: item.name };
          });
          askCategory(ticket, sessionId, list);
        }
        // 기존에 카테고리가 이미 있는 경우
        else {
          const category = categoryList && categoryList.find(c => c.categoryId === ticket.categoryId);
          data.addLiveChatMessage(
            "you",
            `${category.name} 카테고리로 상담이 접수 되었습니다.`
          );
          requestNotAssigned();
        }
      }).catch(err => {
        console.error(err);
      });
    });
  };

  // 텍스트 전송
  const send = async (text, isMine = true) => {
    let result = null;
    try {
      result = await API.graphql({
        query: AIMLClassifySentimentShort,
        authMode: "AWS_IAM",
        variables: {
          text: text
        },
      })
    }
    catch (error) {
      console.log("[LiveChat] Send Error", error)
    }
    try {
      let sentimentalResult = (result?.data?.AIMLClassifySentimentShort)
      const contentJson = JSON.stringify(makeTXMessage(isMine, text, false, sentimentalResult));
      result = await API.graphql({
        query: sendMessage,
        authMode: "AWS_IAM",
        variables: {
          contentJson: contentJson,
          convId: ticket.convId,
          senderId: ticket.customerId,
        },
      });
    }
    catch (error) {
      console.log("[LiveChat] Send Error", error)
    }
  };

  const [myTyping, setMyTyping] = useState(false);

  // 타이핑 전송
  useEffect(() => {
    console.log("typing:", myTyping, "input:", input.input);

    const resetTimer = () => {
      if (!window.livechat) window.livechat = {};
      if (window.livechat.myTypingTimer) clearTimeout(window.livechat.myTypingTimer);
      window.livechat.myTypingTimer = setTimeout(() => {
        setMyTyping(false);
        console.log("myTyping timer end")
      }, 5000);
    };

    if (!myTyping && input.input && input.input.length > 0) {
      const contentJson = JSON.stringify(makeTypingMessage());
      API.graphql({
        query: sendMessage,
        authMode: "AWS_IAM",
        variables: {
          contentJson: contentJson,
          convId: ticket.convId,
          senderId: ticket.customerId,
        },
      })
        .then((result) => console.log("[LiveChat] Send Result", result))
        .catch((error) => console.log("[LiveChat] Send Error", error));

      setMyTyping(true);
      resetTimer();
    }
  }, [input.input]);

  const openRating = () => {
    setLiveChatContext(prev => {
      prev.openStarRating = true;
      return { ...prev };
    });
  };

  const closeRating = (rating, comment) => {
    setLiveChatContext(prev => {
      prev.openStarRating = false;
      return { ...prev };
    });

    // 별점과 상담평가가 있는 경우
    if (rating && comment) {
      API.graphql({
        query: updateTicketRating,
        authMode: "AWS_IAM",
        variables: {
          ticketId: ticket.ticketId,
          customerRating: rating,
          customerComment: comment
        },
      })
        .then((result) => console.log("[LiveChat] updateTicketRating Result", result))
        .catch((error) => console.log("[LiveChat] updateTicketRating Error", error));
    }
    console.log(`getBranchPhrase : branch -${data.livechatBranch}`)
    API.graphql({
      query: getBranchPhrase,
      authMode: "AWS_IAM",
      variables: { branch: data.livechatBranch || ticket.branch, phraseType:"END_CONV" },
    })
      .then((result) => {
        console.log("[LiveChat] getBranchPhrase Result:", result);
        let phraseEndConv = result.data.getBranchPhrase;
        const contentJson = JSON.stringify(makeTXMessage(false, phraseEndConv, false));
        API.graphql({
          query: sendMessage,
          authMode: "AWS_IAM",
          variables: {
            contentJson: contentJson,
            convId: ticket.convId,
            senderId: "system",
          },
        })
          .then((result) => console.log("[LiveChat] Send Result", result))
          .catch((error) => console.log("[LiveChat] Send Error", error))
      })
      .catch((error) => {
        console.log("[LiveChat] getBranchPhrase Error:", error);
      })
      .finally(()=>{
        // 상담 종료
        API.graphql({
          query: endConversation,
          authMode: "AWS_IAM",
          variables: {
            convId: ticket.convId
          },
        })
          .then((result) => console.log("[LiveChat] endConversation Result", result))
          .catch((error) => console.log("[LiveChat] endConversation Error", error));
      })
    

    // data.addLiveChatMessage("you", "상담이 종료되었습니다.");
    setLiveChatContext(initialLiveChatContext);
    if (subscriptionMessage) subscriptionMessage.unsubscribe();
    if (subscriptionTicket) subscriptionTicket.unsubscribe();
  }

  const api = {
    login: login,
    send: send,
    openRating: openRating,
    closeRating: closeRating
  };

  return (
    <LiveChatContext.Provider
      value={{ livechat: livechatContext, livechatApi: api, ticket: ticket }}
    >
      {children}
    </LiveChatContext.Provider>
  );
};

const useLiveChat = () => {
  const context = useContext(LiveChatContext);
  if (!context) {
    throw new Error("useLiveChat must be used within a LiveChatContext.");
  }
  return context;
};

export { LiveChatProvider, useLiveChat };
