import React, { useRef, useContext } from "react";
import { withStyles } from "@material-ui/core";
import Message from "./Message/Message";
import moment from "moment";
import "moment/locale/ko";
import DataContext from "../data/DataContext";
// import InputContext from "../data/InputContext";
import OldChat from "./OldChat";

const styles = (theme) => ({
  root: {
    height: "auto",
    width: "100%",
    overflowY: "auto",
    overflowX: "hidden",
    margin: "0px",
    flex: "1",
    backgroundColor: theme.color.background
  },
  content: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  date: {
    textAlign: "center",
    color: "#707070",
    fontSize: theme.font.size11,
    lineHeight: "16px",
    margin: "16px 0px 8px 0px",
  },
});

function Chat(props) {
  const { classes } = props;
  const scrollRef = useRef(null);
  // const input = useContext(InputContext);
  const data = useContext(DataContext);

  const scrollToBottom = (smooth, delay = 300) => {
    setTimeout(() => {
      scrollRef.current.scrollIntoView({
        behavior: smooth ? "smooth" : "auto",
      });
      data.setShowOldConversations(false);
    }, delay);
  };
  data.scroll = scrollToBottom;

  const handleScroll = (e) => {
    e.preventDefault();
    if (e.target.scrollTop <= 200 && !data.isShowOldConversations) {
      data.setShowOldConversations(true);
    }

    // if (window.isTabbed) {
    //   closeKeyboard();
    // }
  };

  // const closeKeyboard = (e) => {
  //   if (input.keyboard.open) input.keyboard.setOpen(false);
  // }

  return (
    <div
      className={classes.root}
      onScroll={handleScroll}
    >
      <div className={classes.content}>
        <div className={classes.date}>
          {moment()
            .locale(data.string.locale)
            .format(data.string.chat.dateFormat)}
        </div>
        <OldChat />
        {data.conversations.map((message, key) => (
          <Message key={key} data={message} />
        ))}
        <div ref={scrollRef} />
      </div>
    </div>
  );
}
export default withStyles(styles)(Chat);
