import React from 'react';
import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import RouteMap from './component/page/RouteMap';
import theme from './component/theme/theme';
// import Font from './component/font/Font';
import { ToastProvider } from 'react-toast-notifications';

function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <ToastProvider
        autoDismiss
        autoDismissTimeout={3000}
        placement="top-center">
        <CssBaseline/>
        {/* <Font/> */}
        <RouteMap/>
      </ToastProvider>
    </MuiThemeProvider>
  );
}

export default App;
