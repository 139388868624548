import React, { useContext } from "react";
import TX from "./TX";
import SG from "./SG";
import SL from "./SL";
import CB from "./CB";
import TL from "./TL";
import ProductCard from "./ProductCard";
import BC from "./BC";
import CL from "./CL";
import LO from "./LO";
import RESERVE from "./RESERVE";
import HELPCARD from "./HELPCARD";
import EventCard from "./EventCard";
import CareCard from "./CareCard";
import TICard from "./TICard";
import Debug from "./Debug";
import DeviceGrid from "./DeviceGrid";
import PC from "./PC";
import DataContext from "../../data/DataContext";
import SCL from "./SCL";
import StoryCard from "./StoryCard";

function Message(props) {
  const { data } = props;
  const context = useContext(DataContext);

  const routeCarousel = (data) => {
    const subType = data.template_sub_type.toUpperCase();
    switch(subType){
      case 'CONTENTLIST': return <CL data={data}/>
      case 'HELPCARD': return <HELPCARD data={data}/>
      case 'PRODUCTCARD': return <ProductCard data={data}/>
      case 'EVENTCARD': return <EventCard data={data}/>
      case 'CARECARD': return <CareCard data={data}/>
      case 'TICARD': return <TICard data={data}/>
      default: return null;
    }
  }

  const getMessage = () => {
    if (data && (data.isOld === true ? context.isShowOldConversations : true)) {
      switch(data.type.toUpperCase()) {
        case 'TX': return <TX data={data}/>
        case 'CB': return <CB data={data}/>
        case 'TL': return <TL data={data}/>
        case 'SL': return <SL data={data}/>
        case 'SG': return <SG data={data}/>
        case 'BC': return <BC data={data}/>
        case 'CL': return <CL data={data}/>
        case 'LO': return <LO data={data}/>
        case 'RESERVE': return <RESERVE data={data}/>
        case 'HELPCARD': return <HELPCARD data={data}/>
        case 'PRODUCTCARD': return <ProductCard data={data}/>
        case 'EVENTCARD': return <EventCard data={data}/>
        case 'CARECARD': return <CareCard data={data}/>
        case 'TICARD': return <TICard data={data}/>
        case 'SELECTCARDLIST': return <SCL data={data}/>
        case 'CAROUSEL': return routeCarousel(data)
        case 'DEBUG': return <Debug data={data}/>
        case 'GRID': return <DeviceGrid data={data}/>
        case 'PC': return <PC data={data}/>
        case 'STORYCARD': return <StoryCard data={data}/>
        default: return null;
      }
    }
  }

  return (
    <>
      {getMessage()}
    </>
  );
}
export default Message;
