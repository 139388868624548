import React, { useContext, Fragment } from "react";
import { withStyles, Divider, Button } from "@material-ui/core";
import Carousel from "../../carousel/Carousel";
import DataContext from "../../data/DataContext";
import { openLink } from "../../util/utilNative";

const styles = (theme) => ({
  root: {
    position: "relative",
    letterSpacing: "-0.5px",
    margin: "6px 0px",
    zIndex: 1,
  },
  carousel: {
    width: "100%",
    height: "144px",
  },
  item: {
    height: "37px",
    textAlign: "center",
    color: theme.color.select.color,
    fontSize: theme.font.size14,
    fontWeight: "bold",
    padding: "0px",
    flex: 1,
  },
  vertical: {
    height: "auto",
  },
  list: {
    display: "flex",
    margin: "0px 20px 0px 20px",
  },
  listitem: {
    width: "288px",
    padding: "0px 8px 0px 0px",
  },
  card: {
    width: "280px",
    height: "100%",
    flex: "auto",
    background: "#fff",
    border: "1px solid #e1e1e1",
    borderRadius: "12px",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
  },
  thumbnail: {
    height: "200px",
    // padding: "15px 19px 12px",
  },
  thumbnailImage: {
    // width: "auto",
    // height: "103px",
    width: "100%",
    borderRadius: "12px 12px 0px 0px",
    height: "200px",
  },
  date: {
    fontSize: theme.font.size14,
    color: "#262626",
    lineHeight: "1",
    margin: "12px 20px",
    textAlign: "start",
  },
  external: {
    display: "flex",
    height: "50px",
    background: "#fafafa",
    borderRadius: "0 0 12px 12px",
    borderTop: "1px solid #e1e1e1",
    marginTop: "10px",
  },
  externalButton: {
    color: theme.color.accent,
    fontWeight: "bold",
    fontSize: theme.font.size14,
    flex: 1,
    border: "none",
    padding: 0,
    background: "transparent",
    "&:active": {
      background: "rgba(38, 38, 38, 0.1)",
    },
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  title: {
    fontSize: theme.font.size15, // "15px",
    fontWeight: "bold",
    color: theme.color.text,
    padding: "8px 16px",
    flexGrow: 1,
    textOverflow: "ellipsis",
    overflow: "hidden",
    // whiteSpace: "nowrap",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    textAlign: "start",
  },
});

const Card = React.forwardRef((props, ref) => {
  const { classes, data } = props;
  const context = useContext(DataContext);

  const handleClick = (url, action, event) => {
    if (!ref.clickAllowed()) {
      return;
    }
    openLink(context, action, url, event);
  };

  return data.items.map((item, key) => (
    <div
      key={key}
      className={classes.listitem}
      onClick={() =>
        handleClick(
          context.userAgent.deviceType === "mobile"
            ? item.link.mobile_url
            : item.link.url,
          item.link.type,
          item.link.event_action
        )
      }
      role={"button"}
      tabIndex={0}
      aria-label={`${
        item.buttons[0].parameters.action === "external"
          ? context.string.aria.external + ","
          : ""
      } ${item.title}, ${item["start_date"]}에서 ${
        item["end_date"]
      }까지, 상세보기, ${key + 1}/${data.items.length}, 항목${
        data.items.length
      }개`}
    >
      <div className={classes.card}>
        <div className={classes.thumbnail}>
          <img
            className={classes.thumbnailImage}
            src={item["thumbnail_url"]}
            alt={item.title}
          />
        </div>
        {item.title && <div className={classes.title}>{item.title}</div>}
        <div className={classes.date}>
          {item["start_date"]} ~ {item["end_date"]}
        </div>

        <div className={classes.external}>
          {item.buttons.map((button, index) => (
            <Fragment key={index}>
              <Divider
                className={classes.externalDivider}
                orientation="vertical"
                hidden={index === 0}
                component={"div"}
                disabled
              />
              <button
                type="button"
                className={classes.externalButton}
                disabled={context.conversationEnd}
                onClick={() =>
                  handleClick(
                    context.userAgent.deviceType === "mobile"
                      ? button.parameters.mobile_url
                      : button.parameters.url,
                    button.parameters.action,
                    button.event_action
                  )
                }
              >
                <div className={classes.buttonContainer}>
                  <div>{button.label}</div>
                </div>
              </button>
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  ));
});

function EventCard(props) {
  const { classes, data } = props;
  const context = useContext(DataContext);

  return (
    <>
      <div className={classes.root}>
        <Carousel
          className={classes.carousel}
          component={withStyles(styles)(Card)}
          data={data}
          ariaLabel={context.string.aria.event}
        />
      </div>
    </>
  );
}
export default withStyles(styles)(EventCard);
