import React, { useContext, useState } from "react";
import { withStyles, Divider } from "@material-ui/core";
import Carousel from "../../carousel/Carousel";
import DataContext from "../../data/DataContext";
import { postSmileExt } from "../../data/DataLoader";
import LP from "./LP";
import Engineer from "../../../asset/img/img_profile_default.png";
import moment from "moment";

const styles = (theme) => ({
  root: {
    position: "relative",
    letterSpacing: "-0.5px",
    margin: "6px 0px",
    zIndex: 10,
  },
  carousel: {
    width: "100%",
    height: "auto",
  },
  item: {
    height: "37px",
    textAlign: "center",
    color: theme.color.select.color,
    fontSize: theme.font.size14,
    fontWeight: "bold",
    padding: "0px",
    flex: 1,
  },
  vertical: {
    height: "auto",
  },
  list: {
    display: "flex",
    margin: "0px 20px 0px 20px",
  },
  listitem: {
    width: "308px",
    padding: "0px 8px 0px 0px",
  },
  card: {
    width: "300px",
    height: "100%",
    flex: "auto",
    background: "#fff",
    border: `1px solid ${theme.color.list_divider}`,
    borderRadius: "12px",
    textAlign: "start",
    display: "flex",
    flexDirection: "column",
  },
  cardTitle: {
    fontSize: theme.font.size15, // "15px",
    fontWeight: "bold",
    color: theme.color.text,
    width: "100%",
    textAlign: "start",
    marginBottom: "8px",
  },
  thumbnail: {
    height: "130px",
    padding: "15px 19px 12px",
  },
  thumbnailImage: {
    width: "auto",
    height: "103px",
  },
  info: {
    padding: "12px 20px",
    height: "100%",
  },
  infoTop: {
    marginBottom: "16px",
  },
  infoTitle: {
    fontSize: theme.font.size15, // "15px",
    fontWeight: "bold",
    color: theme.color.text,
    marginBottom: "4px",
  },
  infoDesc: {
    fontSize: theme.font.size15, // "15px",
    fontWeight: "300",
    color: theme.color.text,
  },
  external: {
    display: "flex",
    height: "45px",
    background: "#f7f0f0",
    borderRadius: "0 0 6px 6px",
    borderTop: "1px solid #d7d7d7",
    marginTop: "10px",
  },
  externalButton: {
    color: "#555",
    fontWeight: "normal",
    fontWeight: "bold",
    fontSize: theme.font.size14,
    flex: 1,
    border: "none",
    padding: 0,
    background: "transparent",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  button: {
    height: "50px",
    fontWeight: "bold",
    fontSize: theme.font.size14,
    lineHeight: "1.54",
    color: theme.palette.primary.main,
    background: "#fafafa",
    outline: "0 none",
    border: "none",
    borderRadius: "0px 0px 12px 12px",
    "&:active": {
      backgroundColor: "rgba(38, 38, 38, 0.1)",
    },
  },
  buttonDisabled: {
    color: "rgba(38, 38, 38, 0.4)",
  },
  engimg: {
    width: "75px",
    height: "100px",
    marginTop: "4px",
    border: `1px solid ${theme.color.list_divider}`,
  },
  divider: {
    height: "1px",
    backgroundColor: theme.color.list_divider,
  },
});

const cancelPopupData = {
  title: "예약 취소",
  description: "예약 취소 접수 진행을 위해 사유를 선택해주세요.",
  list_items: [
    {
      label: "서비스 신청 후 제품 정상 가동",
    },
    {
      label: "시간이 맞지 않거나 다음에 다시 신청",
    },
    {
      label: "신제품 구매 예정",
    },
    {
      label: "직접 해결 시도(수리/이전설치 등)",
    },
    {
      label: "기타",
    },
  ],
  buttons: [
    { label: "취소", type: "cancel" },
    { label: "확인", type: "confirm" },
  ],
};

const Card = React.forwardRef((props) => {
  const { classes, data, parentFunc } = props;

  const context = useContext(DataContext);
  return data.items.map((item, key) => (
    <>
      <div key={key} className={classes.listitem}>
        <div className={classes.card}>
          <div className={classes.info}>
            <div className={classes.cardTitle}>{item.title}</div>
            {item.list_items.map((list_item, index) => (
              <div className={index === 0 && classes.infoTop}>
                <div className={classes.infoTitle}>{list_item.sub_title}</div>
                {list_item.sub_items.map((subItem) => (
                  <div className={classes.infoDesc} role={"text"}>
                    - {subItem.key} : {subItem.value}
                  </div>
                ))}
                {index === 0 &&
                  item.meta &&
                  item.meta.picture_yn &&
                  item.meta.picture_yn !== "NA" && (
                    <img
                      className={classes.engimg}
                      src={
                        item.meta &&
                        item.meta.picture_yn &&
                        item.meta.picture_yn.toUpperCase() === "Y"
                          ? `https://www.lge.co.kr/support/downImageFile?value=/upload/engimg/${item.meta.se_id}.jpg`
                          : Engineer
                      }
                      alt={""}
                    />
                  )}
              </div>
            ))}
          </div>

          <Divider className={classes.divider} component={"div"} disabled />
          {item.buttons.map((button) => (
            <button
              type="button"
              className={[
                classes.button,
                (item.meta.cancel_yn !== "Y" || context.conversationEnd) &&
                  classes.buttonDisabled,
              ].join(" ")}
              disabled={item.meta.cancel_yn !== "Y" || context.conversationEnd}
              onClick={() => parentFunc.onButtonClick(item)}
            >
              {button.label}
            </button>
          ))}
        </div>
      </div>
    </>
  ));
});

function TICard(props) {
  const { classes, data } = props;
  const context = useContext(DataContext);
  const [openCancel, setOpenCancel] = useState(false);
  const [item, setItem] = useState(null);

  const handleButtonClick = (item) => {
    const cancelYn = item.meta.cancel_yn;
    if (cancelYn.toUpperCase() === "Y") {
      setItem(item);
      setOpenCancel(true);
    } else {
      // context.addTLMessage(false, context.string.chat.noncancelable, [
      //   { type: "external", url: "tel:15447777", label: "전화걸기" },
      // ]);
      context.addSGMessage(false, context.string.chat.noncancelable, [{
        speech: "고객서비스 상담사 채팅"
      }], false);
    }
  };

  const handlePopupClick = async (button, label, item) => {
    console.log("handlePopupClick", button, label, item);

    setOpenCancel(false);

    if (button.type === "confirm") {
      const description = `thinq | ${moment().format(
        "YYYY-MM-DD HH:mm:ss"
      )} | ${label}`;
      var type = "cancelRepairResrvRcpt"; // Default는 출장/방문 예약 취소
      var content = {
        svcType: item.meta.svc_type,
        rcptNo: item.meta.rcpt_no,
        cancelDesc: description,
        cancelUserId: "WWW13",
      };
      if (
        item.meta.svc_type.toUpperCase() !== "A" &&
        item.meta.svc_type.toUpperCase() !== "B"
      ) {
        type = "updateCounselInfo";
        content = {
          counselNo: item.meta.rcpt_no,
          cancelDesc: description,
          inputUserId: "WWW13",
          sessionDeptCode: "12401",
        };
      }

      console.log("handlePopupClick - context", context);

      // is_hirun이 yes인 경우, is_hirun 추가
      if (item.meta.is_hirun && item.meta.is_hirun.toLowerCase() === "yes") {
        content.is_hirun = item.meta.is_hirun;
        content.cancelUserId = "WWW7";
      }

      const result = await postSmileExt(context, type, content);
      console.log("[handlePopupClick]", result);

      var message = context.string.chat.cancelSuccess;
      if (
        !result.debug_parameters ||
        !result.debug_parameters.result_code ||
        result.debug_parameters.result_code !== "0000"
      ) {
        message = context.string.chat.cancelFail;
        context.addSGMessage(
          false,
          message,
          [{ speech: "고객서비스 상담사 채팅" }],
          false
        );
      } else if (
        type === "cancelRepairResrvRcpt" &&
        result.result.cancelYn.toUpperCase() === "N"
      ) {
        // context.addTLMessage(false, context.string.chat.noncancelable, [
        //   { type: "external", url: "tel:15447777", label: "전화걸기" },
        // ]);
        context.addSGMessage(false, context.string.chat.noncancelable, [{
          speech: "고객서비스 상담사 채팅"
        }], false);
      } else {
        const bodyData = {
          request_type:
            item.meta.svc_type === "A"
              ? "engineer"
              : item.meta.svc_type === "B"
              ? "center"
              : "counsel",
          svc_no: item.meta.rcpt_no,
          svc_type: item.meta.svc_type,
          product_code:
            type === "cancelRepairResrvRcpt"
              ? result.result.productCode
              : result.result[0].productCode,
          product_name:
            type === "cancelRepairResrvRcpt"
              ? result.result.productCodeName
              : result.result[0].productName,
          reason: label,
          device_type: context.userAgent.deviceType,
        };
        console.log("logBody", bodyData);
        context.log(context, "smile_cancel", bodyData);
        //context.addSGMessage(false, message, [{ speech: "예약 확인 및 취소"}], false);
        context.addTXMessage(false, message);
      }
    } else {
      context.addSGMessage(
        false,
        context.string.chat.cancelCancel,
        [{ speech: "예약 확인 및 취소" }],
        false
      );
    }
  };

  const parentFunc = {
    onButtonClick: handleButtonClick,
  };

  return (
    <>
      <div className={classes.root}>
        <Carousel
          className={classes.carousel}
          component={withStyles(styles)(Card)}
          data={data}
          parentFunc={parentFunc}
          ariaLabel={context.string.aria.reserve}
        />
      </div>
      <LP
        open={openCancel}
        data={cancelPopupData}
        item={item}
        handleButtonClick={handlePopupClick}
      />
    </>
  );
}
export default withStyles(styles)(TICard);
