import React, { useContext } from "react";
import Message from "./Message/Message";
import DataContext from "../data/DataContext";

function OldChat() {
  const data = useContext(DataContext);
  if (data.isShowOldConversations !== true) return null;
  return data.oldConversations.map((message, key) => (
    <Message key={key} data={message} />
  ));
}
export default OldChat;
