import React, { useState, useEffect, useContext } from "react";
import DataContext from "./DataContext";
import { send, getToken, thinqSTT, postLog } from "./DataLoader";
import string from "../../asset/language/string.json";
import Error from "../page/Error";
import base62 from "uuid-base62";
import { osName, isMobile } from "react-device-detect";
import Debug from "./Debug";
import { useToasts } from "react-toast-notifications";
import {
  makeTXMessage,
  makeSLMessage,
  makeTLMessage,
  makeSGMessage,
  convertMessage,
  convertDebugMessage,
  makeBCMesage,
} from "./DataConvert";
import InputContext from "./InputContext";
import moment from "moment";
const testMessages = {};

/**
 * ViewModel Class
 * - UI View에 맞게 데이터를 가공하거나 해당 기능을 제공
 */
const DataProvider = ({
  channel,
  channelParams,
  countryCode,
  children,
  stage,
}) => {
  const [debugOpen, setDebugOpen] = useState(false);
  const { addToast } = useToasts();

  function setIsLast(convArray, isLast) {
    if (!convArray || !Array.isArray(convArray) || convArray.length <= 0)
      return;
    convArray[convArray.length - 1]["isLast"] = isLast;
  }

  function addConversation(
    conversationArray,
    dialog = null,
    deleteLoading = false
  ) {
    setContext((prevState) => {
      if (deleteLoading) {
        prevState.conversations = prevState.conversations.filter(
          (conversation) => !conversation.loading
        );
      }
      setIsLast(prevState.conversations, false);
      let tempConv = [
        ...prevState.oldConversations,
        ...prevState.conversations,
        ...conversationArray,
      ];
      setIsLast(tempConv, true);

      let oldConv = [],
        newConv = [];
      tempConv.forEach((conv, index) => {
        // if (index < tempConv.length - 20) {
        //   oldConv.push(conv);
        // } else {
        //   newConv.push(conv);
        // }

        if (index < tempConv.length - 20) {
          conv.isOld = true;
        } else {
          conv.isOld = false;
        }
        newConv.push(conv);
      });

      let tempObj = {
        ...prevState,
        oldConversations: oldConv,
        conversations: newConv,
      };
      if (dialog) {
        tempObj["dialog"] = dialog;
      }
      prevState.scroll(true);
      return tempObj;
    });
  }

  const setShowOldConversations = (show) => {
    setContext((prevState) => {
      if (prevState.isShowOldConversations !== show) {
        return { ...prevState, isShowOldConversations: show };
      } else {
        return prevState;
      }
    });
  };

  function addAction(actionArray) {
    actionArray.forEach((action) => {
      switch (action.action.code.toUpperCase()) {
        case "AUTOCOMPLETE":
          input.autoComplete.setEnable(true, action.parameters);
          break;
        case "LOCATION":
          setLocation();
          break;
        case "TOKEN_EXPIRED":
          const errorRes = { status: { statusCode: 401 } };
          errorHandler(context, errorRes);
          break;
        case "CLIENT_REFRESH":
          refreshAccessToken();
          break;
        case "TERMINATE":
          if (action.parameters.items[0].dim === true) {
            endConversation();
          }
          setContext((prevState) => {
            return {
              ...prevState,
              isUserAction: false,
            };
          });
          sendUserActionToApp(false);
          break;
        case "STOP":
          if (action.parameters.items[0].dim === true) {
            endConversation();
          }
          break;
        default:
          break;
      }
      console.log("Action", action);
    });
  }

  function refreshAccessToken() {
    if (context.userAgent.deviceType !== "mobile") {
      console.log("refreshing access_token is available in mobile only");
      return;
    }
    return new Promise((resolve) => {
      var timer;
      window.NativeCallback.setAccessToken = (data) => {
        setContext((prevState) => {
          console.log(">> refresh access_token");
          console.log(
            "[old.access_token]",
            prevState.channelParams.access_token
          );
          console.log("[new.access_token]", data.access_token);
          prevState.channelParams.access_token = data.access_token;
          return { ...prevState };
        });
        clearTimeout(timer);
        restartConversation();
        window.NativeCallback.setAccessToken = () => {};
        resolve(data.access_token);
      };
      if (window.NativeInterface) {
        window.NativeInterface.getNewAccessToken(
          "window.NativeCallback.setAccessToken"
        );
        timer = setTimeout(() => {
          console.log("[refreshAccessToken] timeout");
          window.NativeCallback.setAccessToken = () => {};
          const errorRes = { status: { statusCode: 400 } };
          errorHandler(context, errorRes);
          context.scroll(true);
          addToast("Token Refresh Timeout!", { appearance: "error" });
        }, 10 * 1000);
      } else {
        console.log("NativeInterface is not supported");
        addToast("NativeInterface is not supported!", { appearance: "error" });
      }

      // 대화 다시 시작 먼저 표시
      const userMessage = makeTXMessage(
        true,
        context.string.chat.restartConversation,
        false
      );
      if (window.navigator.onLine === false) {
        userMessage.online = false;
        userMessage.loading = false;
        userMessage.action = (contextTx, callbackTx) => {
          if (window.navigator.onLine === true) {
            startConversation(contextTx);
            callbackTx(true);
          } else {
            setTimeout(() => {
              callbackTx(false);
            }, 2000);
          }
        };
      }
      addConversation([userMessage]);
      context.scroll(true);
    });
  }

  const setLivechatBranch = (branch) => {
    setContext((prevState) => {
      return { ...prevState, livechatBranch: branch };
    });
  };

  function errorHandler(context, errorRes) {
    const statusCode = errorRes.status.statusCode;
    let list_items = [
      {
        label: context.string.chat.restartConversation,
        speech: context.string.chat.restartConversation,
      },
    ];
    let slMessage = null;
    if (statusCode === 401) {
      // 401때만 access_token refresh
      list_items[0]["frontActionFunc"] = refreshAccessToken;
      slMessage = makeSLMessage(
        false,
        context.string.chat.sessionTimeout,
        list_items
      );
    } else {
      list_items[0]["frontActionFunc"] = refreshAccessToken; // restartConversation;
      slMessage = makeSLMessage(
        false,
        context.string.chat.responseError,
        list_items
      );
    }
    addConversation([slMessage], null, true);
    endConversation();
  }

  /**
   * ThinQ App 이 연결된 경우 인터페이스가 있는지 확인
   */
  const isNativeAppEnabled = () => {
    if (window.NativeInterface) {
      return (
        window.NativeInterface.getLocationStatus &&
        window.NativeInterface.getLocationValue &&
        window.NativeInterface.getNewAccessToken
      );
    }
    return false;
  };

  /**
   * ThinQ App 에 Location 권한 상태를 확인
   * - 모바일이 아닌 경우는 Geolocation 을 확인
   */
  const getLocationStatus = () => {
    if (context.userAgent.deviceType !== "mobile") {
      console.log("getLocationStatus is available in mobile only");
      return new Promise((resolve) => {
        resolve({ Permission: true, Status: true });
      });
    }
    return new Promise((resolve) => {
      var timer;
      window.NativeCallback.getLocationStatus = (data) => {
        console.log("[getLocationStatus]", data);
        if (typeof data === "string") {
          data = JSON.parse(data);
        }
        clearTimeout(timer);
        window.NativeCallback.getLocationStatus = () => {};
        resolve(data);
      };

      if (window.NativeInterface) {
        window.NativeInterface.getLocationStatus(
          "window.NativeCallback.getLocationStatus"
        );
        timer = setTimeout(() => {
          console.log("[getLocationStatus] timeout");
          window.NativeCallback.getLocationStatus = () => {};
          const errorRes = { status: { statusCode: 400 } };
          errorHandler(context, errorRes);
          context.scroll(true);
        }, 10 * 1000);
      }
    });
  };

  const getLocationValue = () => {
    if (context.userAgent.deviceType !== "mobile") {
      console.log("getLocationValue (GPS) is available in mobile only");
      return new Promise((resolve) => {
        resolve({ lat: 37.5012103, lng: 127.0246143 });
      });
    }
    return new Promise((resolve) => {
      var timer;
      window.NativeCallback.getLocationValue = (data) => {
        console.log("[getLocationValue]", data);
        if (typeof data === "string") {
          data = JSON.parse(data);
        }
        clearTimeout(timer);
        window.NativeCallback.getLocationValue = () => {};
        resolve(data);
      };

      if (window.NativeInterface) {
        window.NativeInterface.getLocationValue(
          "window.NativeCallback.getLocationValue"
        );
        timer = setTimeout(() => {
          console.log("[getLocationValue] timeout");
          window.NativeCallback.getLocationValue = () => {};
          const errorRes = { status: { statusCode: 400 } };
          errorHandler(context, errorRes);
          context.scroll(true);
        }, 10 * 1000);
      }
    });
  };

  const setLocation = () => {
    setContext((prevState) => {
      // INITIALIZE Location
      prevState.location = {
        permission: false,
        status: false,
        latitude: "",
        longitude: "",
      };

      // CHECK Native APP GPS Enabled.
      if (isNativeAppEnabled()) {
        getLocationStatus().then((status) => {
          // 1. CONFIRM Permission
          prevState.location.permission = status.Permission;
          prevState.location.status = status.Status;

          // iOS TEST
          // addTXMessage(true, `1) type status: ${typeof status.Permission}, status: ${typeof status.Status}`);

          if (status.Permission === true && status.Status === true) {
            // 1-1. GET GPS Permission Successfully.
            getLocationValue().then((value) => {
              // GET GPS Value.
              prevState.location.latitude = value.lat || "";
              prevState.location.longitude = value.lng || "";

              // iOS TEST
              // addTXMessage(true, `2) lat: ${value.lat}, lng: ${value.lng}`);

              const parameters = {};
              if (prevState.location.latitude !== "")
                parameters.latitude = prevState.location.latitude;
              if (prevState.location.longitude !== "")
                parameters.longitude = prevState.location.longitude;
              postData(prevState, "", "text", parameters);
            });
          } else {
            // 1-2. FAILED to get GPS Permission.
            postData(prevState, "", "text");
          }
        });
      } else {
        // 2. CONFIRM function.
        if (window.navigator.geolocation) {
          window.navigator.geolocation.getCurrentPosition(
            (success) => {
              // 2-1. GET GPS Value successfully.
              console.log("[getCurrentPosition] success", success);
              prevState.location.permission = true;
              prevState.location.status = true;
              prevState.location.latitude = success.coords.latitude || "";
              prevState.location.longitude = success.coords.longitude || "";

              // iOS TEST
              // addTXMessage(true, `3) lat: ${success.coords.latitude}, lng: ${success.coords.longitude}`);

              const parameters = {};
              if (prevState.location.latitude !== "")
                parameters.latitude = prevState.location.latitude;
              if (prevState.location.longitude !== "")
                parameters.longitude = prevState.location.longitude;
              postData(prevState, "", "text", parameters);
            },
            (error) => {
              // 2-2. FAILED to get GPS value.
              console.log("[getCurrentPosition] error", error);
              postData(prevState, "", "text");

              // iOS TEST
              //  addTXMessage(true, `4) error ${error}`);
            },
            { enableHighAccuracy: false, maximumAge: 3000, timeout: 60000 * 10 }
          );
        } else {
          // 3. NOT Supported.
          console.log(
            "[getCurrentPosition] NOT Supported (window.natvigator.geolocation)"
          );
          postData(prevState, "", "text");

          // iOS TEST
          // addTXMessage(true, `5) Not Supported`);
        }
      }
      return { ...prevState };
    });
  };

  const setLocationDummy = () => {
    console.log("[setLocationDummy]", "강남구 역삼동");
    if (!window.NativeInterface) {
      window.NativeInterface = {};
    }
    window.NativeInterface.getLocationStatus = function() {
      window.NativeCallback.getLocationStatus({
        Permission: true,
        Status: true,
      });
    };
    window.NativeInterface.getLocationValue = function() {
      window.NativeCallback.getLocationValue({
        lat: 37.5012103,
        lng: 127.0246143,
      }); // 강남구 역삼동
    };
  };

  async function postData(context, content, contentType, parameters = null, expect = null) {
    context.scroll(false);

    // 사용자가 입력한 메시지
    const userMessage = makeTXMessage(true, content);

    if (window.navigator.onLine === false) {
      userMessage.online = false;
      userMessage.loading = false;
      addConversation([userMessage]);
      return;
    }

    addConversation([userMessage]);

    // 자동 완성 기능 종료
    // setAutoComplete(false);
    input.autoComplete.setEnable(false);

    // 일단 딤처리를 푼다..
    releaseDim();

    // 서버에 요청
    send(
      "post",
      context,
      content,
      contentType,
      null,
      "dialog",
      parameters,
      expect
    ).then((res) => {
      // 서버 응답 처리
      console.log("res", res);

      let responseArray = [];
      userMessage.loading = false;

      const statusCode = res.status.statusCode;
      if (statusCode !== 200) {
        errorHandler(context, res);
      } else {
        // Debug Message
        let debug = res.response.layouts.thinq.debug;
        if (
          context.debug &&
          Object.keys(debug).length !== 0 &&
          debug.constructor === Object
        ) {
          let debugArray = convertDebugMessage(debug);
          addConversation(debugArray);
        }

        try {
          responseArray = convertMessage(res.response.layouts.thinq.items);
        } catch (error) {
          console.log(error);
        }
        // 일반 대화 처리
        const conversationArray = responseArray.filter(
          (response) => response.type.toUpperCase() !== "ACTION"
        );
        addConversation(conversationArray, res.response.dialog, true);

        // ACTION 분기 처리
        const actionArray = responseArray.filter(
          (response) => response.type.toUpperCase() === "ACTION"
        );
        addAction(actionArray);
      }
      context.scroll(true);
    });

    // 데이터 로딩 중 메시지
    userMessage.loading = false;
    const waitMessage = makeTXMessage(false, content, true);

    addConversation([waitMessage]);
    setContext((prevState) => {
      if (prevState.isUserAction !== true) {
        sendUserActionToApp(true);
      }
      return {
        ...prevState,
        isUserAction: true,
      };
    });
  }

  const sendUserActionToApp = (action) => {
    if (window.NativeInterface && window.NativeInterface.setIsUserAction) {
      window.NativeInterface.setIsUserAction(action);
    }
  };

  function addTXMessage(isMine, content, isDebug = false) {
    context.scroll(false);

    if (isDebug) {
      let debugArray = convertDebugMessage(content);
      addConversation(debugArray);
    } else {
      // 사용자가 입력한 메시지
      const userMessage = makeTXMessage(isMine, content, false);
      addConversation([userMessage]);
    }
    
    context.scroll(true);
  }

  function addTLMessage(isMine, content, link) {
    context.scroll(false);

    const userMessage = makeTLMessage(isMine, content, link);
    addConversation([userMessage]);

    context.scroll(true);
  }

  function addSGMessage(isMine, content, buttons, isGrid) {
    context.scroll(false);

    const userMessage = makeSGMessage(isMine, content, buttons, isGrid);
    addConversation([userMessage]);

    context.scroll(true);
  }

  function addBCMessage(isMine, content, title, image, buttons) {
    context.scroll(false);

    const userMessage = makeBCMesage(isMine, content, title, image, buttons);
    addConversation([userMessage]);

    context.scroll(true);
  }

  function addSLMessage(isMine, content, list_items) {
    context.scroll(false);

    const userMessage = makeSLMessage(isMine, content, list_items);
    addConversation([userMessage]);

    context.scroll(true);
  }

  // Token Issue API
  async function requestToken(data) {
    const res = await getToken(data);
    const thinqAIToken = res.access_token;

    console.log("requestToken - token:", thinqAIToken);

    return thinqAIToken;
  }

  // 테스트 데이터 호출 용
  const testData = (type) => {
    context.scroll(false);
    const testMessage = testMessages[type.toUpperCase()];

    if (type.toUpperCase() === "AUTOCOMPLETE") {
      addAction(testMessage);
    } else {
      addConversation(testMessage);
    }

    context.scroll(true);
    if (type.toUpperCase() === "TERMINATE") {
      endConversation();
    }
  };

  // 디버그 데이터 호출
  const debugData = async (content) => {
    // ST, QA 형상만 허용됨
    if (!window.thinq.chatbot.debug) {
      return;
    }

    if (content.startsWith("/debug_on")) {
      addToast("DEBUG ON!", { appearance: "warning" });
      setContext((prevState) => {
        prevState.debug = true;
        return { ...prevState };
      });
    } else if (content.startsWith("/debug_off")) {
      addToast("DEBUG OFF!", { appearance: "warning" });
      setContext((prevState) => {
        prevState.debug = false;
        return { ...prevState };
      });
    } else if (content === "/h") {
      setDebugOpen(true);
    } else if (content.startsWith("/e_")) {
      // 강제로 서버 에러 발생 시 Status Code 입력 ex) /e_401 이면 401 에러 발생으로 간주
      const code = content.split("_")[1];
      const errorRes = { status: { statusCode: Number(code) } };
      errorHandler(context, errorRes);
    } else if (content === "/h_gps") {
      setLocationDummy();
    } else if (content === "/h_gps_status") {
      const status = await getLocationStatus();
      if (status) {
        addToast(
          `[GPS Status] permission: ${status.Permission} status: ${status.Status}`,
          { appearance: "info" }
        );
      }
    } else if (content === "/h_gps_value") {
      const value = await getLocationValue();
      if (value) {
        addToast(`[GPS Value] lat: ${value.lat} lng: ${value.lng}`, {
          appearance: "success",
        });
      }
    } else if (content === "/h_device_test") {
      setContext((prevState) => {
        const id = prevState.dialog.context.devices[0].id;
        console.log("device test", id);
        window.NativeInterface.goProductPage(id);
        return prevState;
      }) 
    } else if (content === '/h_livechat') {
      const buttons = [{
        title: "고객서비스 상담사와 채팅 시작",
        type: "livechat"
      }];
      addBCMessage(false, "전문 상담사와 실시간으로 대화해 보세요! 채팅 요청 고객이 많을 경우 연결이 원할하지 않을 수 있습니다.", null, "https://chatbot.lgthinq.com/image/ko_thinq_livechat.png", buttons);
    } else if (content === "/h_reward") {
      window.NativeInterface.open("thinqapp://commerce?path=Reward&from=Chatbot");
    } else if (content === "/h_reward_mission") {
      window.NativeInterface.open(`thinqapp://commerce?path=Reward&from=Chatbot&url=${encodeURIComponent("https://st-kr-front.qreward.lge.com/mission")}`);
    } else if (content ==="/h_diagnosis") {
      window.NativeInterface.open("thinqapp://smartdiagnosis");
    } else if (content === "/h_csreserve") {
      window.NativeInterface.open("thinqapp://csreserve?call=Push");
    } else if (content.startsWith("/h_csreserve_")) {
      const type = content.split("_")[2];
      window.NativeInterface.open(`thinqapp://csreserve?call=Push&reserveType=reserveType${type}`);
    } else if (content.startsWith("/h_thinqapp_")) {
      const type = content.split("_")[2];
      window.NativeInterface.open(`thinqapp://${type}`);
    } else {
      const data = {
        intent: "THQ_HIDDEN_COMMAND",
        parameters: JSON.parse("{}"),
      };
      // const debugResult = await debug("hidden", context, data, content);
      const debugResult = await window.thinq.chatbot.debug.send(
        "hidden",
        context,
        data,
        content,
        moment().unix()
      );

      console.log("Hidden Command", debugResult);
      addToast(`Hidden Command: ${content}`, { appearance: "warning" });

      let resultItems = null;
      let resultDialog = "";

      resultDialog = debugResult.response.dialog;
      resultItems = debugResult.response.layouts.thinq.items;

      const responseArray = convertMessage(resultItems);

      console.log("[debug] - resultItems", resultItems);
      console.log("[debug] - responseArray", responseArray);

      // 일반 대화 처리
      const conversationArray = responseArray.filter(
        (response) => !response.type || response.type.toUpperCase() !== "ACTION"
      );
      addConversation(conversationArray, resultDialog);

      // ACTION 분기 처리
      const actionArray = responseArray.filter(
        (response) => response.type && response.type.toUpperCase() === "ACTION"
      );
      addAction(actionArray);
      context.scroll(false);
    }
  };

  // const livechatData = (text) => {
  //   livechatApi.send(text);
  //   const userMessage = makeTXMessage(true, text);
  //   addConversation([userMessage]);
  //   context.scroll(false);
  // }

  const handleDebugClose = () => {
    setDebugOpen(false);
  };

  const handleDebugOK = async (data) => {
    setDebugOpen(false);
    console.log("handleDebugOK", data);
    // let debugResult = await debug("conversation", context, data);
    if (!window.thinq.chatbot.debug) {
      return;
    }
    let debugResult = await window.thinq.chatbot.debug.send(
      "conversation",
      context,
      data,
      moment().unix()
    );

    // Debug Message
    let debugRes = debugResult.response.layouts.thinq.debug;
    console.log("debug", debugRes);
    if (
      context.debug &&
      Object.keys(debugRes).length !== 0 &&
      debugRes.constructor === Object
    ) {
      let debugArray = convertDebugMessage(debugRes);
      addConversation(debugArray);
    }

    console.log(debugResult);
    let resultItems = null;
    let resultDialog = "";

    resultDialog = debugResult.response.dialog;
    resultItems = debugResult.response.layouts.thinq.items;

    addConversation(convertMessage(resultItems), resultDialog);
    context.scroll(false);
  };

  const request = async (context, content, contentType, parameters = null, expect = null) => {
    if (Object.keys(testMessages).includes(content.toUpperCase())) {
      testData(content);
    } else if (
      content === "/h" ||
      content.startsWith("/debug") ||
      new RegExp("^/h_[a-z0-9_]+").exec(content) ||
      new RegExp("^/e_[a-z0-9_]+").exec(content)
    ) {
      debugData(content);
    } else {
      postData(context, content, contentType, parameters, expect);
    }
  };

  const log = async (context, type, data) => {
    const res = await postLog(context, type, data);
    console.log("[LOG] res", res);
  };

  const startSTT = async () => {
    console.log("startSTT", context);
    return thinqSTT(context.thinqAIToken);
  };

  const initThinqAIToken = async () => {
    if (context.thinqAIToken !== null) return;
    let token = await requestToken();
    setContext((prevState) => {
      prevState.thinqAIToken = token;
      return { ...prevState };
    });
  };

  const initSessionId = () => {
    let sessionId = base62.v4();

    console.log("initSessionId", sessionId);

    setContext((prevState) => {
      prevState.sessionId = sessionId;
      return { ...prevState };
    });
  };

  const initOS = () => {
    console.log("string", context.string);
    document.title = string[countryCode].title;
    setContext((prevState) => {
      prevState.userAgent.os = osName;
      prevState.userAgent.deviceType = isMobile ? "mobile" : "pc";
      return { ...prevState };
    });
  };

  const startConversation = async (context) => {
    releaseDim();

    console.log("[startConversation] context =", context);
    let res = null;
    try {
      res = await send(
        "post",
        context,
        "__CHATBOT_START_CONVERSATION__",
        "button",
        null,
        "start"
      );
    } catch (error) {
      console.log("[error]", error);
    }
    let responseArray = [];
    console.log("[startConversation]", res);
    const statusCode = res.status.statusCode;
    if (statusCode !== 200) {
      errorHandler(context, res);
    } else {
      // Debug Message
      let debug = res.response.layouts.thinq.debug;
      if (
        context.debug &&
        Object.keys(debug).length !== 0 &&
        debug.constructor === Object
      ) {
        let debugArray = convertDebugMessage(debug);
        addConversation(debugArray);
      }

      try {
        responseArray = convertMessage(res.response.layouts.thinq.items, true);
      } catch (error) {
        console.log(error);
      }
      // 일반 대화 처리
      const conversationArray = responseArray.filter(
        (response) => response.type.toUpperCase() !== "ACTION"
      );
      addConversation(conversationArray, res.response.dialog, true);

      // ACTION 분기 처리
      const actionArray = responseArray.filter(
        (response) => response.type.toUpperCase() === "ACTION"
      );
      addAction(actionArray);
    }
    context.scroll(true);

    setContext(prevState => {
      prevState.conversationStart = true;
      return { ...prevState };
    })
  };

  const endConversation = () => {
    setContext((prevState) => {
      return {
        ...prevState,
        conversationEnd: true,
      };
    });
  };

  const restartConversation = () => {
    console.log("[Restart conversation]", context);
    initSessionId();
    setContext((prevState) => {
      startConversation(prevState);
      return { ...prevState, conversationStart: true };
    });
  };

  const releaseDim = () => {
    setContext((prevState) => {
      return {
        ...prevState,
        conversationEnd: false,
      };
    });
  };

  const deleteMessage = (msgId) => {
    setContext((prevState) => {
      return {
        ...prevState,
        conversations: prevState.conversations.filter(
          (conversation) => msgId !== conversation.id
        ),
      };
    });
  };

  const addLiveChatMessage = (type, text, list = null, callback = null) => {
    console.log("addLiveChatMessage", type, text);
    switch(type.toLowerCase()) {
      case 'start':
        addTXMessage(false, text);
        break;
      case 'me':
        addTXMessage(true, text);
        break;
      case 'you':
        addTXMessage(false, text);
        break;
      case 'no category':
        addSLMessage(false, text, list, callback);
        break;
      case 'message':
        // addConversation(convertMessage([text]));
        addTXMessage(false, text);
        context.scroll(true);
        break;
      default: 
        addConversation([text]);
        break;
    }
  }

  const setTyping = isTyping => {
    console.log("isTyping", isTyping)

    const resetTimer = () => {
      if (!window.livechat) window.livechat = {};
      if (window.livechat.typingTimer) clearTimeout(window.livechat.typingTimer);
      window.livechat.typingTimer = setTimeout(() => {
        if (isTyping) {
          setTyping(false);
          console.log("typing timer end", false)
        }
      }, 5000);
    };

    setContext(prev => {
      if (isTyping) {
        const waitMessage = makeTXMessage(false, "Typing", true);
        prev.conversations.push(waitMessage);
        resetTimer();
      } else {
        prev.conversations = prev.conversations.filter(
          conversation => !conversation.loading
        );
      }
      prev.scroll(true);
      return { ...prev };
    });
  }

  // 초기화 객체
  const initialState = {
    oldConversations: [],
    isShowOldConversations: false,
    setShowOldConversations: setShowOldConversations,
    conversations: [],
    request: request,
    scroll: () => {},
    countryCode: countryCode,
    string: string[countryCode],
    requestToken: requestToken,
    thinqAIToken: null,
    startSTT: startSTT,
    channel: channel,
    channelParams: channelParams,
    sessionId: "",
    deleteMessage: deleteMessage,
    stage: stage,
    conversationStart: false,
    conversationEnd: false,
    userAgent: {
      os: "",
      deviceType: "",
    },
    debug: false,
    dialog: {},
    addTXMessage: addTXMessage,
    addTLMessage: addTLMessage,
    addSGMessage: addSGMessage,
    addSLMessage: addSLMessage,
    location: {
      permission: false,
      status: false,
      latitude: "",
      longitude: "",
    },
    endConversation: endConversation,
    log: log,
    isUserAction: false,
    addLiveChatMessage: addLiveChatMessage,
    setTyping: setTyping,
    livechatBranch: "",
    setLivechatBranch: setLivechatBranch,
  };

  const [context, setContext] = useState(initialState);
  const input = useContext(InputContext);

  // Fetch thinqAIToken
  useEffect(() => {
    console.log("end loading", Date.now());
    window.endLoading = Date.now();
    initThinqAIToken();
    initSessionId();
    initOS();
    setContext((prevState) => {
      startConversation(prevState).then(() => {
        if (prevState.channelParams.speech) {
          postData(prevState, prevState.channelParams.speech, "text");
        }
        console.log("complete loading", Date.now());
        window.completeLoading = Date.now();
      });
      return prevState;
      // return {
      //   ...prevState,
      //   conversationStart: true,
      // };
    });

    // startConversation(context).then(() => {
    //   if (context.channelParams.speech) {
    //     postData(context, context.channelParams.speech, "text");
    //   }
    //   setContext(prevState => {
    //     console.log("CONVERSATION START!!!!!!!!!!!")
    //     return {
    //       ...prevState,
    //       conversationStart: true 
    //     }
    //   })
    // });

  }, []);

  if (!string.hasOwnProperty(countryCode)) {
    return <Error />;
  }

  return (
    <DataContext.Provider value={context}>
      {children}
      <Debug
        open={debugOpen}
        handleOk={handleDebugOK}
        handleClose={handleDebugClose}
      />
    </DataContext.Provider>
  );
};
export default DataProvider;
