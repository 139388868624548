import React, { useContext, useState, Fragment, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import DataContext from "../../data/DataContext";
import MsgRetry from '../../../asset/img/btn_refresh_nor.png';
import MsgRetryPressed from '../../../asset/img/btn_refresh_pre.png';
import MsgDelete from '../../../asset/img/btn_remove_nor.png';
import MsgDeletePressed from '../../../asset/img/btn_remove_pre.png';
import LeftTail from "../../../asset/img/bubble_point1.png";
import RightTail from "../../../asset/img/bubble_point2.png";
import TypingData from "../../../asset/img/img_text_loading.json";
import Lottie from "react-lottie";

const styles = theme => ({
  root: {
    display: "flex",
    letterSpacing: "-0.5px",
    whiteSpace: "pre-wrap",
    zIndex: 10,
    outline: 0
  },
  leftContainer: {
    justifyContent: "flex-start"
  },
  rightContainer: {
    justifyContent: "flex-end"
  },
  message: {
    fontSize: theme.font.size15, // "15px",
    borderRadius: "12px",
    flexWrap: "wrap",
    color: theme.color.message.color,
    padding: "9px 20px",
    maxWidth: "75%",
    zIndex: 10,
    wordWrap: 'break-word',
    wordBreak: "break-word"
  },
  leftMessage: {
    backgroundColor: "white",
    margin: "6px 6px 6px 9px",
    boxShadow: "0px 12px 12px rgba(194, 194, 194, 0.58)",
    fontWeight: "300",
    "text-size-adjust": "auto",
    fontSize: theme.font.size15, // "15px",
  },
  rightMessage: {
    backgroundColor: theme.color.message.background,
    margin: "8px 10px",
    boxShadow: "none",
    fontWeight: "bold",
    color: "#fff"
  },
  date: {
    color: theme.color.message.date,
    fontSize: theme.font.size11,
    fontWeight: "300",
    alignSelf: "flex-end",
    margin: "5px 0 6px 0",
    textAlign: "center"
  },
  rightDate: {
    margin: "5px 0 22px 0"
  },
  typing: {
    width: '30px',
    height: '6px'
  },
  leftTail: {
    // borderLeft: '14px solid transparent',
    // borderRight: '14px solid transparent',
    // borderTop: '18px solid #fff',
    // borderRadius: '5px',
    zIndex: 11,
    margin: '6px -21px 0px 11px',
    backgroundImage: `url(${LeftTail})`,
    backgroundSize: "21px 12px",
    width: "21px",
    height: "12px",
    minWidth: "21px"
  },
  rightTail: {
    // borderLeft: '14px solid transparent',
    // borderRight: '14px solid transparent',
    // borderTop: '18px solid ' + theme.color.message.background,
    // borderRadius: '5px',
    zIndex: 11,
    margin: '26px 11px 0px -21px',
    backgroundImage: `url(${RightTail})`,
    backgroundSize: "21px 12px",
    width: "21px",
    height: "12px",
    minWidth: "21px"
  },
  leftBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-end'
    
  },
  messageLoading: {
    width: "3.125em",
    height: "1.375rem",
    cursor: "pointer",
    backgroundSize: "50px 20px"
  },
  messageStatusImg: {
    width: "32px",
    height: "32px",
    cursor: "pointer",
    //margin: "10px 0 0 0",
    border: "none",
    outline: "0 none",
    backgroundColor: "transparent",
    backgroundSize: "32px",
    backgroundImage: `url(${MsgRetry})`,
    "&:active": {
      backgroundImage: `url(${MsgRetryPressed})`,
    }
  },
  rightBox: {
    position: "relative"
  },
  btnDelete: {
    position: "absolute",
    left: "-25px",
    top: "12px",
    width: "32px",
    height: "32px",
    zIndex: "20",
    cursor: "pointer",
    border: "none",
    outline: "0 none",
    backgroundColor: "transparent",
    backgroundSize: "32px",
    backgroundImage: `url(${MsgDelete})`,
    "&:active": {
      backgroundImage: `url(${MsgDeletePressed})`,
    }
  }
});

const lottieTypingOptions = {
  loop: true,
  autoplay: true,
  animationData: TypingData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

function TXLeftRaw(props) {
  const { classes, data } = props;
  const loading = data.loading === true || data.loading === 'true';
  const context = useContext(DataContext);
  return data.text && <div
    className={[
      classes.root,
      classes.leftContainer
    ].join(" ")}
    role={"text"}
    aria-label={`${context.string.aria.chatbotMessage} ${data.text.replace(/(\r\n|\n|\r)/gm, " ")}, ${data.date}`}
    tabIndex={0}
  >
    <div className={classes.leftTail}/>
    <div
      className={[
        classes.message,
        classes.leftMessage
      ].join(" ")}
    >
      { loading ? 
        <Lottie
          options={lottieTypingOptions}
          className={classes.messageLoading}
          width={"3.125em"}
          height={"1.375em"}
        />
      :(
        data.text.split("\n").map((content, index) => (
          <Fragment key={index}>
            <span>{content}</span>
            <br />
          </Fragment>
        ))
      )}
    </div>
    {!loading && <p className={classes.date}>{data.date}</p>}
  </div>
}

function TXRightRaw(props) {
  const { classes, data } = props;
  const context = useContext(DataContext);
  const [loading, setLoading] = useState(data.loading === true || data.loading === 'true');
  const [online, setOnline] = useState(data.online);
  const [focused, setFocused] = useState(true);
  const ref = useRef(null);
  const handleRetry = async () => {
    setLoading(true);
    setOnline(true);
    if (data.action) {
      await data.action(context, (isOnline) => {
        setLoading(false);
        setOnline(isOnline);
      });
      return;
    }
    setTimeout(() => {
      setLoading(false);
      context.deleteMessage(data.id)
      context.request(context, data.text, "text");  
    }, 2000);
  }
  const handleDelete = () => {
    context.deleteMessage(data.id);
  }

  if (ref && ref.current && focused) {
    ref.current.focus();
    console.log("[Focus Order] TX", ref.current);
    setFocused(false);
  }
  
  return data.text && <div
    className={[
      classes.root,
      classes.rightContainer
    ].join(" ")}
    role={"text"}
    tabIndex={0}
    aria-label={`${context.string.aria.myMessage} ${data.text}, ${data.date}`}
    ref={ref}
  >
    <div className={classes.leftBox}>
      {loading && <Lottie
        options={lottieTypingOptions}
        className={classes.messageLoading}
        width={32}
        height={14}
      />}
      {!online && <button
        type="button"
        className={classes.messageStatusImg}
        onClick={handleRetry} />}
      <p className={[classes.date, classes.rightDate].join(' ')}>{data.date}</p>
    </div>
    <span
      className={[
        classes.message,
        classes.rightMessage
      ].join(" ")}
    >
      {data.text.split("\n").map((content, index) => (
        <Fragment key={index}>
          <span>{content}</span>
          <br />
        </Fragment>
      ))}
    </span>
    {!online&&(
      <div className={classes.rightBox}>
        <button type="button" className={classes.btnDelete} onClick={handleDelete}/>
      </div>
    )}
    <div className={classes.rightTail} />
  </div>
}
const TXRight = withStyles(styles)(TXRightRaw);
const TXLeft = withStyles(styles)(TXLeftRaw);

function TX(props) {
  const { data } = props;
  return (data.mine === true || data.mine === 'true')?<TXRight data={data}/>:<TXLeft data={data}/>
}
export default withStyles(styles)(TX);
