import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core";
import { Base64 } from "js-base64";
import { useErrorBoundary } from "use-error-boundary";

import Header from "../chat/Header";
import Footer from "../chat/Footer";
import AutoComplete from "../chat/AutoComplete";
import Chat from "../chat/Chat";
import DataProvider from "../data/DataProvider";
import { extServiceCheck } from "../data/DataLoader";
import queryString from "query-string";
import Maintenance from "./Maintenance";
import Sorry from "./Sorry";
import Loading from "./Loading";
import loadJs from "load-js";
import InputProvider from "../data/InputProvider";
import { LiveChatProvider } from '../data/LiveChat';
import Layout from "./Layout";

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.color.background,
    overflow: "none",
    "-webkit-overflow-scrolling": "touch",

    // Default Font Settings
    font: "-apple-system-body",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.54,
    letterSpacing: "normal"
  },
  
});

const stagePrefixMap = {
  "chatbot-st": "st",
  "chatbot-qa": "qa",
  chatbot: "op",
  "chatbot-st-us": "st",
  "chatbot-qa-us": "qa",
  "chatbot-us": "op",
};

function getCurrentStage() {
  let currentUrlHost = window.location.host.split(".");
  let stagePrefix = currentUrlHost[0];
  let currentStage = "st";
  if (stagePrefix in stagePrefixMap) {
    currentStage = stagePrefixMap[stagePrefix];
  }
  return currentStage;
}

const countryPrefixMap = {
  "chatbot-st-us": "us",
  "chatbot-qa-us": "us",
  "chatbot-us": "us",
};

function getCurrentCountry() {
  let currentUrlHost = window.location.host.split(".");
  let prefix = currentUrlHost[0];
  let country = "kr";
  if (prefix in countryPrefixMap) {
    country = countryPrefixMap[prefix];
  }
  return country;
}

function getChannelParams(props) {
  var channel = "";
  var channelParams = {};
  try {
    const parameters = queryString.parse(props.location.search);
    console.log("parameters", parameters);

    if (!("channel" in parameters) || !"channel_parameters") {
      throw new Error("channel or channel_parameters is not exist");
    }

    channel = parameters["channel"];
    let tempChannelParams = Base64.decode(parameters["channel_parameters"]);
    channelParams = JSON.parse(tempChannelParams);
    console.log("[Main.js]", "channelParams", channelParams);

    // Get Parameters로 얻어온 데이터를 Context에 저장하고 URL 창에 보여주지 않는다.
    window.history.replaceState({}, null, props.location.pathname);
  } catch (e) {
    console.log("Main.js:", e.message);
  }

  return { channel, channelParams };
}

function Main(props) {
  const { classes } = props;

  const location = window.location + props.location.search;
  const currentStage = getCurrentStage();
  const countryCode = getCurrentCountry(); //props.match.params.countryCode ?? 'kr';

  // OP 인 경우 로그 삭제!
  // 2020-07-02 QE2차를 위해 임시 해제
  // if (currentStage.toLowerCase() === "op") {
  //   window["console"]["log"] = function() {};
  // }

  const [maintenanceInfo, setMaintenanceInfo] = useState(null);

  function isIOS() {
    var varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기

    console.log("UserAgent", varUA);
    if ( varUA.indexOf('android') > -1) {
        //안드로이드
        return false;
    } else if ( varUA.indexOf("iphone") > -1||varUA.indexOf("ipad") > -1||varUA.indexOf("ipod") > -1 ) {
        //IOS
        return true;
    } else {
        //아이폰, 안드로이드 외
        return false;
    }    
}


  async function doServiceCheck() {
    try {
      let serviceChk = await extServiceCheck(countryCode, currentStage);
      console.log(serviceChk);
      setMaintenanceInfo(serviceChk.result);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    doServiceCheck();
  }, []);

  const { ErrorBoundary } = useErrorBoundary();
  if (maintenanceInfo === null) {
    console.log("start loading", Date.now());
    window.startLoading = Date.now();
    return (
      <ErrorBoundary>
        <Loading safari={isIOS()} />
      </ErrorBoundary>
    );
  }

  const handleReload = () => {
    console.log("handleReload", location);
    window.location = location;
  };

  if (maintenanceInfo.check === false) {
    let { channel, channelParams } = getChannelParams(props);

    // 동적으로 네이버 맵 로드
    loadJs(
      "https://" + "openapi.map.naver.com/openapi/v3/maps.js?ncpClientId=iveo33q885&submodules=geocoder"
    );

    return (
      <ErrorBoundary
        render={() => (
          <div className={classes.root}>
            <InputProvider>
              <DataProvider
                countryCode={countryCode}
                channel={channel}
                channelParams={channelParams}
                stage={currentStage}
              >
                <LiveChatProvider>
                  <Layout />
                </LiveChatProvider>
              </DataProvider>
            </InputProvider>
            
          </div>
        )}
        renderError={({ error }) => (
          <Sorry message={error} handleReload={handleReload} />
        )}
      />
    );
  } else if ("error" in maintenanceInfo) {
    return (
      <ErrorBoundary>
        <Sorry
          message={maintenanceInfo.error.statusText}
          handleReload={handleReload}
        />
      </ErrorBoundary>
    );
  } else {
    return (
      <ErrorBoundary>
        <Maintenance
          since={maintenanceInfo.since}
          until={maintenanceInfo.until}
          service={maintenanceInfo.service}
          reason={maintenanceInfo.reason}
        />
      </ErrorBoundary>
    );
  }
}
export default withStyles(styles)(Main);
