import React from "react";
import { withStyles } from "@material-ui/core";
import Close from "../../asset/img/btn_action_close_nor.png";
import ClosePressed from "../../asset/img/btn_action_close_pre.png";

const styles = (theme) => ({
  mainBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100wh%",
    height: "100vh",
  },
  maintenanceText1: {
    fontSize: theme.font.size16,
    fontWeight: "bold",
    color: theme.color.text,
    margin: "20px 0 0 0",
  },
  maintenanceText2: {
    fontSize: theme.font.size16,
    fontWeight: "bold",
    color: theme.color.text,
    margin: "5px 0",
  },
  periodBox: {
    width: "220px",
    height: "104px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "12px",
    border: `1px solid ${theme.color.list_divider}`,
    overflow: "hidden",
    margin: "28px auto 0",
    backgroundColor: "#FFFFFF",
  },
  periodTitle: {
    color: theme.color.accent,
    fontSize: theme.font.size16,
    fontWeight: "bold",
    textAlign: "center",
  },
  periodTxt: {
    //backgroundColor: "#FAFAFA",
    fontSize: theme.font.size14,
    fontWeight: "bold",
    color: theme.color.text,
    textAlign: "center",
    padding: "6px",
    lineHeight: "24px",
  },
  close: {
    width: "32px",
    height: "32px",
    position: "absolute",
    right: "6px",
    top: "6px",
    outline: "0 none",
    border: "none",
    background: "transparent",
    backgroundSize: "32px",
    backgroundImage: `url(${Close})`,
    "&:active": {
      backgroundImage: `url(${ClosePressed})`,
    },
  },
});

function Maintenance(props) {
  const { classes, since, until } = props;
  const handleClick = () => {
    window.NativeInterface.closeView() && window.NativeInterface.closeView();
  };
  return (
    <>
      <div className={classes.mainBox}>
        <button type="button" className={classes.close} onClick={handleClick} />
        <div className={classes.maintenanceText1}>서비스 점검 중입니다.</div>
        <div className={classes.maintenanceText2}>
          더욱 똑똑한 서비스로 찾아 뵙겠습니다.
        </div>
        <div className={classes.periodBox}>
          <div className={classes.periodTitle}>점검일시</div>
          <div className={classes.periodTxt}>
            {since}
            {/*2020-04-08 11:10:00*/}
            <br />~ {until}
            {/*2020-04-08 11:20:00*/}
          </div>
        </div>
      </div>
    </>
  );
}
export default withStyles(styles)(Maintenance);
