import React, { useContext, useState, Fragment } from "react";
import { withStyles, Grid, makeStyles } from "@material-ui/core";
import DataContext from "../../data/DataContext";
import TypingData from "../../../asset/img/img_text_loading.json";
import Lottie from "react-lottie";

const styles = (theme) => ({
  root: {
    display: "flex",
    // letterSpacing: "-0.5px",
    fontWeight: "300",
    marginTop: "6px"
  },
  leftContainer: {
    justifyContent: "flex-start",
  },
  rightContainer: {
    justifyContent: "flex-end",
  },
  message: {
    fontSize: theme.font.size14,
    borderRadius: "12px",
    flexWrap: "wrap",
    color: theme.color.message.color,
    padding: "13px 19px 13px 20.5px",
    maxWidth: "75%",
    wordWrap: 'break-word',
    wordBreak: "normal"
  },
  leftMessage: {
    backgroundColor: "white",
    margin: "6px 10px",
    boxShadow: "0px 6px 12px rgba(194, 194, 194, 0.58)",
  },
  rightMessage: {
    backgroundColor: theme.color.message.background,
    margin: "26px 10px",
    boxShadow: "none",
  },
  carousel: {
    width: "100%",
    height: "144px",
    position: "initial",
  },
  date: {
    color: theme.color.message.date,
    fontSize: theme.font.size11,
    alignSelf: "flex-end",
    margin: "5px 0 6px 0",
    fontWeight: "300",
    textAlign: "center"
  },
  typing: {
    width: "30px",
    height: "6px",
  },
  list: {
    display: "flex",
    overflowX: "auto",
    margin: "0px 8px 9px 8px",
  },
  listBottom: {
    position: "fixed",
    bottom: "60px",
  },
  listbutton: {
    minWidth: "fit-content",
    padding: "0px",
    // width: '100%',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "none",
    outline: "0 none",
    border: "none",
    backgroundColor: "transparent"
  },
  listitem: {
    height: "32px",
    padding: "4px 20px",
    margin: "4px",
    background: "#fff",
    border: "1px solid #0a8297",
    fontSize: theme.font.size14,
    fontWeight: "bold",
    color: theme.palette.primary.main,
    outline: "0 none",
    borderRadius: "16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:active": {
      background: theme.palette.primary.main,
      color: "#fff"
    },
    zIndex: 11
  },
  leftTail: {
    borderLeft: "14px solid transparent",
    borderRight: "14px solid transparent",
    borderTop: "18px solid #fff",
    borderRadius: "5px",
    zIndex: 10,
    margin: "6px -28px 0px 10px",
  },
  grid: {
    width: "100%",
    display: "flex",
    margin: "12px 0px"
  },
  bottom: {
    width: "100%",
    display: "flex",
    flexGrow: 1,
    alignItems: "flex-end",
  },
  bottomSpace: {
    height: "50px",
  },
});

const lottieTypingOptions = {
  loop: true,
  autoplay: true,
  animationData: TypingData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

const GridButtons = (props) => {
  const { data } = props;
  const context = useContext(DataContext);
  const useStyles = makeStyles({
    list: {
      width: "100%",
      display: "flex",
      padding: "6px 20px",
      alignItems: "stretch"
    },
    specialItem: {
      width: "100%",
      height: "2em",
      padding: "0px 18px",
      background: "#fff",
      border: "1px solid #7f7f7f",
      fontSize: "0.875em",
      color: "#262626",
      borderRadius: "1em",
      textTransform: "none",
      fontWeight: "bold",
      outline: "0 none",
      "&:active": {
        background: "#ccc",
      },
    },
    listitemContainer: {
      padding: "2px",
      fontWeight: "bold",
      display: "flex"
    },
    listitem: {
      // minWidth: 'fit-content',
      width: "100%",
      minHeight: "2em",
      padding: "0px",
      background: "#fff",
      border: "1px solid #0a8297",
      fontSize: "0.875em",
      color: "#0a8297",
      borderRadius: "1em",
      textTransform: "none",
      fontWeight: "bold",
      outline: "0 none",
      "&:active": {
        background: "#0a8297",
        color: "#fff"
      },
      lineHeight: 1,
      whiteSpace: "normal"
    },
  });
  const classes = useStyles();

  const handleClick = (button) => {
    context.request(context, button.speech, "button");
  };
  return (
    !context.conversationEnd && (
      <>
        <Grid container className={classes.list} direction="row">
          {data.buttons.special.map((button, key) => (
            <Grid item key={key} className={classes.listitemContainer}>
              <button
                type="button"
                className={classes.specialItem}
                onClick={() => handleClick(button)}
                aria-label={`${button.label}, ${key + 1} / ${data.buttons.special.length}`}
              >
                {button.label}
              </button>
            </Grid>
          ))}
        </Grid>
        <Grid container className={classes.list} direction="row">
          {data.buttons.general.map((button, key) => (
            <Grid item xs={6} key={key} className={classes.listitemContainer}>
              <button
                type="button"
                className={classes.listitem}
                onClick={() => handleClick(button)}
                aria-label={`${button.label}, ${key + 1} / ${data.buttons.general.length}`}
              >
                {button.label}
              </button>
            </Grid>
          ))}
        </Grid>
      </>
    )
  );
};

function DeviceGrid(props) {
  const { classes, data } = props;
  const context = useContext(DataContext);

  return (
    <>
      {data.text && data.text !== "" && (
        <div
          className={[
            classes.root,
            data.mine ? classes.rightContainer : classes.leftContainer,
          ].join(" ")}
          role={"text"}
          tabIndex={0}
          aria-label={`${context.string.aria.chatbotMessage} ${data.text}, ${data.date}`}
        >
          {!data.loading && data.mine && (
            <p className={classes.date}>{data.date}</p>
          )}
          {!data.mine && <div className={classes.leftTail} />}
          <p
            className={[
              classes.message,
              data.mine ? classes.rightMessage : classes.leftMessage,
            ].join(" ")}
          >
            {data.loading && (
              <Lottie
                options={lottieTypingOptions}
                className={classes.messageLoading}
                width={50}
                height={22}
              />
            )}
            {!data.loading &&
              data.text.split("\n").map((content, key) => (
                <Fragment key={key}>
                  {content}
                  <br />
                </Fragment>
              ))}
          </p>
          {!data.loading && !data.mine && (
            <p className={classes.date}>{data.date}</p>
          )}
        </div>
      )}
      {data.isLast &&
        !data.loading &&
        (
          <GridButtons className={classes.grid} data={data} />
        )}
    </>
  );
}
export default withStyles(styles)(DeviceGrid);