import { withStyles } from '@material-ui/core';
import React, { useContext } from 'react';
import DataContext from '../data/DataContext';
import Loading from './Loading';
import Header from '../chat/Header';
import Chat from '../chat/Chat';
import AutoComplete from '../chat/AutoComplete';
import Footer from '../chat/Footer';
import LiveChatHeader from "../chat/LiveChatHeader";

const styles = theme => ({
  chat: {
    width: "100%",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    top: "56px",
    bottom: "0px",
    background: theme.color.background
  },
});

const Layout = props => {
  const { classes } = props;
  const data = useContext(DataContext);

  return (
    // data.conversationStart ? 
    <>
      <Header />
      <div className={classes.chat}>
        <Chat />
        <AutoComplete />
        <LiveChatHeader/>
        <Footer />
      </div>
    </> //:
    // <Loading safari={data.userAgent.os === 'iOS'}/>
  );
}

export default withStyles(styles)(Layout);