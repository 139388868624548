import React, { useContext, useRef, useEffect, useState } from "react";
import { withStyles, Grid } from "@material-ui/core";
import Menu from "../../asset/img/btn_menu_nor.png";
import MenuDisabled from "../../asset/img/btn_menu_dis.png";
import MenuSelected from "../../asset/img/btn_menu_sel.png";
import MenuClose from "../../asset/img/btn_menu_close_nor.png";
import MenuCloseSelected from "../../asset/img/btn_menu_close_sel.png";

import DataContext from "../data/DataContext";
import InputContext from "../data/InputContext";
import Send from "../../asset/img/btn_send_nor.png";
import SendSelected from "../../asset/img/btn_send_sel.png";
import SendDisabled from "../../asset/img/btn_send_dis.png";
import TextareaAutosize from "react-autosize-textarea";

import HomeIcon from "../../asset/img/ic_undo.png";
import { useLiveChat } from "../data/LiveChat";

const styles = (theme) => ({
  root: {
    width: "100%",
    // maxHeight: "1.5rem",
    minHeight: "60px",
    zIndex: 11,
    // position: "fixed",
    bottom: "0px",
    background: theme.color.app_bar_material_background,
  },
  row: {
    minHeight: "60px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FFFFFF",
  },
  rowDisable: {
    width: "100%",
    minHeight: "inherit",
    position: "fixed",
    bottom: "0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.color.input.disabled,
    color: theme.color.primary_text_default_dark,
    fontSize: theme.font.size15, // "15px",
    fontWeight: 600,
  },
  formContainer: {
    flexGrow: 1,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    borderLeft: `1px solid ${theme.color.list_divider}`,
  },
  form: {
    flexGrow: 1,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  input: {
    width: "100%",
    maxHeight: "40px",
    border: "none",
    background: "none",
    flex: 1,
    margin: "0px 10px",
    resize: "none",
    fontSize: theme.font.size14,
    color: theme.color.input.text,
    height: "20px",
    fontWeight: "300",
    "&::placeholder": {
      color: theme.color.input.placeholder,
    },
    "&:focus::-webkit-input-placeholder": {
      color: "transparent",
    },
    fontFamily: "Noto Sans CJK KR, Noto Sans, RobotoDraft, Roboto, San Francisco, Apple SD Gothic Neo, Helvetica Neue, sans-serif",
    outline: "none",
    textDecoration: "none"
  },
  button: {
    minWidth: "40px",
    width: "56px",
    height: "56px",
    padding: "0px",
    "&:active": {
      color: theme.palette.secondary.main,
    },
    overflow: "hidden",
    backgroundColor: "transparent",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    border: "none",
    outline: "0 none",
  },
  send: {
    minWidth: "56px",
    height: "50px",
    background: "linear-gradient(to bottom right, #1e8728, #00829b, #1377d1)",
    border: "0px",
    borderRadius: "25px 0px 0px 25px",
    outline: "0 none",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "0px",
  },
  sendImage: {
    minWidth: "32px",
    height: "32px",
    backgroundSize: "contain",
    backgroundImage: `url(${Send})`,
    "&:active": {
      backgroundImage: `url(${SendSelected})`,
    },
    margin: "0px 8px",
  },
  sendImageDisabled: {
    backgroundImage: `url(${SendDisabled})`,
  },
  test: {
    width: "46px",
    height: "42px",
    background: "linear-gradient(to bottom right, #6ad17d, #5680e9)",
    border: "0px",
    borderRadius: "25px 0px 0px 25px",
    outline: "0 none",
    right: "0px",
    position: "absolute",
    transition: "all .5s ease-in-out",
  },
  recording: {
    width: "calc(100vw - 64px)",
    transition: "all .5s ease-in-out",
  },
  menuButton: {
    height: "32px",
    margin: "8px",
    backgroundSize: "32px",
    backgroundImage: `url(${Menu})`,
    "&:active": {
      backgroundImage: `url(${MenuSelected})`,
    },
  },
  menuClose: {
    height: "32px",
    margin: "8px",
    backgroundSize: "32px",
    backgroundImage: `url(${MenuClose})`,
    "&:active": {
      backgroundImage: `url(${MenuCloseSelected})`,
    },
  },
  menuDisabled: {
    backgroundImage: `url(${MenuDisabled})`,
  },
  menu: {
    // minHeight: "13.375em", // "214px",
    width: "100%",
    // height: "fit-content",
    background: theme.color.menu.background,
    padding: "10px 20px",
    margin: "0px",
    overflow: "auto",
    maxHeight: "calc(100% - 60px)"
  },
  menus: {
    width: "100%",
    height: "100%",
    minHeight: "2rem", // "32px",
    borderRadius: "32px", // "16px",
    // fontSize: theme.font.size14,
    fontSize: "16px",
    fontWeight: "bold",
    outline: "0 none",
    lineHeight: "1",
    padding: "0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    wordBreak: "break-word"
  },
  menuHome: {
    background: "#ffffff",
    border: "solid 1px #7f7f7f",
    color: theme.color.menu.home,
    fontWeight: "bold",
    fontSize: theme.font.size14,
    backgroundImage: "none",
    "&:active": {
      background: theme.color.menu.homeSelected,
    },
    padding: "0px 12px",
    display: "flex",
    justifyContent: "space-between"
  },
  dummyHomeIcon: {
    width: "24px",
    height: "24px"
  },
  homeIcon: {
    minWidth: "24px", // "24px",
    height: "24px", // "24px",
    backgroundSize: "24px", // "24px",
    backgroundImage: `url(${HomeIcon})`,
    // position: "absolute",
    right: "40px", // "40px"
  },
  menuItem: {
    background: "#ffffff",
    border: "solid 1px #087e93",
    color: theme.color.menu.item,
    "&:active": {
      background: theme.color.menu.item,
      color: "#ffffff",
    },
    fontSize: theme.font.size14,
    whiteSpace: "normal",
    padding: "0px 12px"
  },
});

function inputValidCheck(inputStr) {
  let result = true;
  if (inputStr.trim().length <= 0) {
    result = false;
  }
  return result;
}

function strByteLength(inputStr) {
  if (inputStr.length === 0) return 0;

  let tempChar = "";
  let byteLength = 0;
  for (let index = 0; inputStr.length >= index; index++) {
    tempChar = inputStr.charCodeAt(index);
    byteLength += tempChar >> 11 ? 3 : tempChar >> 7 ? 2 : 1;
  }
  return byteLength;
}

const menuRestart = {
  title: "대화 다시 시작",
  text: "대화 다시 시작",
  aria: "1/9, 항목9개",
};

const menuItems = [
  {
    title: "제품 고장 및 수리",
    text: "제품 고장 및 수리",
    intent: "THQ_DIAGNOSIS",
    aria: "2/9, 항목9개",
  },
  {
    title: "소모품 구입",
    text: "소모품 구입",
    intent: "THQ_EXPENDABLES_PURCHASE",
    aria: "3/9, 항목9개",
  },
  {
    title: "이전설치 예약",
    text: "이전설치 예약",
    intent: "THQ_RELOCATE_BOOK",
    aria: "4/9, 항목9개",
  },
  {
    title: "예약 확인 및 취소",
    text: "예약 확인 및 취소",
    intent: "THQ_BOOK_CHECK",
    aria: "5/9, 항목9개",
  },
  {
    title: "제품 정보 안내",
    text: "제품 정보 안내",
    intent: "THQ_PRODUCT_SEARCH",
    aria: "6/9, 항목9개",
  },
  {
    title: "기획전/이벤트 안내",
    text: "기획전/이벤트 안내",
    intent: "THQ_EVENT_SEARCH",
    aria: "7/9, 항목9개",
  },
  {
    title: "센터 및 매장 찾기",
    text: "센터 및 매장 찾기",
    intent: "THQ_SC_BS_LOCATION",
    aria: "8/9, 항목9개",
  },
  {
    title: "상담사 채팅 연결",
    text: "상담사 채팅 연결",
    intent: "THQ_INQUIRY_BY_CHAT",
    aria: "9/9, 항목9개",
  },
  // { title: "음성 명령", text: "음성 명령" }
  // {
  //   title: "[TEST] Link",
  //   link: "https://www.lge.co.kr/",
  //   aria: "9/9, 항목9개"
  // },
];

function Footer(props) {
  const { classes } = props;
  const data = useContext(DataContext);
  const input = useContext(InputContext);
  const inputRef = useRef(null);
  const [ menu, setMenu ] = useState(false);
  const { livechat, livechatApi } = useLiveChat();

  const handleHome = () => {
    console.log("context - channel", data.channel);
    console.log("context - channelParams", data.channelParams);
    console.log("context - countryCode", data.countryCode);
    data.request(data, data.string.chat.initConversation, "button");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (inputValidCheck(input)) {
      if (livechat.enable) {
        livechatApi.send(input.input);
      } else {
        data.request(data, input, "text");
      }
      input.setInput(input);
    } else {
      console.log("input value is not valid");
    }

    if (data.userAgent.deviceType === "mobile") {
      // 모바일인 경우, 키보드 Focus 를 삭제하여 키보드를 내린다.
      inputRef.current.blur();
    } else {
      inputRef.current.focus();
    }
    input.keyboard.setOpen(false);
  };

  const handleChange = (e) => {
    e.preventDefault();
    const text =
      e.target.value.length > 256
        ? e.target.value.substring(0, 256)
        : e.target.value;
    input.setInput(text);
  };

  const handleSend = () => {
    if (inputValidCheck(input.input)) {
      if (livechat.enable) {
        livechatApi.send(input.input);
      } else {
        data.request(data, input.input, "text");
      }
      
      input.setInput("");
    }
    // setTimeout(() => inputRef.current.focus(), 100);
    input.keyboard.setOpen(false);
  };

  const handleEnter = (e) => {
    if (
      e.keyCode === 13 &&
      e.shiftKey === false &&
      data.userAgent.deviceType !== "mobile"
    ) {
      e.preventDefault();
      handleSend();
    }
  };

  const handleFocus = () => {
    if (
      input.autoComplete &&
      input.autoComplete.enable &&
      input.autoComplete.parameters &&
      !input.autoComplete.open
    ) {
      input.autoComplete.setOpen(true);
      input.keyboard.setOpen(true);
    } else if (!input.keyboard.open) {
      input.keyboard.setOpen(true);
    }

    // 채팅 내용이 긴 경우 딜레이를 500ms 로 증가 시킨다.
    data.scroll(false, data.isShowOldConversations ? 600 : 300);
    setMenu(false);
  };

  const handleFocusOut = () => {
    if (
      input.autoComplete &&
      input.autoComplete.enable &&
      input.autoComplete.parameters &&
      !input.autoComplete.open &&
      input.input.length > 0
    ) {
      setTimeout(() => {
        input.autoComplete.setOpen(true);
      }, 200);
    } else if (input.autoComplete.open) {
      setTimeout(() => {
        input.autoComplete.setOpen(false);
        input.keyboard.setOpen(false);
      }, 200);
    }
  };

  const handleKeyboardOpen = (open) => {
    if (input.keyboard.open && !open) {
      inputRef.current.blur();
      // input.autoComplete.setOpen(false);
    }
  };

  useEffect(() => {
    input.keyboard.handleOpen = handleKeyboardOpen;
  }, []);

  const closeKeyboard = () => {
    if (input.keyboard.open) { 
      input.keyboard.setOpen(false);
      console.log("close keyboard")
    }
    else if (
      input.autoComplete &&
      input.autoComplete.enable &&
      input.autoComplete.parameters &&
      input.autoComplete.open) {
      input.autoComplete.setOpen(false);
    }
  };

  // Footer에서 스크롤 시 키보드 종료 이벤트를 설정한다.
  window.isTabed = false;
  window.addEventListener("touchstart", () => (window.isTabbed = true));
  window.addEventListener("touchend", () => (window.isTabbed = false));
  window.addEventListener("touchmove", () => {
    if (window.isTabbed) {
      closeKeyboard();
      window.isTabbed = false;
    }
  });

  const handleMenu = () => {
    if (input.keyboard.open) input.keyboard.setOpen(false);
    if (!menu) data.scroll(false, data.isShowOldConversations ? 600 : 300);
    setMenu(!menu);
  };

  const handleMenuClick = (item) => {
    if (item.intent) {
      data.request(
        data,
        item.text,
        "button",
        { to: item.intent },
        { mode: "bypass", intent: "DIRECT_MESSAGE" }
      );
    } else {
      data.request(data, item.text, "button");
    }
    setMenu(false);
  };

  return (
    <div className={classes.root}>
      
      <div className={classes.row}>
        {/* <button
          className={[classes.button, classes.home].join(" ")}
          disabled={data.conversationEnd || !data.conversationStart}
          onClick={handleHome}
          aria-label={data.string.aria.footer.restart}
        /> */}
        <button
          type="button"
          className={[
            classes.button,
            menu ? classes.menuClose : classes.menuButton,
            (data.conversationEnd || !data.conversationStart) &&
              classes.menuDisabled,
          ].join(" ")}
          disabled={data.conversationEnd || !data.conversationStart}
          onClick={handleMenu}
          aria-label={menu ? data.string.aria.footer.menuClose : data.string.aria.footer.menuOpen}
        />
        <div className={classes.formContainer}>
          <form className={classes.form} onSubmit={handleSubmit}>
            <label id="autocomplete_label" hidden>입력 예시 표시됨.</label>
            <TextareaAutosize
              className={classes.input}
              ref={inputRef}
              type="text"
              value={data.conversationEnd ? "" : input.input}
              onSubmit={handleSubmit}
              onChange={handleChange}
              onKeyDown={handleEnter}
              onFocus={handleFocus}
              // onBlur={handleFocusOut}
              disabled={data.conversationEnd || !data.conversationStart}
              placeholder={
                data.conversationEnd ? "" : data.string.chat.inputHint
              }
              maxRows={2}
              aria-labelledby={input.autoComplete.open && "autocomplete_label"}
            />
          </form>
        </div>
        {/* <button
          className={[classes.test, record ? classes.recording : ""].join(" ")} 
          onClick={handleSend} 
          disabled={data.conversationEnd}
        /> */}
        <button
          type="button"
          className={classes.send}
          onClick={handleSend}
          disabled={data.conversationEnd}
          aria-label={data.string.aria.footer.sendUserInput}
        >
          <div
            className={[
              classes.sendImage,
              data.conversationEnd ? classes.sendImageDisabled : "",
            ].join(" ")}
          />
        </button>

        {/* <button
          className={[
            classes.button,
            data.conversationEnd ? classes.sendDisabled : classes.send,
          ].join(" ")}
          disabled={data.conversationEnd}
          onClick={handleSend}
          aria-label={data.string.aria.footer.sendUserInput}
        /> */}

        {(data.conversationEnd || data.zendeskPaused || (data.zendeskEnabled && !data.zendeskConnected)) && (
          <div className={classes.rowDisable} role={"status"}>
            {(!data.zendeskPaused && !(data.zendeskEnabled && !data.zendeskConnected)) &&  data.string.chat.conversationEnd}
          </div>
        )}
      </div>
      
      {menu && !data.conversationEnd && (
        <Grid className={classes.menu} container spacing={1}>
          <Grid item xs={12} sm={12}>
            <button
              type="button"
              className={[classes.menus, classes.menuHome].join(" ")}
              onClick={() => handleMenuClick(menuRestart)}
              aria-label={`${data.string.chat.initConversation}, ${menuRestart.aria}`}
            >
              <span className={classes.dummyHomeIcon} disabled/>
              {menuRestart.title}
              <span className={classes.homeIcon} />
            </button>
          </Grid>
          {menuItems.map((item) => (
            <Grid item xs={6}>
              <button
                type="button"
                className={[classes.menus, classes.menuItem].join(" ")}
                onClick={() => handleMenuClick(item)}
                aria-label={`${item.title}, ${item.aria}`}
              >
                {item.title}
              </button>
            </Grid>
          ))}
        </Grid>
      )}
    </div>
  );
}
export default withStyles(styles)(Footer);