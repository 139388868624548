import React, { useContext, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import DataContext from "../../data/DataContext";
import msgRetry from '../../../asset/img/msg_re.png';
import msgDelete from '../../../asset/img/msg_re_x.png';
import TypingData from "../../../asset/img/img_text_loading.json";
import Lottie from "react-lottie";

const styles = theme => ({
  root: {
    display: "flex",
    letterSpacing: "-0.5px"
  },
  leftContainer: {
    justifyContent: "flex-start"
  },
  rightContainer: {
    justifyContent: "flex-end"
  },
  message: {
    fontSize: theme.font.size13_5,
    borderRadius: "6px",
    flexWrap: "wrap",
    color: '#fff',
    padding: "10px",
    maxWidth: "75%",
    zIndex: 1,
    wordWrap: 'break-word'
  },
  leftMessage: {
    backgroundColor: '#ff0000',
    margin: "6px 20px",
    boxShadow: "0px 1px 11px rgba(0, 0, 0, 0.1)"
  },
  rightMessage: {
    backgroundColor: theme.color.message.background,
    margin: "26px 10px",
    boxShadow: "none"
  },
  date: {
    color: theme.color.message.date,
    fontSize: theme.font.size11,
    alignSelf: "flex-end",
    margin: "5px 0 6px 0",
    textAlign: "center"
  },
  rightDate: {
    margin: "5px 0 22px 0"
  },
  typing: {
    width: '30px',
    height: '6px'
  },
  leftTail: {
    borderLeft: '14px solid transparent',
    borderRight: '14px solid transparent',
    borderTop: '18px solid #fff',
    borderRadius: '5px',
    zIndex: 10,
    margin: '6px -28px 0px 10px',
  },
  rightTail: {
    borderLeft: '14px solid transparent',
    borderRight: '14px solid transparent',
    borderTop: '18px solid ' + theme.color.message.background,
    borderRadius: '5px',
    zIndex: 10,
    margin: '26px 10px 0px -28px',
    minWidth: "21px"
  },
  leftBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-end'
    
  },
  messageStatusImg: {
    width: "24px",
    height: "24px",
    cursor: "pointer"
    //margin: "10px 0 0 0"
  },
  rightBox: {
    position: "relative",
  },
  
  deleteMessageImg: {
    position: "absolute",
    left: "-20px",
    top: "0px",
    width: "24px",
    height: "24px",
    zIndex: "20",
    cursor: "pointer"
  },
  messageLoading: {
    width: "24px",
    height: "24px",
    cursor: "pointer"
  },
});

const lottieTypingOptions = {
  loop: true,
  autoplay: true,
  animationData: TypingData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

function TXLeftRaw(props) {
  const { classes, data } = props;
  const loading = data.loading;

  console.log('data', data);

  return <div
    className={[
      classes.root,
      classes.leftContainer
    ].join(" ")}
  >
    <div
      className={[
        classes.message,
        classes.leftMessage
      ].join(" ")}>
      { loading ? 
        <Lottie
          options={lottieTypingOptions}
          className={classes.messageLoading}
          width={50}
          height={22}
        />
      :(
        // data.text.split("\n").map((content, index) => (
        //   <Fragment key={index}>
        //     <span>{content}</span>
        //     <br />
        //   </Fragment>
        // )
        Object.keys(data.text).map(key => (
          <>
            <span>{key} : {JSON.stringify(data.text[key])}</span>
            <br />
          </>
        )
      ))}
    </div>
    {!loading && <p className={classes.date}>{data.date}</p>}
  </div>
}

function TXRightRaw(props) {
  const { classes, data } = props;
  const context = useContext(DataContext);
  const handleRetry = () => {
    context.deleteMessage(data.id)
    context.request(context, data.text, "text");
  }
  const handleDelete = () => {
    context.deleteMessage(data.id);
  }
  const loading = data.loading;
  const online = data.online;
  return <div
    className={[
      classes.root,
      classes.rightContainer
    ].join(" ")}
  >
    <div className={classes.leftBox}>
      {loading && <Lottie
        options={lottieTypingOptions}
        className={classes.messageLoading}
        width={32}
        height={14}
      />}
      {!online && <img
        className={classes.messageStatusImg}
        onClick={handleRetry}
        src={msgRetry} alt={"retry"} />}
      <p className={[classes.date, classes.rightDate].join(' ')}>{data.date}</p>
    </div>
    <p
      className={[
        classes.message,
        classes.rightMessage
      ].join(" ")}
    >
      {data.text.split("\n").map((content, index) => (
        <Fragment key={index}>
          <span>{content}</span>
          <br />
        </Fragment>
      ))}
    </p>
    {!online&&(
      <div className={classes.rightBox}>
        <img 
          className={classes.deleteMessageImg} 
          onClick={handleDelete}
          src={msgDelete} alt={"msgDelete"} />
      </div>
    )}
    <div className={classes.rightTail} />
  </div>
}
const TXRight = withStyles(styles)(TXRightRaw);
const TXLeft = withStyles(styles)(TXLeftRaw);

function Debug(props) {
  const { data } = props;
  return (data.mine)?<TXRight data={data}/>:<TXLeft data={data}/>
}
export default withStyles(styles)(Debug);
