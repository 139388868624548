import React, { useState, useContext, useEffect, useRef } from "react";
import { withStyles, TextField, Dialog } from "@material-ui/core";
import DataContext from "../data/DataContext";
import { postCenterLMS } from "../data/DataLoader";

//for terms agreements
//import iconChk from '../../asset/img/ico_chk2.png';
//import iconUnChk from '../../asset/img/ico_chk_grey.png';
import iconInfo from "../../asset/img/ico_info.png";

const styles = (theme) => ({
  dialogRoot: {
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  dialogContainer: {
    height: "fit-content",
  },
  dialogBody: {
    borderRadius: "20px",
  },
  smsBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    font: "-apple-system-body",
  },
  title: {
    fontSize: theme.font.size18,
    fontWeight: "bold",
    color: theme.color.text,
    alignSelf: "flex-start",
    padding: "18px 24px 0px 24px",
  },
  dialogContent: {
    padding: theme.spacing(1),
  },
  buttonGroup: {
    borderRadius: "0px 0px 20px 20px",
    border: "0px",
    justifyContent: "flex-end",
    padding: "0px 14px 14px 14px",
    height: "52px",
    display: "flex",
    font: "-apple-system-body",
  },
  button: {
    borderRadius: "20px",
    border: "none",
    fontSize: theme.font.size15, // "15px",
    fontWeight: "bold",
    color: theme.color.accent,
    height: "2.5em", // "40px",
    width: "4em", // "64px",
    background: "#ffffff",
    "&:active": {
      color: "rgba(38, 38, 38, 0.4)",
    },
    outline: "0 none",
  },
  info1: {
    textAlign: "start",
    fontSize: theme.font.size15, // "15px",
    fontWeight: "300",
    color: theme.color.text,
    margin: "18px 24px 16px 24px",
  },
  info2: {
    width: "100%",
    textAlign: "center",
    fontSize: theme.font.size12,
    fontWeight: "300",
    color: "#666",
    margin: "8px 24px 11px 24px",
  },
  inputBox: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    background: "#fafafa",
    textAlign: "center",
    padding: "20px 24px",
  },
  inputBoxTitle: {
    fontSize: theme.font.size12,
    fontWeight: "bold",
  },
  textfield: {
    height: "32px",
    margin: "0px",
    width: "100%",
  },
  inputTextField: {
    fontSize: theme.font.size18,
    fontWeight: "bold",
    color: theme.color.accent,
    textAlign: "start",
    "&::placeholder": {
      opacity: 1
    }
  },
  validBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "12px",
  },
  validIcon: {
    width: "16px",
    height: "16px",
    margin: "0 5px 0 0",
  },
  validText: {
    fontSize: theme.font.size14,
    fontWeight: "300",
    color: "#7f7f7f",
  },
});

function validatePhoneNumber(countryCode, phoneNumber) {
  //obj, objMsg){
  const errorMsg = {
    phoneDigit: "휴대전화번호를 정확히 입력해 주세요",
  };

  let regExp = null;
  if (countryCode === "kr") {
    //var regExp = /^01([0|1|6|7|8|9]?)-?([0-9]{3,4})-?([0-9]{4})$/;
    regExp = /^(?:(010\d{4})|(01[1|6|7|8|9]\d{3,4}))(\d{4})$/;
  }
  if (regExp && !regExp.test(phoneNumber)) {
    return errorMsg["phoneDigit"];
  }
  if (countryCode !== "kr") {
    return null;
  }
  var tel1 = phoneNumber.substr(0, 3);
  var tel2 =
    phoneNumber.length === 10
      ? phoneNumber.substr(3, 3)
      : phoneNumber.substr(3, 4);
  var tel3 = phoneNumber.substr(phoneNumber.length - 4, 4);
  var tel2_ = phoneNumber.substr(3, 1);

  var ment = "";
  try {
    if (
      (tel1 === "011" ||
        tel1 === "016" ||
        tel1 === "017" ||
        tel1 === "018" ||
        tel1 === "019") &&
      tel2.length === 3
    ) {
      if (tel2 >= 200 && tel2 <= 899) {
        //return true;
      } else {
        ment =
          '국번 번호는 "200~899" 만 허용하며, "000~199", "900~999" 허용 불가합니다.';
        return ment; //errorMsg["phoneDigit"];
      }
    }
    if (ment === "") {
      switch (tel1) {
        case "010":
          if (tel2_ === "0" || tel2_ === "1") {
            ment = '국번 번호의 첫자리가 "0, 1"은 잘못된 국번입니다.';
          } else {
            /*return true;*/
          }
          break;
        case "011":
          if (tel2.length === 4) {
            if (
              (tel2 >= 1700 && tel2 <= 1799) ||
              (tel2 >= 9500 && tel2 <= 9999)
            ) {
              //return true;
            } else {
              ment =
                '국번 번호 범위가 맞지 않습니다. "011" 이면 "1700~1799" 또는 "9500~9999" 만 허용합니다.';
            }
          } else if (tel2.length === 3) {
            if (tel2 >= 200 && tel2 <= 899) {
              //return true;
            } else {
              ment =
                '국번 번호 범위가 맞지 않습니다. "011" 이면 "200~899" 만 허용합니다.';
            }
          }
          break;
        case "016":
        case "019":
          if ((tel2 >= 200 && tel2 <= 899) || (tel2 >= 9000 && tel2 <= 9999)) {
            //return true;
          } else {
            ment =
              '국번 번호 범위가 맞지 않습니다. "016, 019" 이면 "9000~9999" 까지만 허용합니다.';
          }
          break;
        case "017":
        case "018":
          if (tel2.length === 4) {
            ment = '"017, 018"의 경우 국번은 4자리이면 안됩니다.';
          } else {
            if (tel2 >= 200 && tel2 <= 899) {
              //return true;
            } else {
              ment =
                '국번 번호 범위가 맞지 않습니다. "017, 018" 이면 "200~899" 까지만 허용합니다.';
            }
          }
          break;
        default:
          ment = '"010, 011, 016, 017, 018, 019" 만 허용합니다.';
          break;
      }
      if (ment === "") {
        return null;
      } else {
        return ment; // errorMsg["phoneDigit"];
      }
    }
  } catch (er) {
    console.log(er);
  } finally {
    console.log(
      "휴대번호 유효성 검사결과 : [tel1:" +
        tel1 +
        ", tel2:" +
        tel2 +
        ", tel3:" +
        tel3 +
        ", tel2_:" +
        tel2_ +
        "]" +
        ment
    );
  }
}

function SMS(props) {
  const {
    classes,
    open,
    handleOk,
    handleClose,
    centerName,
    centerSeq,
    defaultPhoneNumber,
  } = props;
  const [phoneNumber, setPhoneNumber] = useState("");
  const [validationText, setValidationText] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const inputRef = useRef(null);

  const data = useContext(DataContext);

  useEffect(() => {
    handlePhoneNumberChange(defaultPhoneNumber);
  }, [defaultPhoneNumber]);

  const handlePhoneNumberChange = (value) => {
    const regExp = /[^0-9]/gi;
    if (regExp.test(value)) {
      value = value.replace(regExp, "");
    }
    setPhoneNumber(value);
  };

  const handleSendResult = (resultObj) => {
    if ("result" in resultObj && resultObj.result.result === 1) {
      data.addTXMessage(false, data.string.chat.loSMSSuccess);
      return;
    }
    let resultType = resultObj.result.result;

    let outputMsg = "";
    switch (resultType) {
      case "errors.doNotSendDuple1Munite":
        outputMsg = data.string.chat.loSMSInProgress;
        break;
      case "errors.mobilePhoneNum":
        outputMsg = data.string.chat.loSMSWrongNumber;
        break;
      default:
        outputMsg = data.string.chat.loSMSCommonError;
    }
    data.addTXMessage(false, outputMsg);
  };
  const initInput = () => {
    setPhoneNumber("");
    setValidationText(null);
  };

  const handleSend = async () => {
    try {
      if (phoneNumber) {
        let validationText = validatePhoneNumber(data.countryCode, phoneNumber);
        if (validationText) {
          setValidationText(validationText);
          inputRef.current && inputRef.current.focus();
        } else {
          let validatedPhoneNumber = phoneNumber;
          setButtonDisabled(true);

          const result = await postCenterLMS(data, phoneNumber, centerSeq);

          initInput();
          setButtonDisabled(false);

          handleSendResult(result);
          console.log(result);
          handleOk(validatedPhoneNumber);
        }
      } else {
        setValidationText("휴대전화번호를 입력해 주세요");
        inputRef.current && inputRef.current.focus()
      }
    } catch (e) {
      setValidationText("휴대전화번호를 입력해 주세요");
      inputRef.current && inputRef.current.focus()
      console.log("handleSend - error", e);
      return;
    }
  };

  const handleCancel = () => {
    setValidationText(null);
    setPhoneNumber("");
    handleClose();
  };

  return (
    <>
      <Dialog
        classes={{
          root: classes.dialogRoot,
          paper: classes.dialogBody,
          container: classes.dialogContainer,
        }}
        open={open}
        onClose={handleCancel}
        aria-labelledby="form-dialog-title"
      >
        <div className={classes.smsBox}>
          <div className={classes.title} role={"text"} aria-label={`${data.string.aria.sms.title}, ${data.string.aria.title1}`}>{data.string.aria.sms.title}</div>
          <div className={classes.info1} role={"text"} tabIndex={0}>
            {centerName} 위치정보를 문자로 발송해 드립니다.
          </div>
          <div className={classes.inputBox}>
            <div className={classes.inputBoxTitle}>받으실 휴대전화</div>
            <TextField
              className={classes.textfield}
              autoFocus
              margin="normal"
              id="phoneNumber"
              placeholder="숫자만 입력"
              type="tel"
              onChange={(e) => {
                e.preventDefault();
                handlePhoneNumberChange(e.target.value);
              }}
              value={phoneNumber}
              InputProps={{
                classes: {
                  input: classes.inputTextField,
                },
              }}
              inputProps={{ maxlength: 11, "aria-label": `받으실 휴대전화 입력`, readOnly: true}}
              inputRef={inputRef}
            />
            <div role={"status"} className={classes.validBox} hidden={!validationText}>
              <img
                className={classes.validIcon}
                src={iconInfo}
                alt={""}
                hidden={!validationText}
                disabled
                tabIndex={-1}
              />
              <div className={classes.validText} tabIndex={1}>
                {validationText}
              </div>
            </div>
            {/* )} */}
          </div>
          <div className={classes.info2}>
            통신사 사정으로 문자전송이 지연될 수 있습니다.
          </div>
        </div>

        <div className={classes.buttonGroup}>
          <button
            type="button"
            disableRipple
            disabled={buttonDisabled}
            className={classes.button}
            onClick={handleCancel}
          >
            취소
          </button>
          <button
            type="button"
            disableRipple
            disabled={buttonDisabled}
            className={classes.button}
            onClick={handleSend}
          >
            전송
          </button>
        </div>
      </Dialog>
    </>
  );
}
export default withStyles(styles)(SMS);
