import React, { useContext, useState, useRef } from "react";
import { Button, withStyles, Typography } from "@material-ui/core";
import DataContext from "../../data/DataContext";
import Carousel from "../../carousel/Carousel";
import More from "../../../asset/img/ic_more.png";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    margin: "12px 20px",
    borderRadius: "12px",
    border: "1px solid #e1e1e1",
    background: "#fff",
    zIndex: 10,
  },
  title: {
    margin: "20px",
    display: "flex",
    flexDirection: "column",
  },
  place: {
    // maxWidth: "6rem",
    fontWeight: "bold",
    fontSize: theme.font.size15, // "15px",
    padding: "0px 8px 10px 0px",
    margin: "0px 8px 0px 0px",
    color: "#262626",
    borderBottom: "1px solid #e1e1e1",
    // whiteSpace: "nowrap",
    wordBreak: "break-word",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    marginTop: "8px",
  },
  description: {
    fontWeight: "300",
    fontSize: theme.font.size15, // "15px",
    color: "#262626",
  },
  devicesNumber: {
    margin: "0 4px",
    fontWeight: "300",
    fontSize: theme.font.size15, // "15px",
    color: theme.color.accent,
  },
  carousel: {
    margin: "8px 0px",
  },
  buttonContainer: {
    width: "5rem",
    minWidth: "5rem",
    alignSelf: "start",
    outline: 0,
    border: "none",
    background: "transparent",
    "&:active": {
      background: "rgba(38, 38, 38, 0.1)",
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  button: {
    minWidth: "5rem",
    minHeight: "5rem",
    margin: "0px",
    padding: "0px",
    outline: "0 none",
    border: "none",
    background: "transparent",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "16px",
    "&:active": {
      background: "rgba(38, 38, 38, 0.1)",
    },
    font: "-apple-system-body",
  },
  buttonImage: {
    width: "2.313rem",
    height: "2.313rem",
    margin: "0px 0px 8px 0px",
  },
  buttonText: {
    width: "5rem",
    minWidth: "5rem", //"4.375rem",
    wordBreak: "break-all",
    color: "#262626",
    fontWeight: "normal",
    fontSize: theme.font.size12, //theme.font.size12,
  },
  buttonDisabled: {
    color: "rgba(38,38,38,0.4)",
  },
  more: {
    height: "2rem",
    color: theme.color.text,
    fontSize: theme.font.size12,
    fontWeight: "bold",
    backgroundColor: "transparent",
    border: "none",
    outline: "0 none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  moreImage: {
    width: "16px",
    height: "16px",
  },
  divider: {
    background: "#e1e1e1",
    margin: "0px 20px",
    height: "0.5px",
  },
  emptyDevice: {
    fontWeight: "bold",
  },
  upgradable: {
    color: "#ffffff",
    background: theme.color.accent,
    fontSize: theme.font.size10,
    padding: "0px 8px",
    minHeight: theme.font.size12,
    verticalAlign: "middle",
    borderRadius: "24px",
    minWidth: "24px",
  },
  empty8: {
    background: "transparent",
    minHeight: theme.font.size8,
  },
  empty12: {
    background: "transparent",
    minHeight: theme.font.size12,
  },
});

const Product = React.forwardRef((props) => {
  const { classes, data } = props;
  const context = useContext(DataContext);

  const handleClick = (button) => {
    if (button.type.toLowerCase() === "action_backend") {
      context.request(
        context,
        button.speech,
        "button",
        button.parameters,
        button.expect
      );
    } else {
      context.request(context, button.speech, "button");
    }
  };

  const upgradable = data.buttons.find(button => button.parameters && button.parameters.upgradable && button.parameters.upgradable.toUpperCase() === "Y")
  return data.buttons.map((button, index) => (
    <button
      type="button"
      disabled={context.conversationEnd}
      className={classes.buttonContainer}
      onClick={() => handleClick(button)}
      aria-label={`${
        button.parameters && button.parameters.product_name
          ? button.parameters.product_name + ","
          : button.parameters ? button.parameters.product_group_name + "," : ""
      } ${button.speech}, ${index + 1} / ${data.buttons.length}`}
    >
      {upgradable ?
      (button.parameters.upgradable.toUpperCase() === "Y" ? (
        <div className={classes.upgradable}>UP가전</div>
      ) : (
        <div className={classes.empty12}></div>
      )) : <div className={classes.empty8}></div>}
      <img
        className={classes.buttonImage}
        src={`http://${window.location.host}/image/${button.pictogram}`}
        alt={""}
      />
      
      <Typography
        className={[
          classes.buttonText,
          context.conversationEnd ? classes.buttonDisabled : "",
        ].join(" ")}
      >
        {button.speech}
      </Typography>
    </button>
  ));
});

function PC(props) {
  const { classes, data } = props;
  const [more, setMore] = useState(false);

  const handleMore = () => {
    setMore(true);
  };

  return (
    <>
      <div className={classes.root}>
        {data.items.map((item, index) =>
          !more && index >= 2 ? (
            <></>
          ) : (
            <div key={`${item.description} ${index}`}>
              <div className={classes.title}>
                {item.place && (
                  <div className={classes.place}>{item.place}</div>
                )}
                {/* <span className={classes.description} >{item.description}</span>
                {item.devices_number > 0 && (
                  <span className={classes.devicesNumber}>
                    {item.devices_number}
                  </span>
                )} */}
                <div className={classes.row}>
                  <span
                    className={[
                      classes.description,
                      item.devices_number <= 0 && classes.emptyDevice,
                    ].join(" ")}
                  >
                    {item.description}
                  </span>
                  {item.devices_number > 0 && (
                    <span className={classes.devicesNumber}>
                      {item.devices_number}
                    </span>
                  )}
                </div>
              </div>
              <div className={classes.carousel}>
                <Carousel
                  component={withStyles(styles)(Product)}
                  data={item}
                  arrows={false}
                  ariaLabel={item.place}
                />
              </div>
            </div>
          )
        )}
        {!more && data.items.length > 2 && (
          <button
            type="button"
            className={classes.more}
            aria-label={"홈 더보기"}
            onClick={handleMore}
          >
            <span>홈 더보기</span>
            <img className={classes.moreImage} src={More} alt={""} />
          </button>
        )}
      </div>
    </>
  );
}
export default withStyles(styles)(PC);
