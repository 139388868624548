import React, { useState, useCallback, useEffect } from "react";
import { withStyles } from "@material-ui/core";
import EmblaCarouselReact from "embla-carousel-react";
import arrow from "../../asset/img/btn_arr_pc_2.png";

const styles = () => ({
  root: {
    position: "relative",
    maxWidth: "100%",
    width: "100%",
    padding: "0px 10px"
  },
  list: {
    display: "flex",
    alignItems: "stretch",
    width: "100%",
    "user-select": "none",
    "-webkit-touch-callout": "none",
    "-khtml-user-select": "none",
    "-webkit-tap-highlight-color": "transparent",
  },
  swipe: {
    width: "30px",
    height: "42px",
    position: "absolute",
    display: "block",
    // transform: "translateY(-50%)",
    top: "50%",
    padding: "0px",
    overflow: "auto",
    backgroundImage: `url(${arrow})`,
    background: "none",
    textIndent: "-9999em",
    border: "none",
    "-webkit-transform": "translate3d(0,-50%,0)",
    zIndex: "12"
  },
  prev: {
    left: "0px",
    backgroundPosition: "left top",
    "&:hover": {
      backgroundPosition: "left 42px",
    },
  },
  next: {
    right: "0px",
    backgroundPosition: "right top",
    "&:hover": {
      backgroundPosition: "right 42px",
    },
  },
  dots: {
    display: "flex",
    justifyContent: "center",
    margin: "16px 0px"
  },
  dot: {
    width: "8px",
    height: "8px",
    borderRadius: "8px",
    margin: "0px 8px",
    transition: "all, .5s, ease-in-out",
  },
  dotActive: {
    background: "#0a8297",
    transform: "scale(1)",
    // opacity: "1",
  },
  dotSide: {
    background: "#666",
    transform: "scale(.75)",
    // opacity: ".2",
  },
  dotSideSide: {
    background: "#666",
    transform: "scale(.5)",
    // opacity: ".2",
  },
  blur: {
    width: "34px",
    height: "42px",
    position: "absolute",
    display: "block",
    top: "50%",
    padding: "0px",
    overflow: "auto",
    textIndent: "-9999em",
    border: "none",
    background: "none",
    "-webkit-transform": "translate3d(0,-50%,0)",
    zIndex: "12"
  },
  blurPrev: {
    left: "0px",
    backgroundImage: "-webkit-linear-gradient(to left, rgba(255,255,255,0), rgba(245,245,245,1.0))",
    backgroundImage: "linear-gradient(to left, rgba(255,255,255,0), rgba(245,245,245,1.0))"
  },
  blurNext: {
    right: "0px",
    backgroundImage: "-webkit-linear-gradient(to right, rgba(255,255,255,0), rgba(245,245,245,1.0))",
    backgroundImage: "linear-gradient(to right, rgba(255,255,255,0), rgba(245,245,245,1.0))"
  },
  test: {
    minWidth: "100px"
  }
});

function Carousel({ component: Component, ...props }) {
  const { classes, children, data, parentFunc, indicator = true, arrows = false, blur = false, ariaLabel = null } = props;
  const [embla, setEmbla] = useState(null);
  const [slide, setSlide] = useState(0);
  const [canScrollPrev, setCanScrollPrev] = useState(false);
  const [canScrollNext, setCanScrollNext] = useState(false);
  const onScrollPrev = useCallback(() => embla.scrollPrev(), [embla]);
  const onScrollNext = useCallback(() => embla.scrollNext(), [embla]);
  const [childComponent, setChildComponent] = useState(null);

  const setScrollStatus = () => {
    if (embla) {
      setSlide(embla.selectedScrollSnap());
      setCanScrollPrev(embla.canScrollPrev());
      setCanScrollNext(embla.canScrollNext());
    }
  };

  useEffect(() => {
    if (embla) {
      setCanScrollPrev(embla.canScrollPrev());
      setCanScrollNext(embla.canScrollNext());

      embla.on("select", () => {
        setScrollStatus();
      });
      embla.on("resize", () => {
        setScrollStatus();
      });
    }

    if (Component) {
      setChildComponent(
        <Component ref={embla} data={data} parentFunc={parentFunc}/>
      );
    } else {
      setChildComponent(children);
    }
    console.log("childComponent", Component, embla, data, parentFunc)


    //remove below line due to https://github.com/davidcetinkaya/embla-carousel-react/issues/13
    //return () => embla && embla.destroy();
  }, [embla]);

  const renderDots = useCallback(() => {
    if (embla) {
      return (
        <div className={classes.dots}>
          {embla.slideNodes().length === 4 ? embla.scrollSnapList().map((snap, index) => (
            <p
              key={index}
              active={index === slide ? "true" : "false"}
              onClick={() => embla.scrollTo(index)}
              hidden={index < slide - 3 || index > slide + 3}
              role={"button"}
              className={
                index === slide
                  ? [classes.dot, classes.dotActive].join(" ")
                  : index === slide + 1 || index === slide - 1
                  ? [classes.dot, classes.dotSide].join(" ")
                  : index === slide + 2 || index === slide - 2 || index === slide +3 || index === slide - 3
                  ? [classes.dot, classes.dotSideSide].join(" ")
                  : undefined
              }
              aria-label={`${index === slide ? "선택됨," : ""} ${ariaLabel ? ariaLabel : ""} ${index + 1}번째 슬라이드 보기, ${index + 1} / 4`}
            />
          )) : (
            embla.scrollSnapList().map((snap, index) => (
              <p
                key={index}
                active={index === slide ? "true" : "false"}
                onClick={() => embla.scrollTo(index)}
                hidden={index < slide - 2 || index > slide + 2}
                role={"button"}
                className={
                  index === slide
                    ? [classes.dot, classes.dotActive].join(" ")
                    : index === slide + 1 || index === slide - 1
                    ? [classes.dot, classes.dotSide].join(" ")
                    : index === slide + 2 || index === slide - 2
                    ? [classes.dot, classes.dotSideSide].join(" ")
                    : undefined
                }
                aria-label={`${index === slide ? "선택됨," : ""} ${ariaLabel ? ariaLabel : ""} ${index + 1}번째 슬라이드 보기, ${index + 1} / ${embla.scrollSnapList().length}`}
              />
          )))}
        </div>
      );
    }
    return null;
  }, [embla, slide]);
  
  
  

  

  return (
    <div className={classes.root} onTouchMove={(e) => e.stopPropagation()}>
      {childComponent && <EmblaCarouselReact
        emblaRef={setEmbla}
        options={{ align: "start", dragFree: true, containScroll: true }}
      >
        <div className={classes.list}>{childComponent}</div>
      </EmblaCarouselReact>}

      {/* Pagination 좌/우 버튼 */}
      {canScrollPrev ?
        arrows ? (
          <button
            type="button"
            className={[classes.swipe, classes.prev].join(" ")}
            onClick={onScrollPrev}
          >
            이전버튼
          </button>
        ) : blur && (
          <div className={[classes.blur, classes.blurPrev].join(" ")}/>
        )
      : null}
      {canScrollNext ?
        arrows ? (
          <button
            type="button"
            className={[classes.swipe, classes.next].join(" ")}
            onClick={onScrollNext}
          >
            다음버튼
          </button>
        ) : blur && (
          <div className={[classes.blur, classes.blurNext].join(" ")}/>
        )
      : null}

      {/* Pagination Dot 버튼 */}
      {indicator && (canScrollPrev || canScrollNext) && renderDots()}
    </div>
  );
}
export default withStyles(styles)(Carousel);
