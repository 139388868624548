import React, { useState, useEffect, useContext } from "react";
import { withStyles } from "@material-ui/core";
import { openLink } from "../util/utilNative";
import DataContext from "../data/DataContext";

const styles = (theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: theme.color.background,
  },
  loading: {
    width: "60px",
    height: "12px",
  },
});

function NaverMaps(props) {
  const {
    classes,
    id,
    style,
    title,
    defaultCenter,
    defaultZoom,
    countryCode,
  } = props;
  const [navermap, setNavermap] = useState(null);

  let random = Math.random()
    .toString(36)
    .substring(7);
  const divId = id + random;
  const context = useContext(DataContext);

  useEffect(() => {
    if (window.naver.maps && navermap === null) {
      var navermaps = window.naver.maps;
      var position = new navermaps.LatLng(defaultCenter.lat, defaultCenter.lng);
      var mapOptions = {
        center: position,
        zoom: defaultZoom,
        scaleControl: false,
        logoControl: false,
        mapDataControl: false,
        zoomControl: false,
        mapTypeControl: false,
      };

      try {
        var map = new navermaps.Map(divId, mapOptions);
        new navermaps.Marker({
          position: position,
          title: title,
          map: map,
        });

        navermaps.Event.addListener(map, "click", function() {
          var url = `${window.location.href}ext/maps/NaverMaps.html?country=${countryCode}&title=${title}&lng=${defaultCenter.lng}&lat=${defaultCenter.lat}`;
          openLink(context, "inapp", url);
        });

        setNavermap(map);
      } catch (e) {
        console.log("error", e);
      }
    }

    return () => {
      if (navermap) {
        navermap.destroy();
      }
    };
  }, []);

  return (
    <div
      id={divId}
      style={style}
      className={classes.root}
      tabIndex={0}
      role={"link"}
      aria-label={`${context.string.aria.navermap}, 마커의 위경도는 ${defaultCenter.lat}, ${defaultCenter.lng}`}
    />
  );
}
export default withStyles(styles)(NaverMaps);
