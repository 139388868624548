import React, { useContext, useState } from "react";
import { withStyles, Typography } from "@material-ui/core";
import DataContext from "../data/DataContext";
import BackIcon from "../../asset/img/btn_navi_up_material_nor.png";
import BackIconPressed from "../../asset/img/btn_navi_up_material_pre.png";
import HelpIcon from "../../asset/img/btn_action_help_material_nor.png";
import HelpIconPressed from "../../asset/img/btn_action_help_material_pre.png";
import HelpIconDisabled from "../../asset/img/btn_action_help_material_dis.png";
import InputContext from "../data/InputContext";
import { uploadHistoryHtmlFileToS3 } from "../util/utilAWS";

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "56px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    // boxShadow: "1px 1px " + theme.color.lightGrey,
    zIndex: 11,
    position: "fixed",
    top: "0px",
    padding: "0px 4px",
    backgroundColor: "#fff"
  },
  title: {
    width: "100%",
    color: theme.color.app_bar_title_text,
    fontSize: theme.font.size18,
    // fontWeight: "normal",
    fontWeight: 600,
    flexGrow: 1,
    outline: 0,
    border: "none"
  },
  help: {
    backgroundImage: `url(${HelpIcon})`,
    "&:active": {
      backgroundImage: `url(${HelpIconPressed})`,
    },
  },
  helpDisabled: {
    backgroundImage: `url(${HelpIconDisabled})`,
  },
  close: {
    color: theme.color.grey,
    minWidth: "40px",
  },
  button: {
    width: "32px",
    height: "32px",
    backgroundSize: "32px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundColor: "transparent",
    textIndent: "-9999em",
    border: "none",
    outline: "0 none",
    margin: "0px 4px",
  },
  rightButtonGroup: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  back: {
    backgroundImage: `url(${BackIcon})`,
    "&:active": {
      backgroundImage: `url(${BackIconPressed})`,
    },
  },
  livechat: {
    width: "100px"
  }
});

function Header(props) {
  const { classes } = props;
  const context = useContext(DataContext);
  const [ hiddenClick, setHiddenClick ] = useState(0);
  const [helpEnabled, setHelpEnabled] = useState(true);

  const handleHelp = () => {

    setHelpEnabled(false);
    setTimeout(() => {
      setHelpEnabled(true);
    }, 1000);

    context.request(context, context.string.chat.help, "button");
  };

  const handleClose = () => {
    // ThinQ 앱 인터페이스가 있는 경우
    if (window.NativeInterface) {
      // 대화 내용이 있는 경우만 S3 업로드 후 종료 팝업 호출
      if (context.isUserAction) {
        const htmlText = document.documentElement.innerHTML;
        uploadHistoryHtmlFileToS3(
          context.sessionId,
          context.stage,
          context.countryCode,
          htmlText
        ).then(
          (s3Res) => {
            console.log("[handleClose] S3 Upload Success", "s3Res:", s3Res);
            window.NativeInterface.closeViewWithPopup &&
              window.NativeInterface.closeViewWithPopup();
          },
          (s3Reject) => {
            console.log("[handleClose] S3 Upload Fail", "s3Res:", s3Reject);
            window.NativeInterface.closeViewWithPopup &&
              window.NativeInterface.closeViewWithPopup();
          }
        );
      } 
      // 대화 내용이 없는 경우 일반 종료
      else {
        window.NativeInterface.closeView &&
          window.NativeInterface.closeView();
      }
    } else {
      context.endConversation();
    }
  };

  const handleHidden = () => {
    if (context.stage !== "op") {
      if (hiddenClick >= 4) {
        context.addTXMessage(false, { "Stage": context.stage, "SessionID": context.sessionId, "Loading Bar": (window.endLoading - window.startLoading) / 1000 + " s", "Start Loading": (window.completeLoading - window.endLoading) / 1000 + " s", "Total Loading": (window.completeLoading - window.startLoading) / 1000 + " s"}, true)
        setHiddenClick(0);
      } else {
        setHiddenClick(hiddenClick + 1);
      }
    }
  }

  return (
    <>
      <div className={classes.root}>
        <button
          type="button"
          className={[classes.button, classes.back].join(" ")}
          onClick={handleClose}
          aria-label={context.string.aria.header.exitButton}
        />
        {/*
        <span>
          <Button
            disableRipple={true}
            className={classes.button}
            onClick={handleMenu}
          >
            <MenuIcon />
          </Button>
        </span>
        */}
        <div
          className={classes.title}
          aria-label={context.string.aria.header.title}
          onClick={handleHidden}
          tabIndex={0}
          role={"text"}
        >
          {context.string.title}
        </div>
        <div className={classes.rightButtonGroup}>
          <button
            type="button"
            disabled={context.conversationEnd || !context.conversationStart || !helpEnabled}
            className={[
              classes.button,
              (context.conversationEnd || !context.conversationStart || !helpEnabled) ? classes.helpDisabled : classes.help,
            ].join(" ")}
            onClick={handleHelp}
            aria-label={context.string.aria.header.helpButton}
          />
          {/* <span>
            <Button
              disableRipple={true}
              className={[classes.button, classes.close].join(" ")}
              onClick={handleClose}
              aria-label={context.string.aria.header.exitButton}
            >
              <CloseIcon/>
            </Button>
          </span> */}
        </div>
      </div>
      {/* <Menu menuOpen={menuOpen} /> */}
    </>
  );
}
export default withStyles(styles)(Header);
