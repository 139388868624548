// import AWS from "aws-sdk/dist/aws-sdk-react-native";
import AWS from "aws-sdk";

const makeHtmlBlob = (htmlText, htmlFileName) => {
  var blobObj = new Blob([htmlText], { type: "text/html" });
  blobObj.name = htmlFileName;
  return blobObj;
};

const getRegion = (countryCode) => {
  let region = "";
  switch (countryCode.toLowerCase()) {
    case "kr":
      region = "ap-northeast-2";
      break;
    case "us":
      region = "us-west-2";
      break;
    default:
      region = "";
  }

  if (region === "") {
    throw new Error("can't assigned region with '" + countryCode + "'");
  }
  return region;
};

const getCognitoPoolID = (stage, region) => {
  stage = stage.toLowerCase();
  let poolID = "";
  if (region === "ap-northeast-2") {
    if (stage === "op") {
      poolID = "ap-northeast-2:b9898a1c-2381-426c-9245-289322258ed9";
    } else if (stage === "st" || stage === "qa") {
      poolID = "ap-northeast-2:63052247-b327-410e-b5f6-4887298c228d";
    }
  } else if (region === "us-west-2") {
    if (stage === "op") {
      poolID = "us-west-2:4578e662-5d56-469d-bb11-bd274870e055";
    } else if (stage === "st" || stage === "qa") {
      poolID = "us-west-2:a4e65df2-f862-401e-addd-f049b66561aa";
    }
  }
  return poolID;
};

const getLiveChatHistoryS3Name = (stage, countryCode) => {
  stage = stage.toLowerCase();
  countryCode = countryCode.toLowerCase();
  let s3name = "";

  if (countryCode === "kr") {
    if (stage === "op") {
      s3name = "thinq-chatbot-livechat-history-op-kr";
    } else if (stage === "qa" || stage === "st") {
      s3name = "thinq-chatbot-livechat-history-qa-kr";
    }
  } else if (countryCode === "us") {
    if (stage === "op") {
      s3name = "thinq-chatbot-livechat-history-op-us";
    } else if (stage === "qa" || stage === "st") {
      s3name = "thinq-chatbot-livechat-history-qa-us";
    }
  }
  return s3name;
};

const uploadHistoryHtmlFileToS3 = async (
  sessionId,
  stage,
  countryCode,
  htmlText
) => {
  const newFileName = sessionId + ".html";
  const region = getRegion(countryCode);
  const htmlBlob = makeHtmlBlob(htmlText, newFileName);
  const poolID = getCognitoPoolID(stage, region);
  const s3BucketName = getLiveChatHistoryS3Name(stage, countryCode);

  AWS.config.update({
    region: region,
    credentials: new AWS.CognitoIdentityCredentials({
      IdentityPoolId: poolID,
    }),
  });

  const S3 = new AWS.S3({ apiVersion: "2006-03-01" });

  const S3UploadParams = {
    Bucket: s3BucketName,
    Key: "history_html/" + newFileName,
    ContentType: htmlBlob.type,
    Body: htmlBlob,
    ACL: "private",
  };

  return new Promise((resolve, reject) => {
    S3.putObject(S3UploadParams, (err, data) => {
      if (!data) {
        console.log("Upload Livechat history data Failed");
        reject(err);
      } else {
        console.log("Upload Livechat history data Success");
        resolve(data);
      }
    });
  });
};

export { uploadHistoryHtmlFileToS3 };
