import React, { useContext, useState } from "react";
import { Base64 } from 'js-base64';

import { withStyles, Button, ButtonGroup, Box, Divider, Container } from "@material-ui/core";
import DataContext from "../../data/DataContext";
import ReserveTitleImg from "../../../asset/img/title_reservation.jpg";

import { openLink } from "../../util/utilNative"


const styles = theme => ({
  root: {
    display: "flex",
    letterSpacing: "-0.5px",
  },
  item: {
    height: '37px',
    textAlign: 'center',
    color: theme.color.select.color,
    fontSize: theme.font.size14,
    fontWeight: 'bold',
    padding: '0px',
    flex: 1
  },
  vertical: {
    height: 'auto'
  },
  divider: {
    margin: '5px'
  },
  list: {
    display: 'flex',
    padding: '10px'
  },
  imgBox: {
    display:"flex" ,
    alignItems:"center",
    justifyContent:"center"
  },
  img: {
    width: '100%',
    height: '100%'
  },
  infoTitle: {
    fontSize: theme.font.size15, // "15px",
    color: '#333',
    textAlign: 'left'
  },
  infoDescription: {
    fontSize: theme.font.size13,
    color: '#555',
    textAlign: 'center'
  },
  date: {
    color: theme.color.message.date,
    fontSize: theme.font.size11,
    alignSelf: "flex-end",
    margin: "5px 0 6px 0",
    textAlign: "center"
  },
  label: {
    width:'auto',
    height: '30px',
    fontSize: theme.font.size10,
    color: '#707070',
    textAlign: 'start'
  },
  value: {
    fontSize: theme.font.size13,
    color: '#333',
    textAlign: 'start'
  },
  leftContainer: {
    justifyContent: "flex-start"
  },
  rightContainer: {
    justifyContent: "flex-end"
  },
  message: {
    fontSize: theme.font.size13_5,
    borderRadius: "6px",
    flexWrap: "wrap",
    color: theme.color.message.color,
    padding: "10px",
    maxWidth: "75%"
  },
  leftMessage: {
    backgroundColor: "white",
    margin: "6px 10px",
    boxShadow: "0px 1px 11px rgba(0, 0, 0, 0.1)"
  },
  rightMessage: {
    backgroundColor: theme.color.message.background,
    margin: "8px 10px",
    boxShadow: "none"
  },
  leftTail: {
    borderLeft: '14px solid transparent',
    borderRight: '14px solid transparent',
    borderTop: '18px solid #fff',
    borderRadius: '5px',
    zIndex: 10,
    margin: '6px -28px 0px 10px',
  },
});

function getParamObj(context){
  const currentTime = Math.floor(Date.now()/1000);
  let paramobj = 
  {
    "client_id":context.sessionId,
    "request_type":"engineer",
    "svc_type":"A",
    "product_group_code":"GR_AC",
    "product_group_name":"에어컨",
    "product_code":"PAC",
    "product_name":"스탠드형 에어컨",
    "rcpt_symptom_code":"PACST000442",
    "sub_symptom_code":"SS000003",
    "premium":"N",
    "timestamp":currentTime,
    "device_type":"mobile",
    "privacy_consent":"Y",
    "terms_type":"A_SVC",
    "terms_id":"termsVersion",
    "apply_start_date":"20200504",
    "user_name":"홍길동",
    "phone_number":"01077420935",
    "access_token":context.channelParams.access_token
  }
  return paramobj;
}

function encodeParam(paramObj){
  let paramStr = JSON.stringify(paramObj);
  const paramBase64 = Base64.encodeURI(paramStr);
  return paramBase64;
}

function RESERVE(props) {
  const { classes, data } = props;
  const context = useContext(DataContext);
  const reservationUrl = '/ext/reservation/services/request.html'

  const handleClick = () => {
    const paramObj = getParamObj(context);
    const paramBase64 = encodeParam(paramObj)
    const targetUrl = window.location.origin+reservationUrl+"?channel=thinq&channel_parameters="+paramBase64;
    console.log(targetUrl);
    openLink(context, 'inapp', targetUrl);
  }
  return (
    <div
      className={[
        classes.root,
        data.mine ? classes.rightContainer : classes.leftContainer
      ].join(" ")}
    >
      {(!data.loading && data.mine) && <p className={classes.date}>{data.date}</p>}
      {!data.mine && <div className={classes.leftTail}/>}
      <div
        className={[
          classes.message,
          data.mine ? classes.rightMessage : classes.leftMessage
        ].join(" ")}
      >
        <Box className={classes.imgBox}>
          <img className={classes.img} src={ReserveTitleImg} alt='title'/>
        </Box>
        <Container>
          <h2>{data.title}</h2>
          <h5>{data.text}</h5>
        </Container>
        {!data.loading &&
          <div>
          <Divider className={classes.divider} component={"div"} disabled/>
            <ButtonGroup
              fullWidth
              orientation="vertical">
              {!data.loading && data.buttons.map(button => (
                <Button 
                  type="button"
                  key={button.title} 
                  disabled={context.conversationEnd}
                  fullWidth  
                  onClick={() => handleClick(button)}>
                    {button.title}
                </Button>
              ))}
            </ButtonGroup>
          </div>
        }
      </div>
      {(!data.loading && !data.mine) && <p className={classes.date}>{data.date}</p>}
    </div>
  )
}
export default withStyles(styles)(RESERVE);
