import React, { useContext, Fragment } from "react";
import { withStyles, Divider } from "@material-ui/core";
import DataContext from "../../data/DataContext";
import { openLink } from "../../util/utilNative";
import LeftTail from "../../../asset/img/bubble_point1.png";
import Arrow from "../../../asset/img/ic_list_arrow_nor.png";
import TypingData from "../../../asset/img/img_text_loading.json";
import Lottie from "react-lottie";
import { useLiveChat } from "../../data/LiveChat";

const styles = (theme) => ({
  root: {
    display: "flex",
    letterSpacing: "-0.5px",
  },
  leftContainer: {
    justifyContent: "flex-start",
  },
  rightContainer: {
    justifyContent: "flex-end",
  },
  message: {
    fontSize: theme.font.size15, // "15px",
    borderRadius: "12px",
    flexWrap: "wrap",
    color: theme.color.message.color,
    maxWidth: "75%",
    zIndex: 10,
    wordWrap: "break-word",
  },
  leftMessage: {
    backgroundColor: "white",
    margin: "6px 6px 6px 9px",
    boxShadow: "0px 12px 12px rgba(194, 194, 194, 0.58)",
    fontWeight: "300",
  },
  rightMessage: {
    backgroundColor: theme.color.message.background,
    margin: "8px 10px",
    boxShadow: "none",
  },
  text: {
    padding: "9px 20px",
  },
  date: {
    color: theme.color.message.date,
    fontSize: theme.font.size11,
    fontWeight: "300",
    alignSelf: "flex-end",
    margin: "5px 0 6px 0",
    textAlign: "center"
  },
  typing: {
    width: "30px",
    height: "6px",
  },
  list: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  item: {
    minHeight: "2.5rem",
    textAlign: "center",
    color: theme.color.accent,
    fontSize: theme.font.size14,
    fontWeight: "bold",
    padding: "0px",
    margin: "0px",
    flex: 1,
    backgroundColor: "#FAFAFA",
    textTransform: "none",
    outline: "0 none",
    border: "0px",
    borderRadius: "0px 0px 12px 12px",
    "&:active": {
      backgroundColor: "rgba(38, 38, 38, 0.1)",
    },
  },
  vertical: {
    height: "auto",
  },
  leftTail: {
    // borderLeft: '14px solid transparent',
    // borderRight: '14px solid transparent',
    // borderTop: '18px solid #fff',
    // borderRadius: '5px',
    zIndex: 11,
    margin: "6px -21px 0px 11px",
    backgroundImage: `url(${LeftTail})`,
    backgroundSize: "21px 12px",
    width: "21px",
    height: "12px",
    minWidth: "21px"
  },
  buttonText: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "100%",
    minHeight: "2.5rem",
    color: "#262626",
    padding: "0px 20px",
    // fontSize: theme.font.size14,
    textAlign: "start"
  },
  arrow: {
    width: "1.8em",
    height: "1.8em",
    backgroundSize: "contain",
    backgroundImage: `url(${Arrow})`,
    padding: ".5em",
  },
});

const lottieTypingOptions = {
  loop: true,
  autoplay: true,
  animationData: TypingData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

function TL(props) {
  const { classes, data } = props;
  const context = useContext(DataContext);
  const { livechatApi } = useLiveChat();
  const handleClick = (link) => {
    var type = link.type;
    var url = link.url;
    if (type.toLowerCase() === "faq") {
      type = "inapp";
      url = `${window.location.href}ext/faq/faq.html?url=${url}`;
      openLink(context, type, url, link.event_action);
    }
    else if (type.toLowerCase() === "endconv") {
      livechatApi.openRating();
    }
    else {
      openLink(context, type, url, link.event_action);
    }
  };

  return (
    <>
      <div
        className={[
          classes.root,
          data.mine ? classes.rightContainer : classes.leftContainer,
        ].join(" ")}
      >
        {!data.loading && data.mine && (
          <p className={classes.date}>{data.date}</p>
        )}
        {!data.mine && <div className={classes.leftTail} />}
        <div
          className={[
            classes.message,
            data.mine ? classes.rightMessage : classes.leftMessage,
          ].join(" ")}
        >
          {data.loading && (
            <Lottie
              options={lottieTypingOptions}
              className={classes.messageLoading}
              width={50}
              height={22}
            />
          )}

          <div className={classes.text}>
            {!data.loading &&
              data.text.split("\n").map((content, index) => (
                <Fragment key={index}>
                  {content}
                  <br />
                </Fragment>
              ))}
          </div>

          <Divider component={"div"} disabled />
          <div className={classes.list}>
            {!data.loading &&
              data.link &&
              data.link.map((link, index) => (
                <>
                  {index !== 0 && <Divider component={"div"} disabled />}
                  <button
                    type="button"
                    className={classes.item}
                    disabled={context.conversationEnd}
                    onClick={() => handleClick(link)}
                    aria-label={`${
                      link.type.toLowerCase() === "external"
                        ? context.string.aria.external + ","
                        : ""
                    } ${link.label}`}
                  >
                    <div className={classes.buttonText}>
                      {link.label}
                      <div className={classes.arrow} />
                    </div>
                  </button>
                </>
              ))}
          </div>
        </div>
        {!data.loading && !data.mine && (
          <p className={classes.date}>{data.date}</p>
        )}
      </div>
    </>
  );
}
export default withStyles(styles)(TL);
