import React, { useContext, Fragment } from "react";
import DataContext from "../../data/DataContext";
import { withStyles } from "@material-ui/core";
import Carousel from "../../carousel/Carousel";

import IconControl from "../../../asset/img/img_cm_control.png";
import IconEvent from "../../../asset/img/img_cm_event.png";
import IconInstall from "../../../asset/img/img_cm_installation.png";
import IconLocation from "../../../asset/img/img_cm_location.png";
import IconPurchase from "../../../asset/img/img_cm_purchase.png";
import IconReservation from "../../../asset/img/img_cm_reservation.png";
import IconSearch from "../../../asset/img/img_cm_search.png";
import IconSolution from "../../../asset/img/img_cm_solution_method.png";
import IconCleaning from "../../../asset/img/ic_shortcut_homecare.png";
import IconStory from "../../../asset/img/ic_cm_story.svg";

const helpTypeIconMap = {
  helpSelfRepair: IconSolution,
  helpReserveEng: IconControl,
  helpFindProduct: IconSearch,
  helpBuyConsumables: IconPurchase,
  helpReserveInstall: IconInstall,
  helpCheckReserve: IconReservation,
  helpFindCenter: IconLocation,
  helpEvent: IconEvent,
  helpDeviceControl: IconControl,
  helpCleaningService: IconCleaning,
  helpStory: IconStory
};

const styles = (theme) => ({
  root: {
    position: "relative",
    letterSpacing: "-0.5px",
    margin: "6px 0px",
    zIndex: 10,
  },
  carousel: {
    width: "100%",
    height: "144px",
  },
  listitem: {
    width: "205px",
    padding: "0px 8px 0px 0px",
  },
  card: {
    width: "200px",
    marginRight: "5px",
    display: "flex",
    flexDirection: "column",
    height: "100%"
  },
  content: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flexGrow: 1,
    textAlign: "center",
    background: "#fff",
    border: "1px solid #e1e1e1",
    borderRadius: "12px 12px 0px 0px",
    padding: "0px 20px",
  },
  thumbnail: {
    width: "5.625rem",
    height: "4.375rem",
    margin: "15px 20px 12px 20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  thumbnailImage: {
    width: "5.625rem",
    height: "4.375rem",
  },
  info: {
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
    margin: "12px 0px",
    overflow: "hidden",
  },
  infoDescription: {
    fontSize: theme.font.size13,
    fontWeight: "300",
    color: "#262626",
    textAlign: "start",
    overflow: "hidden",
  },
  sampleUtterances: {
    margin: "0 0 10px 0",
    color: "#262626",
    fontSize: theme.font.size12,
    fontWeight: "bold",
  },
  externalButton: {
    width: "100%",
    minHeight: "3.5rem",
    maxHeight: "4.5rem",
    color: theme.palette.primary.main, // "#00a0b6",
    fontWeight: "bold",
    fontSize: theme.font.size14,
    border: "1px solid #e1e1e1",
    borderRadius: "0px 0px 12px 12px",
    borderTop: "none",
    padding: 0,
    background: "#fafafa",
    "&:active": {
      background: "rgba(38, 38, 38, 0.1)",
    },
    outline: "0 none",
    // whiteSpace: "normal",
    lineHeight: 1,
    margin: 0
  },
  externalDivider: {
    width: "100%",
    height: "1px",
    background: "#e1e1e1",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "38px",
  },
  buttonText: {},
});

const Card = React.forwardRef((props, ref) => {
  const { classes, data } = props;
  const context = useContext(DataContext);

  const handleClick = (helpSpeech) => {
    if (ref.clickAllowed()) {
      context.request(context, helpSpeech, "button");
    }
  };
  return data.list_items.map((item, index) => (
    <div className={classes.listitem} key={`helpcard-${index}`}>
      <div className={classes.card} role={"button"}>
        <div className={classes.content}
          tabIndex={0}
          aria-label={`${item.title}, ${item.description}, ${item.sample_utterances}, ${index + 1} / ${data.list_items.length}`}>
          <div className={classes.thumbnail}>
            <div
              // className={classes.thumbnailImage}
              style={{
                minWidth: "90px",
                height: "70px",
                backgroundSize: "90px 70px",
                backgroundImage: `url(${helpTypeIconMap[item.thumbnail_code]})`,
              }}
            />
          </div>
          <div className={classes.info}>
            <div className={classes.infoDescription}>
              {item.description.split("\n").map((value, key) => {
                return (
                  <Fragment key={key}>
                    {value}
                    <br />
                  </Fragment>
                );
              })}
            </div>
          </div>
          <div className={classes.sampleUtterances}>
            {item.sample_utterances.map((value, key) => {
              return (
                <Fragment key={key}>
                  {value}
                  <br />
                </Fragment>
              );
            })}
          </div>
        </div>

        {/* <Divider orientation={"horizontal"} className={classes.externalDivider}/> */}
        {item.buttons.map((button, index) => (
          <button
            type="button"
            className={classes.externalButton}
            disabled={context.conversationEnd}
            key={index}
            onClick={() => handleClick(button.speech)}
          >
            {button.label}
          </button>
        ))}
      </div>
    </div>
  ));
});

function HELPCARD(props) {
  const { classes, data } = props;
  const context = useContext(DataContext);

  return (
    <>
      <div className={classes.root}>
        <Carousel
          className={classes.carousel}
          component={withStyles(styles)(Card)}
          data={data}
          ariaLabel={context.string.aria.help}
        />
      </div>
    </>
  );
}
export default withStyles(styles)(HELPCARD);
