import moment from 'moment';
import base62 from 'uuid-base62';

/**
 * 서버에서 전달된 메시지를 UI에 맞도록 변경한다.
 * @param {Server Data Format} server 
 */
const makeTXMessage = (isMine, text, loading = false, sentiment = null) => {
  return {
    id: base62.v4(),
    type: 'tx',
    template_type: "tx",
    mine: isMine,
    text: text,
    date: moment().format('LT'),
    loading: loading,
    online: true,
    sentiment: sentiment
  }
}

const makeSLMessage = (isMine, text, list_items) => {
  return {
    id: base62.v4(),
    type: 'sl',
    mine: isMine,
    text: text,
    date: moment().format('LT'),
    list_items: list_items
  }
}

const makeTLMessage = (isMine, text, link) => {
  return {
    id: base62.v4(),
    type: 'tl',
    mine: isMine,
    text: text,
    date: moment().format('LT'),
    link: link
  }
}

const makeSGMessage = (isMine, text, buttons, isGrid) => {
  return {
    type: "SG",
    mine: isMine,
    text: text,
    date: moment().format('LT'),
    buttons: buttons.map(button => {
      return { label: button.speech }
    }),
    isWelcome: isGrid
  }
}

const makeBCMesage = (isMine, text, title, image, buttons) => {
  return {
    type: "BC",
    mine: isMine,
    date: moment().format('LT'),
    title: title,
    text: text,
    image: {
      url: image,
      accessibility_text: title
    },
    buttons: buttons
  }
}

const makeTypingMessage = () => {
  return {
    type: "typing",
    template_type: "typing"
  }
}

const convertMessage = (messages, isWelcome = false) => {
  return messages.map(message => {
    let tempMessage = {};
    switch (message.template_type.toUpperCase()) {
      case "TX":
        tempMessage = makeTXMessage(false, message.text);
        break;
      case "TL":
        tempMessage = {
          type: message.template_type,
          mine: false,
          text: message.text,
          date: moment().format('LT'),
          link: message.link
        }
        break;
      case "CB":
        tempMessage = {
          type: message.template_type,
          subType: message.template_sub_type,
          mine: false,
          text: message.text,
          date: moment().format('LT'),
          buttons: message.buttons,
          listItems: message.list_items,
          title: message.title
        }
        break;
      case "GRID":
        tempMessage = {
          type: "Grid",
          mine: false,
          text: message.text,
          date: moment().format('LT'),
          buttons: message.buttons
        }
        break;
      case "SG": 
        tempMessage = {
          type: "SG",
          mine: false,
          text: message.text,
          date: moment().format('LT'),
          buttons: message.buttons.map(button => {
            return { label: button.speech }
          }),
          isWelcome: message.template_type.toUpperCase() === "GRID" ? true : isWelcome
        }
        break;
      case "SL":
        tempMessage = makeSLMessage(false, message.text, message.list_items);
        break;
      case "BC":
        tempMessage = {
          type: message.template_type,
          mine: false,
          date: moment().format('LT'),
          title: message.title,
          text: message.text,
          image: {
            url: message.image.url,
            accessibility_text: message.image.accessibility_text
          },
          buttons: message.buttons
        }
        break;
      case "LO":
        tempMessage = {
          type: message.template_type,
          template_type: message.template_type,
          mine: false,
          date: moment().format("LT"),
          template_sub_type: message.template_sub_type,
          items: message.list_items,
        }
        break;
      case "CARD":
        tempMessage = {
          type: message.template_sub_type,
          mine: false,
          date: moment().format("LT"),
          template_sub_type: message.template_sub_type,
          items: message.items,
        }
        break;
      case "CAROUSEL":
        if (message.template_sub_type.toLowerCase() === 'helpcard'){
          tempMessage = {
            type: message.template_sub_type,
            list_items: message.items
          }
        }
        else if (message.template_sub_type.toLowerCase() === 'contentlist'){
          tempMessage = {
            type: 'CL',
            mine: false,
            date: moment().format('LT'),
            list_items: message.list_items,
            ellipsis: message.ellipsis
          }
        }
        else if (message.template_sub_type.toLowerCase() === 'productcard'){
          tempMessage = {
            type: 'ProductCard',
            mine: false,
            date: moment().format('LT'),
            items: message.items,
            product_group_link_url: message.product_group_link_url,
            more_items: message.more_items
          }
        }
        else if (message.template_sub_type.toLowerCase() === 'eventcard') {
          tempMessage = {
            type: 'EventCard',
            mine: false,
            date: moment().format('LT'),
            items: message.items
          }
        }
        else if (message.template_sub_type.toLowerCase() === 'carecard') {
          tempMessage = {
            type: 'CareCard',
            mine: false,
            date: moment().format('LT'),
            items: message.items
          }
        }
        else if (message.template_sub_type.toLowerCase() === 'ticard') {
          tempMessage = {
            type: 'TICard',
            mine: false,
            date: moment().format('LT'),
            items: message.items
          }
        }
        else if (message.template_sub_type.toLowerCase() === 'selectcardlist') {
          tempMessage = {
            type: 'SelectCardList',
            mine: false,
            date: moment().format('LT'),
            items: message.list_items
          }
        }
        else if (message.template_sub_type.toLowerCase() === 'storycard') {
          tempMessage = {
            type: 'StoryCard',
            mine: false,
            date: moment().format('LT'),
            items: message.items
          }
        }
        break;
      case "ACTION":
        tempMessage.type = 'ACTION';
        tempMessage.subType = message.action.code;
        tempMessage.action = message.action;
        tempMessage.reaction = message.action.type.toLowerCase() === 'request_response';
        tempMessage.mine = false;
        tempMessage.date = moment().format('LT');
        tempMessage.parameters = message.parameters;
        break;
      default: break;
    }
    tempMessage.id = base62.v4();
    return tempMessage;
  });
}

const convertDebugMessage = (messages) => {

  if (Array.isArray(messages)) {
    return messages.map(message => {
      let tempMessage = makeTXMessage(false, message);
      tempMessage.type = 'debug';
      tempMessage.text = message;
      return tempMessage;
    });
  } else {
    let tempMessage = makeTXMessage(false, messages);
    tempMessage.type = 'debug';
    tempMessage.text = messages;
    return [tempMessage];
  }
}

export  { makeTXMessage, makeSLMessage, makeTLMessage, makeSGMessage, makeBCMesage, convertMessage, convertDebugMessage, makeTypingMessage };