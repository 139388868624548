import React, { useContext, useState, Fragment } from "react";
import { withStyles, Divider } from "@material-ui/core";
import DataContext from "../../data/DataContext";
import more from "../../../asset/img/ic_more.png";
import moreDisabled from "../../../asset/img/arr_d_3.png";
import LeftTail from "../../../asset/img/bubble_point1.png";
import TypingData from "../../../asset/img/img_text_loading.json";
import Lottie from "react-lottie";

const styles = theme => ({
  root: {
    display: "flex",
    letterSpacing: "-0.5px",
  },
  leftContainer: {
    justifyContent: "flex-start"
  },
  rightContainer: {
    justifyContent: "flex-end"
  },
  message: {
    fontSize: theme.font.size15, // "15px",
    borderRadius: "12px",
    flexWrap: "wrap",
    color: theme.color.message.color,
    maxWidth: "75%",
    zIndex: 10,
    wordWrap: "break-word",
  },
  leftMessage: {
    backgroundColor: "white",
    margin: "6px 6px 6px 9px",
    boxShadow: "0px 12px 12px rgba(194, 194, 194, 0.58)",
    fontWeight: "300",
  },
  rightMessage: {
    backgroundColor: theme.color.message.background,
    margin: "8px 10px",
    boxShadow: "none"
  },
  text: {
    padding: "9px 20px",
  },
  date: {
    color: theme.color.message.date,
    fontSize: theme.font.size11,
    fontWeight: "300",
    alignSelf: "flex-end",
    margin: "5px 0 6px 0",
    textAlign: "center"
  },
  typing: {
    width: '30px',
    height: '6px'
  },
  list: {
    alignContent: 'center'
  },
  item: {
    width: "100%",
    minHeight: "40px",
    textAlign: "center",
    color: theme.color.text,
    fontSize: theme.font.size14,
    fontWeight: "bold",
    padding: "4px 8px",
    margin: "0px",
    flex: 1,
    backgroundColor: "#FAFAFA",
    textTransform: "none",
    outline: "0 none",
    border: "0px",
    whiteSpace: "pre-wrap",
    overflow: "hidden",
    "&:active": {
      backgroundColor: "rgba(38, 38, 38, 0.1)",
    },
  },
  itemLast: {
    borderRadius: "0px 0px 12px 12px",
  },
  itemDisabled: {
    color: "rgba(38, 38, 38, 0.4)"
  },
  leftTail: {
    // borderLeft: '14px solid transparent',
    // borderRight: '14px solid transparent',
    // borderTop: '18px solid #fff',
    // borderRadius: '5px',
    zIndex: 11,
    margin: "6px -21px 0px 11px",
    backgroundImage: `url(${LeftTail})`,
    backgroundSize: "21px 12px",
    width: "21px",
    height: "12px",
    minWidth: "21px"
  },
  btnMore: {
    width: "auto",
    height: "16px"
  }
});

const lottieTypingOptions = {
  loop: true,
  autoplay: true,
  animationData: TypingData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

function SL(props) {
  const { classes, data } = props;
  const [ showMore, setShowMore ] = useState(false);

  const context = useContext(DataContext);

  const handleClick = async button => {
    if (button.frontActionFunc){
      await button.frontActionFunc(button);
      console.log("access_token is refreshed");
      return;
    }
    console.log("request restart sl")
    context.request(context, button.speech, "button")
  }

  const handleMore = () => {
    setShowMore(true);
    context.scroll(true);
  }

  const maxItem = 10;
  const list = data.list_items && data.list_items.filter((_, index) => index < maxItem);
  const moreList = data.list_items && data.list_items.length > maxItem && data.list_items.filter((_, index) => index >= maxItem);

  return (
    <>
      <div
        className={[
          classes.root,
          data.mine ? classes.rightContainer : classes.leftContainer
        ].join(" ")}
      >
        {(!data.loading && data.mine) && <p className={classes.date}>{data.date}</p>}
        {!data.mine && <div className={classes.leftTail}/>}
        <div
          className={[
            classes.message,
            data.mine ? classes.rightMessage : classes.leftMessage
          ].join(" ")}
        >
          {data.loading && (
            <Lottie
              options={lottieTypingOptions}
              className={classes.messageLoading}
              width={50}
              height={22}
            />
          )}

          <div className={classes.text}>
          {!data.loading && data.text.split("\n").map((content, index) => (
            <Fragment key={index}>
              {content}
              <br/>
            </Fragment>
          ))}
          </div>
          
          <div className={classes.list}>
            {!data.loading && list && list.map((item, index) => (
              <Fragment key={index}>
                <Divider component={"div"} disabled/>
                <button
                  type="button"
                  className={[classes.item, (index === list.length - 1 && !showMore) ? classes.itemLast : "", !data.isLast && classes.itemDisabled].join(" ")}
                  disabled={!data.isLast} 
                  onClick={() => handleClick(item)}
                  aria-label={`${item.label}, ${index + 1} / ${moreList ? list.length + moreList.length : list.length}`}>
                    {item.label}
                </button>
              </Fragment>
            ))}
            {!data.loading && !showMore && moreList && data.list_items.length > maxItem && (
              <Fragment key={"btnMore"}>
                <Divider component={"div"} disabled/>
                <button
                  type="button"
                  className={[classes.item, classes.itemLast, !data.isLast && classes.itemDisabled].join(" ")}
                  disabled={!data.isLast} 
                  onClick={handleMore}>
                    <img className={classes.btnMore} src={data.isLast ? more : moreDisabled} alt="더보기"/>
                </button>
              </Fragment>
            )}
            {!data.loading && showMore && data.list_items.length > maxItem && moreList && moreList.map((item, index) => (
              <Fragment key={index}>
                <Divider component={"div"} disabled/>
                <button
                  type="button"
                  className={[classes.item, index === moreList.length - 1 && classes.itemLast, !data.isLast && classes.itemDisabled].join(" ")}
                  disabled={!data.isLast} 
                  onClick={() => handleClick(item)}
                  aria-label={`${item.label}, ${index + 1} / ${moreList.length + list.length}`}>
                    {item.label}
                </button>
              </Fragment>
            ))}
          </div>
        
        </div>
        {(!data.loading && !data.mine) && <p className={classes.date}>{data.date}</p>}
      </div>
    </>
  );
}
export default withStyles(styles)(SL);
