import React, { useContext, useState, Fragment } from "react";
import { withStyles, Button, Box, Divider, Container } from "@material-ui/core";
import { openLink } from "../../util/utilNative";
import DataContext from "../../data/DataContext";
import { uploadHistoryHtmlFileToS3 } from "../../util/utilAWS";
import { useLiveChat } from "../../data/LiveChat";
import Arrow from "../../../asset/img/ic_list_arrow_nor.png";

const styles = (theme) => ({
  root: {
    display: "flex",
    letterSpacing: "-0.5px",
  },
  item: {
    height: "100%",
    minHeight: "2.5rem",
    textAlign: "center",
    color: theme.color.text,
    fontWeight: "bold",
    padding: "0px",
    flex: 1,
    backgroundColor: "#fafafa",
    borderRadius: "0px 0px 12px 12px",
    textTransform: "none",
    fontSize: theme.font.size14,
  },
  vertical: {
    height: "auto",
  },
  divider: {
    margin: "5px",
  },
  list: {
    display: "flex",
    width: "100%",
  },
  imgBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
  },
  img: {
    width: "100%",
    height: "100%",
  },
  infoTitle: {
    fontSize: theme.font.size15, // "15px",
    color: "#333",
    textAlign: "left",
  },
  infoDescription: {
    fontSize: theme.font.size13,
    color: "#555",
    textAlign: "center",
  },
  date: {
    color: theme.color.message.date,
    fontSize: theme.font.size11,
    alignSelf: "flex-end",
    margin: "5px 0 6px 0",
    textAlign: "center"
  },
  label: {
    width: "auto",
    height: "30px",
    fontSize: theme.font.size10,
    color: "#707070",
    textAlign: "start",
  },
  value: {
    fontSize: theme.font.size13,
    color: "#333",
    textAlign: "start",
  },
  leftContainer: {
    justifyContent: "flex-start",
  },
  rightContainer: {
    justifyContent: "flex-end",
  },
  message: {
    fontSize: theme.font.size13_5,
    borderRadius: "12px",
    flexWrap: "wrap",
    color: theme.color.message.color,
    maxWidth: "75%",
  },
  leftMessage: {
    backgroundColor: "white",
    margin: "6px 10px",
    boxShadow: "0px 1px 11px rgba(0, 0, 0, 0.1)",
  },
  rightMessage: {
    backgroundColor: theme.color.message.background,
    margin: "8px 10px",
    boxShadow: "none",
  },
  leftTail: {
    borderLeft: "14px solid transparent",
    borderRight: "14px solid transparent",
    borderTop: "18px solid #fff",
    borderRadius: "5px",
    zIndex: 10,
    margin: "6px -28px 0px 10px",
  },
  buttonText: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    color: theme.color.text,
    padding: "10px 20px",
    height: "100%",
    minHeight: "2.5rem",
    "&:active": {
      color: "rgba(38, 38, 38, 0.1)",
    },
  },
  disabledButton: {
    color: "rgba(38, 38, 38, 0.4)",
  },
  descBox: {
    marginBottom: "12px",
  },
  arrow: {
    width: "1.8em",
    height: "1.8em",
    backgroundSize: "contain",
    backgroundImage: `url(${Arrow})`,
    padding: ".5em",
  },
});

function BC(props) {
  const { classes, data } = props;
  const context = useContext(DataContext);
  const [enabled, setEnabled] = useState(true);
  const { livechatApi } = useLiveChat();

  const handleClick = (button) => {
    setEnabled(false);
    setTimeout(() => {
      setEnabled(true);
      console.log("enable");
    }, 3000);

    if (button.event_action && button.event_action.event_type === "livechat") {
      context.setShowOldConversations(true);

      let start_intent = button.event_action.context?button.event_action.context.start_intent:null;
      let branch = "cs";
      switch(start_intent){
        case "THQ_CUSTOMER_SERVICE_CHAT":
          branch="cs";
          break;
        case "THQ_PURCHASE_EXPERT_CHAT":
          branch="mkt";
          break;
        default:
          branch="cs";
          break;
      }
      if (!branch) throw new Error("No branch info exist from DM");
      console.log(`setLivechatBranch: ${branch}`);
      context.setLivechatBranch(branch);

      setTimeout(async () => {
        const htmlText = document.documentElement.innerHTML;
        uploadHistoryHtmlFileToS3(
          context.sessionId,
          context.stage,
          context.countryCode,
          htmlText
        )
          .then(async (s3Res) => {
            // const extRes = await extLiveChatHistory(
            //   context.countryCode,
            //   context.sessionId
            // );
            // console.log(
            //   "[LiveChat] S3 Upload Success",
            //   "s3Res:",
            //   s3Res,
            //   "extRes:",
            //   extRes
            // );

            // const url = new URL(button.url);
            // if (extRes && extRes.result && extRes.result.signed_url) {
            //   url.searchParams.set("chatBotUrl", extRes.result.signed_url);
            // }
            // openLink(context, button.type, url.toString(), button.event_action);

            // openLink(context, button.type, button.url, button.event_action);

            // TODO LiveChat으로 연결하기전에 S3 업로드가 필요
            livechatApi.login(context.sessionId, branch);
            // context.request(context, button.title, 'button');
          })
          .catch((error) => {
            console.log("[LiveChat] S3 Upload Failed", error);
            // openLink(context, button.type, button.url, button.event_action);
            livechatApi.login(context.sessionId, branch);
          });
      }, 300);
    }
    // 1:1 문의에도 S3업로드를 추가
    else if (button.event_action && button.event_action.event_type === "qna") {
      context.setShowOldConversations(true);
      const htmlText = document.documentElement.innerHTML;
      uploadHistoryHtmlFileToS3(
        context.sessionId,
        context.stage,
        context.countryCode,
        htmlText
      );
      openLink(context, button.type, button.url, button.event_action);
    } else {
      openLink(context, button.type, button.url, button.event_action);
    }
  };
  return (
    <div
      className={[
        classes.root,
        data.mine ? classes.rightContainer : classes.leftContainer,
      ].join(" ")}
    >
      {!data.loading && data.mine && (
        <p className={classes.date}>{data.date}</p>
      )}
      {!data.mine && <div className={classes.leftTail} />}
      <div
        className={[
          classes.message,
          data.mine ? classes.rightMessage : classes.leftMessage,
        ].join(" ")}
      >
        <Box className={classes.imgBox}>
          <img
            className={classes.img}
            src={data.image["url"]}
            alt={data.image["accessibility_text"]}
          />
        </Box>
        <Container className={classes.descBox}>
          {data.title && <h2>{data.title}</h2>}
          {data.text.split("\n").map((value, key) => {
            return (
              <Fragment key={key}>
                {value}
                <br />
              </Fragment>
            );
          })}
        </Container>
        <Divider component={"div"} disabled />
        <div className={classes.list}>
          {!data.loading &&
            data.buttons.map((button, index) => (
              <Fragment key={index}>
                <Divider
                  className={classes.vertical}
                  orientation={"vertical"}
                  hidden={index === 0}
                  component={"div"}
                  disabled
                />
                <Button
                  type="button"
                  className={[
                    classes.item,
                    context.conversationEnd && classes.disabledButton,
                  ].join(" ")}
                  disabled={context.conversationEnd}
                  onClick={() => handleClick(button)}
                  aria-label={`${button.title} ${
                    button.type.toLowerCase() === "external"
                      ? context.string.aria.external + ","
                      : ""
                  }`}
                >
                  <div
                    className={[
                      classes.buttonText,
                      context.conversationEnd || !enabled
                        ? classes.disabledButton
                        : "",
                    ].join(" ")}
                  >
                    {button.title}
                    <div className={classes.arrow} />
                  </div>
                </Button>
              </Fragment>
            ))}
        </div>
      </div>
      {!data.loading && !data.mine && (
        <p className={classes.date}>{data.date}</p>
      )}
    </div>
  );
}
export default withStyles(styles)(BC);
