import React, { useContext, useState } from "react";
import { withStyles, Button, Divider, Grid } from "@material-ui/core";
import { goDial, openLink } from "../../util/utilNative";

import Reservation from "../../../asset/img/btn_reserve.png";
import Navigation from "../../../asset/img/btn_find_way.png";
import iconSMS from "../../../asset/img/btn_sms.png";

import IconAddress from "../../../asset/img/ic_address.png";
import IconTime from "../../../asset/img/ic_time.png";
import IconPhone from "../../../asset/img/ic_call.png";
import IconParking from "../../../asset/img/ic_parking.png";

import NaverMaps from "../../map/NaverMaps";
import GoogleMaps from "../../map/GoogleMaps";
import Carousel from "../../carousel/Carousel";
import DataContext from "../../data/DataContext";
import SMS from "../../dialog/SMS.js";

const styles = (theme) => ({
  root: {
    disply: "flex",
    position: "relative",
    letterSpacing: "-0.5px",
    margin: "6px 0px",
    zIndex: 10,
  },
  carousel: {
    width: "100%",
    height: "144px",
  },
  listitem: {
    width: "308px",
    padding: "0px 8px 0px 0px",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    width: "300px",
    height: "100%",
    flex: "auto",
    background: "#fff",
    border: "1px solid #d7d7d7",
    borderRadius: "12px",
    textAlign: "center",
    justifyContent: "space-between",
    cursor: "pointer",
  },
  map: {
    display: "flex",
    justifyContent: "flex-start",
  },
  title: {
    height: "auto",
    minHeight: "2.8125em", // "45px",
    display: "flex",
    alignItems: "center",
    width: "inherit",
    padding: "0 15px",
    justifyContent: "space-between",
  },
  titleName: {
    color: "#262626",
    fontSize: theme.font.size15, // "15px",
    fontWeight: "bold",
  },
  titleDistance: {
    display: "flex",
    justifyContent: "flex-end",
    fontSize: theme.font.size13,
    color: "#7f7f7f",
    minWidth: "4.063rem",
  },
  address: {
    fontSize: theme.font.size13,
    color: "#262626",
    textAlign: "left",
    padding: "0px 10px 0px 0px",
    marginRight: "10px",
    // height: "auto" // "3.125em", // "50px"
  },
  info: {
    height: "20px",
    display: "flex",
    fontSize: theme.font.size11,
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "10px",
  },
  label: {
    fontSize: theme.font.size13,
    color: "#555",
    textAlign: "left",
    padding: "0px",
    borderBotton: "none",
    marginRight: "8px",
    minWidth: "18%",
  },
  infoValue: {
    fontSize: theme.font.size13,
    color: "#555",
    textAlign: "left",
    padding: "0px",
    borderBotton: "none",
  },
  buttonGroup: {
    borderTop: "1px solid #e1e1e1",
    borderRadius: "0 0 12px 12px",
    minHeight: "3.125rem",
    background: "#fafafa",
  },
  buttonGroupItem: {
    alignItems: "center",
    display: "flex",
  },
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minhHeight: "3.125rem",
    width: "100%",
    height: "100%",
    padding: 0,
    fontSize: theme.font.size14,
    background: "#fafafa",
    color: theme.color.accent,
    fontWeight: "bold",
    outline: "0 none",
    border: "0",
    // borderBottom: "1px solid #e1e1e1",
    borderRadius: "0px 0px 12px 12px",
    "&:active": {
      background: "rgba(38, 38, 38, 0.1)",
    },
  },
  buttonDisabled: {
    color: "rgba(38, 38, 38, 0.4)",
  },
  buttonDivider: {
    background: "#e1e1e1",
    height: "16px",
    width: "1px",
    minWidth: "1px",
    padding: "16px 0 0 0",
  },
  infoBody: {
    margin: "0 0 25px 0",
    flexGrow: 1,
  },
  infoContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    margin: "10px 0 0 0 ",
    minWidth: "100%",
  },
  infoMultilineContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
  },
  infoMultilineItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    margin: "0 0 0 0 ",
  },
  infoIcon: {
    width: "1em", // "16px",
    height: "1em", // "16px",
    minWidth: "1em", // "16px",
    minHeight: "1em", // "16px",
    margin: "2px 8px 0 19px", // ".125em .5em 0 1.1875em"
  },
  phoneCallLink: {
    textDecoration: "underline",
    border: "none",
    outline: "0 none",
    color: "#262626",
    fontSize: theme.font.size13,
    background: "none",
    fontWeight: "bold",
  },
  externalDivider: {
    height: "20px",
    alignSelf: "center",
  },
  externalButton: {
    color: "#555",
    fontWeight: "normal",
    fontWeight: "bold",
    fontSize: theme.font.size14,
    flex: 1,
    border: "none",
    padding: 0,
    background: "transparent",
    "&:active": {
      background: "rgba(38, 38, 38, 0.1)",
    },
  },
  buttonLeft: {
    borderRadius: "0px 0px 0px 12px",
  },
  buttonRight: {
    borderRadius: "0px 0px 12px 0px",
  },
  buttonMiddle: {
    borderRadius: "0px",
  },
});

function makeNavigateURL(centerName, lat, lng) {
  const url =
    "http://" +
    "map.naver.com/?pathType=1&eText=" +
    centerName +
    "&elng=" +
    lng +
    "&elat=" +
    lat;
  return url;
}

const Card = React.forwardRef((props, ref) => {
  const { classes, data, parentFunc } = props;
  const context = useContext(DataContext);
  const daymap = {
    weekday_hour: "평일",
    saturday_hour: "토요일",
    sunday_hour: "일요일",
  };
  const handlePhoneCallClick = (telNum) => {
    if (!ref.clickAllowed()) {
      return;
    }
    goDial(telNum);
  };

  const handleNavigateClick = (button) => {
    const centerName = encodeURIComponent(button.parameters.dest);
    const lat = button.parameters.latitude;
    const lng = button.parameters.longitude;
    const url = makeNavigateURL(centerName, lat, lng);
    openLink(context, "external", url);
  };

  const handleSMSOpen = (centerName, centerSeq, phoneNumber) => {
    parentFunc.setSMSInfo(() => {
      return {
        SMSOpen: true,
        centerName: centerName,
        centerSeq: centerSeq,
        phoneNumber: phoneNumber,
      };
    });
  };

  const handleButtonClick = (item, buttonIndex) => {
    const button = item.buttons[buttonIndex];
    if (!ref.clickAllowed()) {
      return;
    }
    switch (button.parameters.action) {
      case "get_direction":
        handleNavigateClick(button);
        break;
      case "send_lms":
        handleSMSOpen(
          item.name,
          item.center_seq,
          button.parameters.phone_number
        );
        break;
      case "external":
        const targetUrl =
          context.userAgent.deviceType === "mobile"
            ? button.parameters.mobile_url
            : button.parameters.url;
        openLink(context, "external", targetUrl, item.event_action);
        break;
      default:
    }
  };

  return data.items.map((item, index) => {
    let tablerows = [];
    for (let daykey in daymap) {
      let opentimeinfo = item.opening_time[daykey];
      let tableinfo = {
        label: daymap[daykey],
      };

      tableinfo["value"] = "휴무";
      if (opentimeinfo) {
        tableinfo["value"] = opentimeinfo;
      }
      tablerows.push(tableinfo);
    }
    return (
      <div key={index} className={classes.listitem}>
        <div key={index} className={classes.card}>
          {context.countryCode === "us" ? (
            <GoogleMaps
              id={`googleMaps${index}`}
              title={item.name}
              defaultCenter={{
                lat: item.location.latitude,
                lng: item.location.longitude,
              }} // 지도 초기 위치
              defaultZoom={16} // 지도 초기 확대 배율
              countryCode={context.countryCode}
              style={{
                width: "100%", // 구글맵 가로 길이
                height: "144px", // 구글맵 세로 길이
                borderRadius: "6px 6px 0px 0px",
              }}
            />
          ) : (
            <NaverMaps
              id={`naverMaps${index}`}
              title={item.name}
              defaultCenter={{
                lat: item.location.latitude,
                lng: item.location.longitude,
              }} // 지도 초기 위치
              defaultZoom={16} // 지도 초기 확대 배율
              countryCode={context.countryCode}
              style={{
                width: "100%", // 네이버지도 가로 길이
                height: "144px", // 네이버지도 세로 길이
                borderRadius: "12px 12px 0px 0px",
              }}
            />
          )}

          <Grid container className={classes.title}>
            <Grid item className={classes.titleName}>
              {item.name}
            </Grid>
            <Grid item className={classes.titleDistance}>
              {item.distance}
            </Grid>
          </Grid>

          <div className={classes.infoBody}>
            <Divider
              component={"div"}
              variant="middle"
              color="#e1e1e1"
              disabled
            />
            <div className={classes.infoContainer} role={"text"}>
              <img
                className={classes.infoIcon}
                src={IconAddress}
                alt={context.string.aria.icon.address}
              />
              <div className={classes.address}>
                {item.address.depth_1} {item.address.depth_2}
              </div>
            </div>
            <div className={classes.infoContainer} role={"text"}>
              <img
                className={classes.infoIcon}
                src={IconTime}
                alt={context.string.aria.icon.time}
              />
              <div className={classes.infoMultilineContainer}>
                {tablerows.map((row, index) => (
                  <Grid
                    container
                    key={index}
                    className={classes.infoMultilineItem}
                  >
                    <Grid item className={classes.label}>
                      {row.label}
                    </Grid>
                    <Grid item className={classes.infoValue}>
                      {row.value}
                    </Grid>
                  </Grid>
                ))}
              </div>
            </div>
            <div className={classes.infoContainer} role={"text"}>
              <img
                className={classes.infoIcon}
                src={IconPhone}
                alt={context.string.aria.icon.phone}
              />
              <div className={classes.infoValue}>
                {item.phone_number}{" "}
                {context.userAgent.deviceType === "mobile" &&
                  item.phone_number && (
                    <button
                      type="button"
                      className={classes.phoneCallLink}
                      onClick={() => {
                        handlePhoneCallClick(item.phone_number);
                      }}
                    >
                      전화걸기
                    </button>
                  )}
              </div>
            </div>
            <div className={classes.infoContainer} role={"text"}>
              <img
                className={classes.infoIcon}
                src={IconParking}
                alt={context.string.aria.icon.parking}
              />
              <div className={classes.infoValue}>{item.parking}</div>
            </div>
          </div>
          <Grid container className={classes.buttonGroup}>
            {item.buttons.map((button, index) => {
              let buttonIcon = "";
              switch (button.label) {
                case "길찾기":
                  buttonIcon = Navigation;
                  break;
                case "방문예약":
                case "매장상담신청":
                  buttonIcon = Reservation;
                  break;
                case "문자로받기":
                  buttonIcon = iconSMS;
                  break;
                default:
                  buttonIcon = null;
              }

              return (
                <Grid
                  item
                  key={index}
                  xs={12 / item.buttons.length}
                  className={classes.buttonGroupItem}
                >
                  <Divider
                    className={classes.buttonDivider}
                    orientation="horizontal"
                    hidden={index === 0}
                    component={"div"}
                    disabled
                  />
                  <button
                    type="button"
                    className={[
                      classes.button,
                      index === 0 &&
                        item.buttons.length !== 1 &&
                        classes.buttonLeft,
                      index === item.buttons.length - 1 &&
                        item.buttons.length !== 1 &&
                        classes.buttonRight,
                      index !== 0 &&
                      index !== item.buttons.length - 1 &&
                      item.buttons.length > 2
                        ? classes.buttonMiddle
                        : "",
                      (context.conversationEnd ||
                        button.parameters.enable === "N") &&
                        classes.buttonDisabled,
                    ].join(" ")}
                    disabled={
                      context.conversationEnd ||
                      button.parameters.enable === "N"
                    }
                    onClick={() => handleButtonClick(item, index)}
                    aria-label={`${
                      button.parameters.action.toLowerCase() === "external" ||
                      button.parameters.action.toLowerCase() === "get_direction"
                        ? context.string.aria.external + ","
                        : ""
                    } ${button.label}_${item.name}`}
                  >
                    {button.label}
                  </button>
                </Grid>
              );
            })}
          </Grid>
        </div>
      </div>
    );
  });
});

const LO = (props) => {
  const { classes, data } = props;
  const context = useContext(DataContext);

  const [state, setState] = useState({
    SMSOpen: false,
    centerName: "",
    centerSeq: null,
    phoneNumber: "",
  });

  const parentFunc = {
    setSMSInfo: setState,
  };

  const handleSMSOK = (data) => {
    setState((prev) => {
      return { ...prev, SMSOpen: false, phoneNumber: "" };
    });
    console.log("handleSMSOK", data);
  };

  const handleSMSClose = () => {
    setState((prev) => {
      return { ...prev, SMSOpen: false, phoneNumber: "" };
    });
  };

  return (
    <>
      <div className={classes.root}>
        <Carousel
          className={classes.carousel}
          data={data}
          parentFunc={parentFunc}
          component={withStyles(styles)(Card)}
          ariaLabel={context.string.aria.location}
        />
        <SMS
          open={state.SMSOpen}
          handleOk={handleSMSOK}
          handleClose={handleSMSClose}
          centerName={state.centerName}
          centerSeq={state.centerSeq}
          defaultPhoneNumber={state.phoneNumber}
        />
      </div>
    </>
  );
};
export default withStyles(styles)(LO);
