import React, { useState } from "react";
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
  Dialog,
} from "@material-ui/core";

function Debug(props) {
  const { open, handleOk, handleClose } = props;
  const [description, setDescription] = useState(
    "Intent와 Parameters를 입력해 주세요!"
  );
  const [intent, setIntent] = useState("SG_TEST");
  const [parameters, setParameters] = useState(
    `{
  "product_group_code": "TQ_WM",
  "product_group_name": "세탁기",
  "product_code": "TQ_DRW",
  "product_name": "트윈워시",
  "product_sub_code": "TQ_DRW_02",
  "product_sub_name": "트윈워시"
}`
  );

  const handleIntentChange = (value) => {
    setIntent(value);
  };

  const handleParametersChange = (value) => {
    setParameters(value);
  };

  const handleComplete = () => {
    try {
      const response = {
        intent: intent,
        parameters: JSON.parse(parameters),
      };
      handleOk(response);
      setDescription("Intent와 Parameters를 입력해 주세요!");
    } catch (e) {
      console.log("handleComplete - error", e);
      setDescription("데이터 포맷 오류가 발생했습니다!");
      return;
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">HIDDEN COMMAND</DialogTitle>
        <DialogContent>
          <DialogContentText color="primary">{description}</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="intent"
            label="INTENT"
            type="text"
            onChange={(e) => {
              handleIntentChange(e.target.value);
            }}
            value={intent}
            fullWidth
          />
          <TextField
            margin="dense"
            id="params"
            label="Parameters"
            type="text"
            onChange={(e) => {
              handleParametersChange(e.target.value);
            }}
            value={parameters}
            fullWidth
            multiline
          />
        </DialogContent>
        <DialogActions>
          <Button type="button" onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button type="button" onClick={handleComplete} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default Debug;
