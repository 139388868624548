import React, { useContext, Fragment } from "react";
import {
  withStyles,
  Divider,
  Button,
  Grid
} from "@material-ui/core";
import Carousel from "../../carousel/Carousel";
import DataContext from "../../data/DataContext";
import { openLink } from "../../util/utilNative";
import IconMore from "../../../asset/img/btn_more_nor.png";
import IconMorePressed from "../../../asset/img/btn_more_sel.png";
import IconMoreItem from "../../../asset/img/btn_more_slide.png";
import IconBadge from "../../../asset/img/ico_flag_newProd.png";

const styles = (theme) => ({
  root: {
    position: "relative",
    letterSpacing: "-0.5px",
    margin: "6px 0px",
    zIndex: 10,
  },
  carousel: {
    width: "100%",
    height: "144px",
  },
  listitem: {
    width: "290px",
    padding: "0px",
    display: "flex"
  },
  card: {
    width: "280px",
    height: "100%",
    flex: "auto",
    background: "#fff",
    border: "1px solid #d7d7d7",
    borderRadius: "12px",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    margin: "0px 5px"
  },
  moreItemList: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "300px",
    padding: "0px 0px 0px 8px",
  },
  moreItemCard: {
    width: "140px",
    background: "transparent",
    border: "0px",
    borderRadius: "6px",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  moreItemText: {
    fontSize: theme.font.size13,
    fontWeight: "bold",
    color: theme.color.text,
    textAlign: "center",
    margin: "15px 0px",
    lineHeight: "24px",
  },
  thumbnail: {
    height: "150px",
    padding: "15px 20px",
  },
  thumbnailImage: {
    width: "auto",
    height: "132px",
  },
  moreItemThumbnailImage: {
    width: "60px",
    height: "60px",
    backgroundImage: `url(${IconMore})`,
    backgroundSize: "60px",
    "&:active": {
      backgroundImage: `url(${IconMorePressed})`,
    },
  },
  info: {
    flexGrow: 1,
    minHeight: "9.688rem",
  },
  infoTitle: {
    fontSize: theme.font.size15, // "15px",
    fontWeight: "bold",
    color: theme.color.text,
    textAlign: "center",
    padding: "0px 20px",
  },
  infoDescription: {
    fontSize: theme.font.size12,
    fontWeight: "300",
    color: theme.color.message.date,
    textAlign: "center",
  },
  table: {
    width: "-webkit-fill-available",
    margin: "12px 20px",
    padding: "0px",
    // lineHeight: "18px"
  },
  tableRow: {
    borderBottom: `1px solid ${theme.color.list_divider}`,
    height: "100%",
    display: "flex",
    alignItems: "baseline",
  },
  label: {
    height: "100%",
    minHeight: "2rem",
    fontSize: theme.font.size13,
    fontWeight: "normal",
    color: theme.color.text,
    textAlign: "start",
    padding: "0px 8px",
    display: "flex",
    alignItems: "center",
  },
  value: {
    fontSize: theme.font.size13,
    fontWeight: "normal",
    color: theme.color.text,
    textAlign: "start",
    padding: "0px 8px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    // whiteSpace: "nowrap",
    // maxWidth: '0',
    display: "flex",
    alignItems: "center",
  },
  horizontalDivider: {
    width: "100%",
    height: "1px",
    backgroundColor: theme.color.list_divider,
  },
  external: {
    display: "flex",
    minHeight: "2.813rem",
    borderRadius: "0px 0px 12px 12px",
    background: "#fafafa",
  },
  externalHorizontal: {
    display: "flex",
    height: "45px",
    background: "#f7f0f0",
    flexDirection: "column",
  },
  endBorderRadius: {
    borderRadius: "0 0 12px 12px",
  },
  externalButton: {
    color: theme.color.accent,
    fontWeight: "bold",
    fontSize: theme.font.size14,
    flex: 1,
    border: "none",
    borderRadius: "0px 0px 12px 12px",
    padding: 5,
    background: "#fafafa",
    "&:active": {
      background: "rgba(38, 38, 38, 0.1)",
    },
    whiteSpace: "pre-wrap",
    lineHeight: 1.5,
  },
  externalButtonLeft: {
    borderRadius: "0px 0px 0px 12px",
  },
  externalButtonRight: {
    borderRadius: "0px 0px 12px 0px",
  },
  horizontalButton: {
    width: "100%",
    height: "45px",
    textAlign: "center",
    color: "#707070",
    fontSize: theme.font.size14,
    fontWeight: "bold",
    padding: "0px",
    backgroundColor: "#f7f7f7",
  },
  disabledButton: {
    color: "rgba(38, 38, 38, 0.4)",
  },
  externalDivider: {
    height: "20px",
    alignSelf: "center",
    backgroundColor: theme.color.list_divider,
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  buttonIcon: {
    width: "20px",
    height: "20px",
    marginRight: "4px",
  },
  horizontalButtonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    padding: "8px 18px",
  },
  horizontalButtonLabel: {
    display: "flex",
    flexDirection: "row",
  },
  priceRow: {
    height: "100%",
    display: "flex",
    alignItems: "baseline",
  },
  priceObs: {
    fontWeight: "bold",
    fontSize: theme.font.size16,
    color: theme.color.message.text,
    marginStart: "8px",
    wordBreak: "breakWord",
  },
  priceMaster: {
    textDecoration: "line-through",
    fontSize: theme.font.size14,
    color: theme.color.message.date,
    marginStart: "8px",
  },
  badge: {  
    position: "absolute",
    top: "10px",
    margin: "0px -5px",
    display: "flex"
  },
  badgeIcon: {
    height: theme.font.size35,
  },
  badgeTitle: {
    color: "#ffffff",
    position: "absolute",
    fontSize: theme.font.size13,
    fontWeight: "bold",
    margin: "5px 10px"
  }
});

const Card = React.forwardRef((props, ref) => {
  const { classes, data } = props;
  const context = useContext(DataContext);
  const featureHorizontalButtons = false;
  const featureMoreItems = true;

  const handleClick = (url, action, event, button, item) => {
    if (!ref.clickAllowed()) {
      return;
    }

    if (button && button.type && button.type.toLowerCase() === "speech") {
      context.request(
        context,
        item.link.product_name + " " + button.label,
        "button"
      );
    } else {
      openLink(context, action, url, event);
    }
  };

  let cardList = data.items.map((item, key) => (
    <div key={`cardList${key}`} className={classes.listitem}>
      <div className={classes.card}>
        <div
          className={classes.thumbnail}
          onClick={() =>
            handleClick(item.link["product_link_url"], item.link["type"])
          }
        >
          <img
            className={classes.thumbnailImage}
            src={item.link["product_image_url"]}
            alt={`${item.link["product_title"]
              .replace("<sup>", "")
              .replace("</sup>", "")
              .replace("ThinQ", "씽큐")}, ${
              item.link["product_name"]
            }, 상세 바로가기`}
          />
        </div>
        <div className={classes.info}>
          {/* HTML TAG가 들어간 경우 처리 */}
          <div
            className={classes.infoTitle}
            dangerouslySetInnerHTML={{ __html: item.link["product_title"] }}
            role={"text"}
          />
          <div
            className={classes.infoDescription}
            dangerouslySetInnerHTML={{ __html: item.link["product_name"] }}
          />
          <div className={classes.table} size="small">
            {item.product_spec.map((spec, key) => (
              // <Grid
              //   container
              //   className={classes.tableRow}
              //   key={`product_spec${key}`}
              //   role={"text"}
              // >
              //   <Grid
              //     item
              //     className={classes.label}
              //     dangerouslySetInnerHTML={{ __html: spec.label }}
              //   />
              //   <Grid
              //     item
              //     className={classes.value}
              //     dangerouslySetInnerHTML={{ __html: spec.value }}
              //   />
              // </Grid>
              <div className={[classes.tableRow, classes.label].join(" ")} dangerouslySetInnerHTML={{ __html: spec.label + " : " + spec.value }}/>
            ))}
          </div>
          {item.product_price &&
            item.product_price.product_master_price !== "0" && (
              <div className={classes.table} size="small">
                <Grid
                  container
                  className={classes.priceRow}
                  key={`product_price${key}`}
                >
                  <Grid
                    item
                    className={classes.priceObs}
                    dangerouslySetInnerHTML={{
                      __html: item.product_price.product_obs_price + "원",
                    }}
                    aria-label={`할인가: ${item.product_price.product_obs_price}원`}
                    role="text"
                  />
                  <Grid
                    item
                    className={classes.priceMaster}
                    dangerouslySetInnerHTML={{
                      __html: item.product_price.product_master_price + "원",
                    }}
                    aria-label={`정상가: ${item.product_price.product_master_price}원`}
                    role="text"
                  />
                </Grid>
              </div>
            )}
        </div>
        <Divider
          orientation={"horizontal"}
          className={classes.horizontalDivider}
          component={"div"}
          disabled
        />
        <div
          className={[
            classes.external,
            (featureHorizontalButtons === false ||
              !("horizontal_buttons" in item)) &&
              classes.endBorderRadius,
          ].join(" ")}
        >
          {item.buttons.map((button, index) => (
            <Fragment key={`buttons${index}`}>
              <Divider
                className={classes.externalDivider}
                orientation="vertical"
                hidden={index === 0}
                component={"div"}
                disabled
              />
              <Button
                type="button"
                className={[
                  classes.externalButton,
                  button.parameters.enable === "N" && classes.disabledButton,
                  index === 0 && item.buttons.length > 1
                    ? classes.externalButtonLeft
                    : "",
                  index === item.buttons.length - 1 && item.buttons.length > 1
                    ? classes.externalButtonRight
                    : "",
                ].join(" ")}
                disabled={
                  context.conversationEnd || button.parameters.enable === "N"
                }
                disableRipple
                onClick={() =>
                  handleClick(
                    context.userAgent.deviceType === "mobile"
                      ? button.parameters.mobile_url
                      : button.parameters.url,
                    button.parameters.action,
                    button.event_action,
                    button,
                    item
                  )
                }
                aria-label={`${
                  button.parameters.action.toLowerCase() === "external"
                    ? context.string.aria.external + ","
                    : ""
                } ${button.label}, ${item.link["product_title"]
                  .replace("<sup>", "")
                  .replace("</sup>", "")
                  .replace("ThinQ", "씽큐")}, ${item.link["product_name"]}`}
              >
                <div className={classes.buttonContainer}>
                  {/* <img
                    className={classes.buttonIcon}
                    src={
                      button.label === context.string.chat.bestshopCounsel
                        ? button.parameters.enable === "N"
                          ? IconChatDisabled
                          : IconChat
                        : button.parameters.enable === "N"
                        ? IconStoreDisabled
                        : IconStore
                    }
                    alt={
                      button.label === context.string.chat.bestshopCounsel
                        ? "IconChat"
                        : "IconStore"
                    }
                  /> */}
                  <div>{button.label}</div>
                </div>
              </Button>
            </Fragment>
          ))}
        </div>
        {featureHorizontalButtons === true && item.horizontal_buttons && (
          <div
            className={[
              classes.externalHorizontal,
              classes.endBorderRadius,
            ].join(" ")}
          >
            {item.horizontal_buttons.map((button, index) => (
              <Fragment key={`horizontal_buttons${index}`}>
                <Divider component={"div"} disabled />
                <Button
                  type="button"
                  className={[
                    classes.horizontalButton,
                    classes.endBorderRadius,
                    button.parameters.enable === "N" && classes.disabledButton,
                  ].join(" ")}
                  disabled={
                    context.conversationEnd || button.parameters.enable === "N"
                  }
                  disableRipple
                  onClick={() =>
                    handleClick(
                      context.userAgent.deviceType === "mobile"
                        ? button.parameters.mobile_url
                        : button.parameters.url,
                      button.parameters.action
                    )
                  }
                >
                  <div className={classes.horizontalButtonContainer}>
                    <div className={classes.horizontalButtonLabel}>
                      {button.label === context.string.chat.moreProducts && (
                        <img
                          className={classes.buttonIcon}
                          src={IconMore}
                          alt={"IconMore"}
                        />
                      )}
                      <div>{button.label}</div>
                    </div>
                    <div>&#62;</div>
                  </div>
                </Button>
              </Fragment>
            ))}
          </div>
        )}
         {(item.product_badge && item.product_badge.length > 0) && 
            <div className={classes.badge}>
              <img className={classes.badgeIcon} src={IconBadge} alt={"Badge"}/>
              <span className={classes.badgeTitle}>{item.product_badge}</span>
            </div>
          }
      </div>
    </div>
  ));
  if (featureMoreItems && data.more_items && data.more_items.enable === "Y") {
    let moreitem_button = (
      <div className={classes.moreItemList}>
        <div
          className={classes.moreItemCard}
          onClick={() => handleClick(data.more_items.url, data.more_items.type)}
        >
          <div className={classes.moreItemThumbnailImage} />
          <div className={classes.moreItemText}>
            {context.string.chat.moreItemHomepage
              .split("\n")
              .map((content, index) => (
                <Fragment key={`moreItem${index}`}>
                  <span>{content}</span>
                  <br />
                </Fragment>
              ))}
          </div>
        </div>
      </div>
    );
    cardList.push(moreitem_button);
  }
  return cardList;
});

function ProductCard(props) {
  const { classes, data } = props;
  const context = useContext(DataContext);

  return (
    <>
      <div className={classes.root}>
        <Carousel
          className={classes.carousel}
          component={withStyles(styles)(Card)}
          data={data}
          ariaLabel={context.string.aria.product}
        />
      </div>
    </>
  );
}
export default withStyles(styles)(ProductCard);
