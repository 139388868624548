import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
  typography: {
    fontFamily: "Noto Sans CJK KR, Noto Sans, RobotoDraft, Roboto, San Francisco, Apple SD Gothic Neo, Helvetica Neue, sans-serif",
    textTransform: "none",
    fontSize: "16px"
  },
  font: {
    size8: "0.5rem",
    size10: ".625rem",     // "10px"
    size11: ".6875rem",    // "11px"
    size12: ".75rem",      // "12px"
    size13: ".8125rem",    // "13px"
    size13_5: ".84375rem", // "13.5px"
    size14: ".875rem",     // "14px"
    size15: ".9375rem",    // "15px"
    size16: "1rem",        // "16px"
    size17: "1.0625rem",   // "17px"
    size18: "1.125rem",    // "18px"
    size35: "2.188rem",
    font50: "3.125rem",    // "50px"
  },
  color: {
    app_bar_title_text: "#262626",
    app_bar_material_background: "#ffffff",
    
    primary_text_default_dark: "#fff",
    list_divider: "#e1e1e1",
    
    accent: "#0A8297", // "#00a0b6",
    text: "#262626",

    // Old
    lightGrey: '#cdcdcd',
    grey: '#888',
    black: '#343434',
    background: '#f5f5f5',
    message: {
      background: '#7f7f7f',
      color: '#262626',
      date: "#666"
    },
    select: {
      color: '#bf134a'
    },
    input: {
      text: "rgba(38, 38, 38)",
      placeholder: "#707070", // "rgba(38, 38, 38, 0.4)",
      disabled: "rgba(38, 38, 38, 0.5)",
    },
    menu: {
      background: "#f0f0f0",
      home: "#262626",
      homeSelected: "#ccc",
      item: "#0A8297"
    }
  },
  palette: {
    primary: {
      main: "#0a8297"//"#00a0b6"
    },
    secondary: {
      main: "#e63869"
    },
    tertiary: {
      main: "#35c8e6"
    },
    search: {
      background: "#2d2d2d"
    }
  },
  toggle: {
    thumbOnColor: '#e63869',
    trackOnColor: '#35c8e6'
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        html: {
          font: "-apple-system-body !important",
          fontSize: "16px"
        },
        body: {
          font: "-apple-system-body",
          fontSize: "16px"
        }
      }
    }
  }
})

export default theme;