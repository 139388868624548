import React, { useEffect, useState } from "react";

function GoogleMaps(props) {
  const { classes, id, style, title, defaultCenter, defaultZoom } = props;
  const [googlemap, setGooglemap] = useState(null);

  let random = Math.random()
    .toString(36)
    .substring(7);
  const divId = id + random;

  useEffect(() => {
    if (window.google.maps && googlemap === null) {
      var position = new window.google.maps.LatLng(
        defaultCenter.lat,
        defaultCenter.lng
      );
      var mapOptions = {
        zoom: defaultZoom,
        center: position,
        mapTypeId: window.google.maps.MapTypeId.ROADMAP //일반지도 HYBRID //위성지도
      };

      try {
        var map = new window.google.maps.Map(
          document.getElementById(divId),
          mapOptions
        );
        new window.google.maps.Marker({
          position: position,
          map: map,
          title: title
        });

        setGooglemap(map);
      } catch (e) {
        console.log("error", e);
      }
    }

    return () => {
      if (googlemap) {
        googlemap.destroy();
      }
    };
  }, []);

  return <div id={divId} style={style} className={classes.root}/>;
}
export default GoogleMaps;
