import React from "react";
import { Route, Switch, BrowserRouter as Router, Redirect } from "react-router-dom";
import Main from "./Main";
import Error from "./Error";

function RouteMap() {
  return (
    <Router>
      <Switch>
        {/* <Route exact path="/error" component={Error}/>
        <Route exact path="/:countryCode" component={Main}/>
        <Route exact path="/" render={() => <Redirect to="/kr"/>} /> */}
        <Route exact path="/" component={Main}/>
        <Route component={Error} />
      </Switch>
    </Router>
  );
}

export default RouteMap;
