import { createContext } from "react";

/**
 * Model Class
 * - 현재 대화를 저장하는 모델
 */
const InputContext = createContext({
  input: "",
  setInput: () => {},
  autoComplete: {
    enable: false,
    setEnable: () => {},
    parameters: {},
    open: false,
    setOpen: () => {},
  },
  keyboard: {
    setOpen: () => {},
    open: false,
    handleOpen: () => {},
  },
});
export default InputContext;
