import React from "react";
import { withStyles, Button } from "@material-ui/core";

const styles = (theme) => ({
  mainBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100wh%",
    height: "100vh",
  },
  errorText1: {
    fontSize: theme.font.size15, // "15px",
    fontWeight: "bold",
    color: theme.color.text,
  },
  errorText2: {
    fontSize: theme.font.size15, // "15px",
    fontWeight: "bold",
    color: theme.color.text,
    margin: "5px 0",
  },
  button: {
    height: "40px",
    backgroundColor: theme.color.accent,
    color: "#fff",
    fontSize: theme.font.size15, // "15px",
    fontWeight: "bold",
    padding: "10px 20px",
    marginTop: "24px",
    borderRadius: "20px",
    textTransform: "unset !important",
    "&:active": {
      backgroundColor: "#08899a",
    },
  },
});

function Sorry(props) {
  const { classes, message, handleReload } = props;
  console.log(message);
  return (
    <>
      <div className={classes.mainBox}>
        <div className={classes.errorText1}>죄송합니다.</div>
        <div className={classes.errorText2}>잠시 후에 다시 이용해주세요.</div>
        <Button type="button" className={classes.button} onClick={handleReload}>
          ThinQ 챗봇 다시 시작
        </Button>
      </div>
    </>
  );
}
export default withStyles(styles)(Sorry);
