import React, { useContext, useState } from "react";
import { withStyles, 
  DialogContent,
  DialogActions,
  Button,
  Dialog, Divider, RadioGroup, FormControlLabel, Radio
} from "@material-ui/core";
import Icon from "../../../asset/img/btn_single_to_off_nor.png";
import CheckedIcon from "../../../asset/img/btn_single_to_on_nor.png";
import DataContext from "../../data/DataContext";

const styles = theme => ({
  paper: {
    borderRadius: "20px",
    margin: "0px"
  },
  title: {
    fontSize: theme.font.size18,
    fontWeight: "bold",
    color: theme.color.text,
    margin: "18px 24px"
  },
  description: {
    fontSize: theme.font.size15, // "15px",
    fontWeight: "300",
    color: theme.color.text
  },
  divider: {
    backgroundColor: theme.color.list_divider,
    height: "1px",
    margin: "12px 0px"
  },
  icon: {
    width: "32px",
    height: "32px",
    backgroundSize: "32px",
    backgroundImage: `url(${Icon})`
  },
  checkedIcon: {
    width: "32px",
    height: "32px",
    backgroundSize: "32px",
    backgroundImage: `url(${CheckedIcon})`
  },
  label: {
    fontSize: theme.font.size15, // "15px",
    color: theme.color.text
  },
  button: {
    color: theme.color.accent,
    fontSize: theme.font.size15, // "15px",
    fontWeight: "bold"
  }
});

function LP(props) {
  const { classes, data, item, open, handleButtonClick } = props;
  const [ value, setValue ] = useState(data.list_items[0].label);
  const context = useContext(DataContext);

  const handleChange = (e) => {
    setValue(e.target.value)
  }

  return (
    <>
      <Dialog open={open} classes={{paper: classes.paper}} aria-labelledby="form-dialog-title" fullWidth>
        <div className={classes.title} id="form-dialog-title"  role={"text"} aria-label={`${data.title}, ${context.string.aria.title1}`}>{data.title}</div>
        <DialogContent>
          {data.description && 
            <div className={classes.description}>
                {data.description}
            </div>
          }
          <Divider className={classes.divider} component={"div"} disabled/>
          <RadioGroup aria-label={classes.title} value={value} onChange={handleChange}>
            {data.list_items.map((item) => (
              <FormControlLabel
                classes={{label: classes.label}}
                value={item.label} 
                control={<Radio className={classes.radio} color={"primary"} icon={<span className={classes.icon} />} checkedIcon={<span className={classes.checkedIcon} />} />}
                label={item.label}/>
            ))}
          </RadioGroup>
          
        </DialogContent>
        <DialogActions>
          {data.buttons.map((button) => (
            <Button className={classes.button} onClick={() => handleButtonClick(button, value, item)} color="primary">
              {button.label}
            </Button>
          ))}
        </DialogActions>
      </Dialog>
    </>
  )
}
export default withStyles(styles)(LP);
