import React from "react";
import { Button, withStyles } from "@material-ui/core";
import { useLiveChat } from "../data/LiveChat";
import { Wave } from "react-animated-text";
import LiveChatRating from "./LiveChatRating";

const styles = (theme) => ({
  root: {
    height: "48px",
    color: theme.palette.primary.main,
    backgroundColor: "#ffffff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "12px",
    border: "1px solid #d7d7d7",
    margin: "8px",
  },
  disabled: {
    color: theme.palette.primary.main,
  },
  text: {
    display: "flex",
  },
});

const LiveChatHeader = (props) => {
  const { classes } = props;
  const { livechat, livechatApi, ticket } = useLiveChat();

  const handleClick = () => {
    livechatApi.openRating();
  };

  if (!livechat.enable) return null;
  return ticket.statusId === "status_chat" ? ( // in_chat
    <>
      <Button className={classes.root} onClick={handleClick}>
        상담 종료
      </Button>
      <LiveChatRating />
    </>
  ) : (
    <Button className={classes.root}>
      <Wave text={"상담사 연결중"} effect={"pop"} effectChange={1.2} />
    </Button>
  );
};

export default withStyles(styles)(LiveChatHeader);
