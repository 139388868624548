// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  "aws_project_region": "ap-northeast-2",
  "aws_cognito_region": "ap-northeast-2",
  "aws_user_pools_id": "ap-northeast-2_QqxcsGKim",
  "aws_user_pools_web_client_id": "67hjh0hj7isbt1lhmpm65juvhu",
  "aws_cognito_identity_pool_id": "ap-northeast-2:5ca5841a-2d0a-4ab6-ae6a-c27f76cc4358",
  "aws_appsync_region": "ap-northeast-2",
  "aws_appsync_graphqlEndpoint": "https://zcqs6ihdfvhgnoy74xu2tdfwia.appsync-api.ap-northeast-2.amazonaws.com/graphql",
  "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
};
// const awsmobile = {
//   "aws_project_region": "ap-northeast-2",
//   "aws_cognito_region": "ap-northeast-2",
//   "aws_user_pools_id": "ap-northeast-2_eWCPbDtI6",
//   "aws_user_pools_web_client_id": "3jg2d9kvpmpu8lnsj5uro6omq9",
//   "aws_cognito_identity_pool_id": "ap-northeast-2:04679bbf-f03d-42b4-86eb-f7dfc3748ea0",
//   "aws_appsync_region": "ap-northeast-2",
//   "aws_appsync_graphqlEndpoint": "https://vjlogk7la5fynfv5qjfewbgwdu.appsync-api.ap-northeast-2.amazonaws.com/graphql",
//   "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
// };
export default awsmobile;
