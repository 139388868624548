import moment from "moment";
import base62 from "uuid-base62";
import "abortcontroller-polyfill";
const https = require("https");

// TOKEN Info.
const clientId = "15tacovivet3s1fmmd0cthciim";
const clientPw = "o964ggp31jmns3vn79hjnqaatn04708kl0fsgm3b8knt97dntmu";
const thinqAPIKey =
  "eyJwcm9qZWN0X2lkIjogIjhhYmQyZWIzNzE5MjRmNmM4OTFmZTIxN2E1NDZlYzg2IiwgInVzZXJfaWQiOiAiS1IyMDAyMjA1ODMzNTYxIiwgImNyZWF0ZV90aW1lIjogIjE1ODM0ODk2OTg2NjkifQ==";

const baseURL = "korea-dev.api.lgthinqai.net";
const downStreamPath = "/voice/stt/v1/dictation/downstream";
const upStreamPath = "/voice/stt/v1/dictation/upstream";

// For send timeout
let timeoutTest = false;
const defaultTimeout = 12 * 1000;
let serviceCheckTimeoutTest = false;
const serviceCheckTimeout = 12000;
const sendLMSTimeout = 18000;

/**
 * Common Header
 * @param {DataContext} context
 */
const header = (context) => {
  return {
    "Content-Type": "application/json;charset=UTF-8",
    Authorization: `Bearer ${context.channelParams.access_token}`,
    "x-project-code": context.channel || "thinq", // Default: thinq
    "x-client": context.channel || "thinq", // Default: thinq
    "x-branch": context.countryCode === "kr" ? "LGEKR" : "LGEAI",
    // "x-session-id": context.sessionId,
    "x-message-id": base62.v4(),
    "x-api-key":
      context.countryCode === "kr" ? 
      "MTtjMmRmMDhkNWJlNTY0NDM4OGMwNDRlZGRiODQzNTRiZDsxNTg2MjYxODA1NDA2" :
      "MTsyYzg1M2UwZDdiN2Y0OTliOGZmNjczYWJkNDY4YzljYzsxNjE5NjAwNjUxMTEz"
  };
};

/**
 * Common Body
 * @param {DataContext} context
 * @param {Text} content
 * @param {ContentType} contentType
 * @param {Intent} intent
 * @param {Parameters} parameters
 */
const body = (
  context,
  content,
  contentType,
  intent = null,
  parameters = null,
  dialogStatus,
  expect = null
) => {
  let bodytemp = {
    dialog_status: dialogStatus,
    session: {
      new: dialogStatus.toLowerCase() === "start",
      session_id: context.sessionId,
      dialog: context.dialog,
      attributes: {},
    },
    request: {
      type: contentType,
      timestamp: moment().unix(),
      locale: context.countryCode === "kr" ? "ko-KR" : "en-US", // Front 생성
      text: content || "", // Front 생성
      // intent: intent || "", // Front 생성
      // parameters: parameters || {} // Front 생성
      utc_offset: createOffsetMinute(new Date()),
    },
  };

  if (intent) bodytemp.request.intent = intent;
  if (parameters) bodytemp.request.parameters = parameters;
  if (expect) bodytemp.session.dialog.expect = expect;
  
  // start 일때만 보내는 것으로 수정
  if (dialogStatus.toLowerCase() === "start") {
    bodytemp.session.client = {
      device_type: context.userAgent.deviceType,
      os: context.userAgent.os,
      utc_offset: createOffsetMinute(new Date()),
      channel: "thinq", //context.channel,
      channel_parameters: context.channelParams,
    };
    bodytemp.session.dialog = {}; // start 일때 dialog 초기화
  }
  return JSON.stringify(bodytemp);
};

function errorHandler(error) {
  //(context, error) {
  //let responseContent = "";
  let code;
  let message;
  let statusCode;
  if (error.name === "AbortError") {
    //responseContent = context.string.chat.responseTimeout;
    code = "504";
    message = "Gateway Timeout.";
    statusCode = 504;
  } else if (error.statusCode === 401) {
    //responseContent = context.string.chat.sessionTimeout;
    code = error.code;
    message = error.message;
    statusCode = error.statusCode;
  } else {
    //responseContent = context.string.chat.responseError;
    code = error.code;
    message = error.message;
    statusCode = error.statusCode;
  }

  return {
    status: {
      code: code,
      message: message,
      statusCode: statusCode,
    },
  };
}

function makeAbortController(timeoutTime) {
  const AbortController = window.AbortController;
  let controller = new AbortController();
  const timeoutId = setTimeout(() => {
    console.log("[send]", "timeout -", timeoutTime);
    controller.abort();
  }, timeoutTime);
  return { timeoutId, controller };
}

function refineInputTextForBackend(inputStr) {
  // 256자 제한
  inputStr = inputStr.substring(0, 256);

  // escaping < >
  inputStr = inputStr.replace(/</g, "&lt;").replace(/\>/g, "&gt;");
  return inputStr;
}

async function send(
  method,
  context,
  content,
  contentType,
  intent,
  dialogStatus,
  parameters,
  expect
) {
  console.log("[send] method:", method);
  console.log("[send] context:", context);
  console.log("[send] content:", content);
  console.log("[send] contentType:", contentType);
  console.log("[send] intent:", intent);
  console.log("[send] dialogStatus:", dialogStatus);

  let url = window.thinq.chatbot.thinqAIUrl + "/voice/nlp/v3/dialog";
  if (content !== "__CHATBOT_START_CONVERSATION__" && timeoutTest) {
    url =
      "https://" + "www.mocky.io/v2/5185415ba171ea3a00704eed?mocky-delay=" +
      (defaultTimeout + 1000) +
      "ms";
  }
  const refineText = refineInputTextForBackend(content);
  console.log("[text-refined]", refineText);

  console.log(url);
  console.log("channelParams", context.channelParams);
  let headerVal = header(context);
  console.log("header", headerVal);
  let bodyVal = body(
    context,
    refineText,
    contentType,
    intent,
    parameters,
    dialogStatus,
    expect
  );
  console.log("body", JSON.parse(bodyVal));

  let { timeoutId, controller } = makeAbortController(defaultTimeout);
  let statusObj = {
    responseOk: true,
    statusCode: null,
    statusText: "",
  };

  return fetch(url, {
    //"/v1/nlp", {
    headers: headerVal,
    method: method,
    body: bodyVal,
    signal: controller.signal,
  })
    .then((res) => {
      clearTimeout(timeoutId);
      console.log("[res]", res);
      statusObj.responseOk = res.ok;
      statusObj.statusCode = res.status;
      statusObj.statusText = res.statusText;
      return res.json();
    })
    .then((resjson) => {
      let body = {};
      body = resjson;
      body.status.statusCode = statusObj.statusCode;

      if (!statusObj.responseOk) {
        throw {
          name: "network",
          code: body.status.code,
          message: body.status.message,
          statusCode: statusObj.statusCode,
          statusText: statusObj.statusText,
        };
      }
      return body;
    })
    .catch((error) => {
      console.log("[error]", error);
      let body = errorHandler(error);
      return body;
    })
    .finally(() => {
      clearTimeout(timeoutId);
    });
}

async function getToken() {
  return new Promise((resolve, reject) => {
    const options = {
      hostname: "oauth.api.thinqai.net",
      port: 443,
      path: "/v1/cognito",
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Basic ${new Buffer(clientId + ":" + clientPw).toString(
          "base64"
        )}`,
      },
    };
    let req = https.request(options, (res) => {
      res.setEncoding("utf8");
      res.on("data", function(data) {
        data = JSON.parse(data);
        resolve(data);
      });
    });
    req.on("error", (err) => {
      console.error(err);
      reject();
    });
    req.end();
  });
}

/*
 * UTC TIMES
 * utc_offset을 시/분 단위로 계산
 */
function createOffsetMinute(date) {
  //분 단위로 계산
  var sign = date.getTimezoneOffset() > 0 ? "-" : "";
  var offset = Math.abs(date.getTimezoneOffset());

  return sign + offset;

  //시간 단위로 계산
  /*
	function pad(value) {
		return value < 10 ? '0' + value : value;
	}
	var sign = (date.getTimezoneOffset() > 0) ? "-" : "+";
	var offset = Math.abs(date.getTimezoneOffset());
	var hours = pad(Math.floor(offset / 60));
	var minutes = pad(offset % 60);

	return sign + hours + ":" + minutes;
	*/
}

/*
 * EXT Request
 */
async function extServiceCheck(countryCode, stage) {
  let checkApi = window.thinq.chatbot.extUrl + "/services/check?service=thinq";
  if (serviceCheckTimeoutTest) {
    checkApi =
      "https://" + "www.mocky.io/v2/5185415ba171ea3a00704eed?mocky-delay=" +
      (serviceCheckTimeout + 1000) +
      "ms";
  }
  const header = {
    "Content-Type": "application/json;charset=UTF-8",
    "x-session-id": base62.v4(),
    "x-channel": "thinq",
    "x-branch": countryCode === "kr" ? "LGEKR" : "LGEAI",
    "x-country-code": countryCode,
  };
  console.log("[checkApi]", checkApi);
  console.log("[checkApi Header", header);

  function makeErrorResult(check, statusCode, statusText) {
    return {
      result: {
        check: check,
        error: {
          statusCode: statusCode,
          statusText: statusText,
        },
      },
    };
  }

  let { timeoutId, controller } = makeAbortController(serviceCheckTimeout);

  return await fetch(checkApi, {
    headers: header,
    signal: controller.signal,
  })
    .then((res) => {
      clearTimeout(timeoutId);
      if (!res.ok) {
        throw makeErrorResult(true, res.status, res.statusText);
      }
      return res.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      if ("name" in error && error.name === "AbortError") {
        error = makeErrorResult(true, 0, "timeout");
      } else if (!("check" in error)) {
        error = makeErrorResult(true, 0, error.message);
      }

      console.log("[error]", error);
      return error;
    })
    .finally(() => {
      clearTimeout(timeoutId);
    });
}

/**
 * 상담사 채팅 링크에 상담 히스토리를 추가하기 위해 S3 버킷을 가져온다.
 *
 * @param {string} countryCode 국가코드
 * @param {string} sessionId 챗봇 Session ID
 */
async function extLiveChatHistory(countryCode, sessionId) {
  let checkApi =
    window.thinq.chatbot.extUrl + "/livechat/url?session_id=" + sessionId;
  if (serviceCheckTimeoutTest) {
    checkApi =
      "https://" + "www.mocky.io/v2/5185415ba171ea3a00704eed?mocky-delay=" +
      (serviceCheckTimeout + 1000) +
      "ms";
  }
  const header = {
    "Content-Type": "application/json;charset=UTF-8",
    "x-session-id": base62.v4(),
    "x-channel": "thinq",
    "x-branch": countryCode === "kr" ? "LGEKR" : "LGEAI",
    "x-country-code": countryCode,
  };
  console.log("[extLiveHistory]", checkApi);
  console.log("[extLiveHistory Header", header);

  function makeErrorResult(check, statusCode, statusText) {
    return {
      result: {
        check: check,
        error: {
          statusCode: statusCode,
          statusText: statusText,
        },
      },
    };
  }

  let { timeoutId, controller } = makeAbortController(serviceCheckTimeout);

  return await fetch(checkApi, {
    headers: header,
    signal: controller.signal,
  })
    .then((res) => {
      clearTimeout(timeoutId);
      if (!res.ok) {
        throw makeErrorResult(true, res.status, res.statusText);
      }
      return res.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      if ("name" in error && error.name === "AbortError") {
        error = makeErrorResult(true, 0, "timeout");
      } else if (!("check" in error)) {
        error = makeErrorResult(true, 0, error.message);
      }

      console.log("[error]", error);
      return error;
    })
    .finally(() => {
      clearTimeout(timeoutId);
    });
}

/*
 * SMILE API Request
 */
async function postCenterLMS(context, phoneNumber, centerSeq) {
  let lmsAPI = window.thinq.chatbot.smileUrl + "/smile/lgcom/lms";

  const header = {
    "Content-Type": "application/json;charset=UTF-8",
    "x-client-id": base62.v4(),
    "x-channel": "thinq",
    "x-branch": context.countryCode === "kr" ? "LGEKR" : "LGEAI",
    "x-country-code": context.countryCode,
  };
  console.log("[postCenterLMS]", lmsAPI);
  console.log("[postCenterLMS Header", header);

  function makeSendLMSErrorResult(statusCode, statusText) {
    return {
      result: {
        error: {
          statusCode: statusCode,
          statusText: statusText,
        },
      },
    };
  }

  let { timeoutId, controller } = makeAbortController(sendLMSTimeout);
  let body = JSON.stringify({
    telNo: "" + phoneNumber,
    cSeq: centerSeq,
  });

  let result = await fetch(lmsAPI, {
    headers: header,
    signal: controller.signal,
    method: "POST",
    body: body,
  })
    .then((res) => {
      clearTimeout(timeoutId);
      if (!res.ok) {
        throw makeSendLMSErrorResult(res.status, res.statusText);
      }
      return res.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      if ("name" in error && error.name === "AbortError") {
        error = makeSendLMSErrorResult(0, "timeout");
      } else if (!("check" in error)) {
        error = makeSendLMSErrorResult(0, error.message);
      }

      console.log("[error]", error);
      return error;
    })
    .finally(() => {
      clearTimeout(timeoutId);
    });

  return result;
}

/*
 * SMILE EXT API Request
 */
async function postSmileExt(context, type, content) {
  let api = window.thinq.chatbot.smileUrl + "/smile/cud/" + type;

  const header = {
    "Content-Type": "application/json;charset=UTF-8",
    "x-client-id": base62.v4(),
    "x-channel": "thinq",
    "x-branch": context.countryCode === "kr" ? "LGEKR" : "LGEAI",
    "x-country-code": context.countryCode,
  };
  
  function makeSendErrorResult(statusCode, statusText) {
    return {
      result: {
        error: {
          statusCode: statusCode,
          statusText: statusText,
        },
      },
    };
  }

  let { timeoutId, controller } = makeAbortController(sendLMSTimeout);
  let body = JSON.stringify(content);

  console.log("[postSmileExt]", api);
  console.log("[postSmileExt Header]", header);
  console.log("[postSmileExt Body]", body);

  let result = await fetch(api, {
    headers: header,
    signal: controller.signal,
    method: "POST",
    body: body,
  })
    .then((res) => {
      clearTimeout(timeoutId);
      if (!res.ok) {
        throw makeSendErrorResult(res.status, res.statusText);
      }
      return res.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      if ("name" in error && error.name === "AbortError") {
        error = makeSendErrorResult(0, "timeout");
      } else if (!("check" in error)) {
        error = makeSendErrorResult(0, error.message);
      }

      console.log("[error]", error);
      return error;
    })
    .finally(() => {
      clearTimeout(timeoutId);
    });

  return result;
}

const postLog = async (context, type, data) => {
  let logAPI = window.thinq.chatbot.logUrl + "/data/logs";

  const header = {
    "Content-Type": "application/json;charset=UTF-8",
    "x-session-id": context.sessionId,
    "x-channel": "thinq",
    "x-branch": context.countryCode === "kr" ? "LGEKR" : "LGEAI",
    "x-country-code": context.countryCode,
  };
  const body = {
    type: type,
    data: data,
  };
  body.data.timestamp = moment().unix();
  body.data.session_id = context.sessionId;

  console.log("[postLog]", logAPI);
  console.log("[postLog] Header", header);
  console.log("[postLog] Body", body);

  let { timeoutId, controller } = makeAbortController(defaultTimeout);
  let statusObj = {
    responseOk: true,
    statusCode: null,
    statusText: "",
  };

  return await fetch(logAPI, {
    headers: header,
    method: "post",
    body: JSON.stringify(body),
    signal: controller.signal,
  })
    .then((res) => {
      clearTimeout(timeoutId);
      console.log("[LOG]", res);
      statusObj.responseOk = res.ok;
      statusObj.statusCode = res.status;
      statusObj.statusText = res.statusText;
      return res.json();
    })
    .then((resjson) => {
      let body = {};
      body = resjson;

      if (!statusObj.responseOk) {
        throw {
          name: "network",
          code: body.status.code,
          message: body.status.message,
          statusCode: statusObj.statusCode,
          statusText: statusObj.statusText,
        };
      }
      return body;
    })
    .catch((error) => {
      console.log("[error]", error);
      let body = errorHandler(error);
      return body;
    })
    .finally(() => {
      clearTimeout(timeoutId);
    });
};

/*
 * 음성 관련 API 테스트
 */
function makeDownStreamOption(apikey, token) {
  return {
    method: "POST",
    hostname: baseURL,
    port: 443,
    path: downStreamPath,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: token,
      "x-api-key": apikey,
    },
  };
}

function makeUpStreamOption(apikey, token, cookie, dtqSessionId) {
  return {
    method: "POST",
    hostname: baseURL,
    port: 443,
    path: upStreamPath,
    headers: {
      "Content-Type": "application/octet-stream",
      Authorization: token,
      "x-api-key": apikey,
      "x-dtq-session-id": dtqSessionId,
      cookie: cookie,
    },
  };
}

async function requestUpStreamCB(thinqAPIToken, cookie, dtqSessionId) {
  return new Promise((resolve) => {
    let option = makeUpStreamOption(
      thinqAPIKey,
      thinqAPIToken,
      cookie,
      dtqSessionId
    );
    const req = https.request(option, (res) => {
      res.on("data", (data) => {
        console.log(data);
      });
    });
    resolve(req);
  });
}

async function requestDownStream(thinqAPIToken, requestUpStreamCB) {
  console.log("requestDownStream - thinqAPIToken: ", thinqAPIToken);
  console.log("requestDownStream - thinqAPIKey: ", thinqAPIKey);

  /*
  fetch('https://' + baseURL + ':443' + downStreamPath, {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': thinqAPIToken,
      'x-api-key':thinqAPIKey
    },
    method: 'POST',
    body: {
      'type':'dictation',
      'input': {
        'languageCode':'ko-KR',
        'audioConfig': {
          'encoding': 'pcm',
          'channels': 1,
          'sampleRate': 16000
        }
      },
      'additionalConfig': {}
    }
  })
  .then(res => {
    res.then((resolve, err) => {
      resolve()
    })

    console.log('response res', res);
    
    
  })
  .then(json => {
    console.log('response json', json);
    return json
  })
  */

  return new Promise((resolve) => {
    let downStreamBody = {
      type: "dictation",
      input: {
        languageCode: "ko-KR",
        audioConfig: {
          encoding: "pcm",
          channels: 1,
          sampleRate: 16000,
        },
      },
      additionalConfig: {},
    };

    let bodyStr = JSON.stringify(downStreamBody);
    let option = makeDownStreamOption(thinqAPIKey, thinqAPIToken);

    const req = https.request(option, (res) => {
      console.log("res", res);

      res.setEncoding("utf8");
      res.on("error", function(data) {
        console.log("error", data);
      });
      res.on("response", function(data) {
        console.log("response", data);
      });
      res.on("data", function(data) {
        data = JSON.parse(data);
        console.log("data", data);
      });
      res.on("end", function(data) {
        console.log("end", data);
      });
    });
    let cookie = "";
    let dtqSessionId = "";
    req.on("connect", (res, clientSocket, head) => {
      console.log("connected");
      console.log(res);
      console.log(clientSocket);
      console.log(head);
    });
    req.on("response", (res) => {
      console.log("response - headers", res);
      cookie = res.headers["set-cookie"];
      dtqSessionId = res.headers["x-dtq-session-id"];

      resolve({
        cookie: cookie,
        dtqSessionId: dtqSessionId,
      });
    });
    req.on("data", (data) => {
      console.log("req data", data);
    });
    req.end(bodyStr);
  });
}

async function thinqSTT(thinqAPIToken) {
  let downStreamObj = null;
  try {
    downStreamObj = await requestDownStream(thinqAPIToken, requestUpStreamCB);
  } catch (err) {
    console.log("error", err);
  }
}

export {
  send,
  getToken,
  thinqSTT,
  extServiceCheck,
  postCenterLMS,
  postSmileExt,
  postLog,
  extLiveChatHistory,
};
