import React, { useContext, useRef } from "react";
import { withStyles } from "@material-ui/core";
import DataContext from "../data/DataContext";
import InputContext from "../data/InputContext";
import Arrow from "../../asset/img/ic_list_arrow_nor.png";

const hangul = require("hangul-js");

const styles = (theme) => ({
  root: {
    width: "100%",
    maxHeight: "7rem",
    overflowY: "auto",
    border: `1px solid ${theme.color.list_divider}`,
    background: "#f5f5f5",
    backgroundColor: "#ffffff",
    transition: "all .3s ease-in-out",
    zIndex: 12,
    borderRadius: "20px 20px 0px 0px",
  },
  close: {
    maxHeight: "0px",
    borderTop: "0px solid #ccc",
  },
  item: {
    width: "100%",
    minHeight: "40px",
    background: "#ffffff",
    textTransform: "none",
    padding: "0px 24px",
    outline: "0 none",
    border: "none",
    display: "flex",
    justfyContent: "between-space",
    alignItems: "center",
    font: "-apple-system-body",
  },
  category: {
    borderRadius: "20px",
    fontSize: theme.font.size12, //"12px",
    fontWeight: "bold",
    color: "#e84472",
    border: "2px solid #e84472",
    width: "85px",
    height: "25px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    font: "-apple-system-body",
  },
  text: {
    flex: 1,
    textAlign: "start",
    // textOverflow: "ellipsis",
    // overflow: "hidden",
    // whiteSpace: "nowrap",
    wordBreak: "break-word",
    fontSize: theme.font.size15, // "15px",
    fontWeight: "300",
    color: theme.color.input.text,
  },
  arrow: {
    width: "24px",
    height: "24px",
    backgroundSize: "24px",
    backgroundImage: `url(${Arrow})`
  },
  divider: {
    background: theme.color.list_divider,
    height: "1px",
    width: "100%"
  },
  hidden: {
    width: "0px",
    height: "0px",
    margin: "0px",
    fontSize: "0.1px",
    textIndent: "-9999px"
  }
});

function AutoComplete(props) {
  var { classes } = props;
  const data = useContext(DataContext);
  const input = useContext(InputContext);

  const handleClick = (item) => {
    console.log("handleAutoCompleteClose", item);
    input.autoComplete.setEnable(false);
    if (item.data && item.data !== "") {
      data.request(data, item.data, "button");
      input.setInput("");
    }
  };

  const sortedList =
    input.autoComplete.parameters &&
    input.autoComplete.parameters.items &&
    input.autoComplete.parameters.items.sort((prev, curr) => {
      return parseInt(prev.order) < parseInt(curr.order)
        ? -1
        : parseInt(prev.order) > parseInt(curr.order)
        ? 1
        : 0;
    });

  var filteredList =
    sortedList &&
    sortedList.filter((item) => {
      const result = hangul.search(item.data.toLowerCase(), input.input.trimLeft().toLowerCase());
      return result >= 0;
    });

  if (input.input === "" && sortedList) {
    filteredList = sortedList;
  }

  return input.autoComplete.enable &&
    input.autoComplete.open &&
    input.autoComplete.parameters &&
    input.autoComplete.parameters.items &&
    input.autoComplete.parameters.items.length > 0 ? (
    <>
      <p className={classes.hidden} role={"status"}>입력 예시 표시됨.</p>
      <div
        className={[
          classes.root,
          input.autoComplete.enable && input.autoComplete.open ? "" : classes.close,
        ].join(" ")}
        onTouchMove={(e) => e.stopPropagation()}
      >
        {input.autoComplete.enable &&
          input.autoComplete.open &&
          input.autoComplete.parameters &&
          input.autoComplete.parameters.items &&
          filteredList &&
          filteredList.map((item, index) => {
            if (index >= 30) return null;
            
            let combine = item.data;

            if (input.input && input.input.length > 0) {
              const arrData = item.data.toLowerCase().split(input.input.toLowerCase())
              const first = item.data.substring(0, arrData[0].length);
              const find = item.data.substring(arrData[0].length, arrData[0].length + input.input.length);
              const last = item.data.substring(arrData[0].length + input.input.length, item.data.length);
              combine = [first, last].join(`<span style="color: #087e293; font-weight: 600; font-family: Noto Sans CJK KR, Noto Sans, RobotoDraft, Roboto, San Francisco, Apple SD Gothic Neo, Helvetica Neue, sans-serif; text-decoration: underline;">${find}</span>`);
            }

            return (
              <>
                <button
                  type="button"
                  className={classes.item}
                  onClick={() => handleClick(item)}
                  key={index}
                  aria-label={`${item.data}, ${index + 1} / ${filteredList.length}`}
                >
                  <div
                    className={classes.text}
                    dangerouslySetInnerHTML={{
                      __html: combine
                    }}
                  />
                  <div className={classes.arrow}/>
                </button>
                {(filteredList.length < 30 ? index !== filteredList.length - 1 : index < 29) && <div className={classes.divider}/>}
              </>
            );
          })}
      </div>
    </>
  ) : null;
}
export default withStyles(styles)(AutoComplete);
