import React, { useState } from "react";
import InputContext from "./InputContext";

const InputProvider = ({ children }) => {
  const setInput = (input) => {
    setState((prevState) => {
      return {
        ...prevState,
        input: input,
      };
    });
  };

  const setAutoComplete = (enable, parameters = null, input = null) => {
    setState((prevState) => {
      prevState.autoComplete.enable = enable;
      prevState.autoComplete.open = enable; // ADDED
      if (parameters) prevState.autoComplete.parameters = parameters;
      if (input) prevState.input = input;
      return { ...prevState };
    });
  };

  const setAutoCompleteOpen = (open) => {
    setState((prevState) => {
      prevState.autoComplete.open = open;
      return { ...prevState };
    });
  };

  const setKeyboardOpen = (open) => {
    setState((prevState) => {
      if (!open) prevState.keyboard.handleOpen(open);
      prevState.keyboard.open = open;
      return { ...prevState };
    });
  };

  const initialState = {
    input: "",
    setInput: setInput,
    autoComplete: {
      enable: false,
      setEnable: setAutoComplete,
      parameters: {},
      open: false,
      setOpen: setAutoCompleteOpen,
    },
    keyboard: {
      setOpen: setKeyboardOpen,
      open: false,
      handleOpen: () => {},
    },
  };

  const [state, setState] = useState(initialState);
  
  return (
    <InputContext.Provider value={state}>{children}</InputContext.Provider>
  );
};

export default InputProvider;
