import { createContext } from "react";

/**
 * Model Class
 * - 대화 내용과 요청 함수들을 정의하는 모델
 */
const DataContext = createContext({
  // 1. 대화 내용
  oldConversations: [], // 20회 이전의 대화 내용
  isShowOldConversations: false,
  conversations: [
    /** 대화 내용은 다음과 같은 형식으로 정의
    {
      id: base62.v4(), (unique message id)
      type: "tx", (Template Type)
      mine: true/false, (사용자/상대 메시지)
      text: "", (대화)
      loading: true/false, (로딩 중)
      date: "", (메시지 날짜, moment 기준으로 LT format)
    }
     */
  ],

  // 2. 대화 요청 함수
  request: () => {},

  // 3. 스크롤 요청 함수
  scroll: () => {},

  // 4. 국가코드
  countryCode: "",

  // 5. 언어팩키지 리소스
  string: null,

  // 6. DM Interface
  requestToken: () => {},

  // 7. ThinqAI Token
  thinqAIToken: null,

  // 8. 채널 (ex) thinq)
  channel: "",

  // 9. 채널파라미터 (모바일로부터 올라온 고객/기기 정보)
  channelParams: {
    access_token: "",
    devices: [],
    user: {
      name: null,
      phone: null,
      email: null,
    },
  },

  // 10. Session Id
  sessionId: "",

  // 11. AutoComplete Status move on InputContext

  // 12. Stage
  stage: "",

  // 13. Conversation Status
  conversationStart: false,
  conversationEnd: false,

  // 14. UserAgent
  userAgent: {
    os: "",
    deviceType: "",
  },

  // 15. Delete Message 함수
  deleteMessage: () => {},

  // 16. Debug Status
  debug: false,

  // 17. Dialog
  dialog: {},

  // 18. Location
  location: {
    permission: false,
    status: false,
    latitude: "",
    longitude: "",
  },

  // 19. 대화 종료
  endConversation: () => {},

  // 20. Log 전달 (BC, TL)
  log: () => {},

  // 21. 사용자 시작여부
  isUserAction: false,

  // 22. livechat branch
  livechatBranch: "",

  // 23. livechat branch 설정
  setLivechatBranch: () => {},
});
export default DataContext;
